import { useRef } from "react";

import { CompletedHomeworkType } from "@customTypes/CompletedHomeworkType";
import { ReactComponent as DownloadImg } from "@img/download.svg";
import { downloadFile } from "@utils/utils";

import cs from "./CompletedHomeworkPill.module.scss";

type CompletedHomeworkPillProps = {
  homework: CompletedHomeworkType;
};

export const CompletedHomeworkPill = ({
  homework,
}: CompletedHomeworkPillProps) => {
  const ref = useRef<any>(null);
  return (
    <div className={cs.root} title={homework.name} ref={ref}>
      <span className={cs.name}>{homework.name}</span>

      <button
        className={cs.downloadBtn}
        onClick={() => downloadFile(homework.url, ref.current)}
      >
        <DownloadImg />
      </button>
    </div>
  );
};
