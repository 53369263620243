import { useEffect, useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { Menu } from "@components/Menu";
import { ReactComponent as HamburgerMenuImg } from "@img/hamburger_menu.svg";

import cs from "./MobileMenu.module.scss";

export const MobileMenu = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const location = useLocation();

  useEffect(() => {
    setIsMobileMenuOpen(false);
  }, [location]);

  useLayoutEffect(() => {
    if (isMobileMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [isMobileMenuOpen]);

  return (
    <>
      <button
        className="btn-icon hide-desktop-up"
        type="button"
        onClick={() => setIsMobileMenuOpen(true)}
      >
        <HamburgerMenuImg width={20} />
      </button>

      <div
        className={`${cs.container} hide-desktop-up`}
        style={{ right: isMobileMenuOpen ? 0 : -320 }}
      >
        <Menu className={cs.mobileMenu} />
      </div>

      <div
        className={`${cs.backdrop} hide-desktop-up`}
        style={{
          opacity: isMobileMenuOpen ? 1 : 0,
          pointerEvents: isMobileMenuOpen ? "all" : "none",
        }}
        onClick={() => setIsMobileMenuOpen(false)}
      />
    </>
  );
};
