import { useQuery } from "react-query";

import { LessonApi } from "@api/LessonApi";

export const useStudyProgress = (params: any) => {
  return useQuery(
    ["lessons", "study-progress", params],
    () => LessonApi.fetchStudyProgress(params),
    {
      keepPreviousData: true,
      cacheTime: 30 * 1000,
      staleTime: 30 * 1000,
    },
  );
};
