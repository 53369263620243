import { useEffect, useRef, useState } from "react";

import copy from "copy-to-clipboard";

import { Button } from "@components/Button";
import { Input } from "@components/Input";
import { Modal } from "@components/Modal";
import { StudentType } from "@customTypes/StudentType";
import { useAddInvitation } from "@hooks/useAddInvitation";
import { ToastService } from "@services/ToastService";

import cs from "./InvitationModal.module.scss";

type InvitationModalProps = {
  isOpen: boolean;
  student?: StudentType | null;
  onClose: () => void;
};

export const InvitationModal = ({
  isOpen,
  student,
  onClose,
}: InvitationModalProps) => {
  const [email, setEmail] = useState("");

  const formElement = useRef<HTMLFormElement>(null);
  const linkInputElement = useRef<HTMLInputElement>(null);

  const addInvitation = useAddInvitation();

  useEffect(() => {
    setEmail(student?.mainContact?.email || "");
  }, [isOpen, student]);

  const invitationLink = `${process.env.REACT_APP_STUDENT_APP}/invitation/${student?._id}`;

  return (
    <Modal
      title="Wyślij zaproszenie"
      isOpen={isOpen}
      className={cs.modal}
      help={`Każdy z Twoich uczniów może, choć nie musi, posiadać darmowe konto w aplikacji. Konto ucznia umożliwia mu dostęp do swojego kalendarza lekcji, otrzymywanie i dostarczanie zadań domowych, dostęp do historii spotkań oraz notatek, a także umożliwia proponowanie lekcji, jeśli korepetytor utworzy Dostępność w swoim kalendarzu.`}
      footer={null}
      onClose={onClose}
    >
      <div className="mb20" style={{ fontSize: 15 }}>
        <div className={cs.row}>
          <Input
            label="Udostępnij uczniowi poniższy link zapraszający:"
            value={invitationLink}
            readonly
            ref={linkInputElement}
            onClick={() => copyLink(invitationLink)}
          />

          <Button
            color="secondary"
            isLoading={addInvitation.isLoading}
            htmlType="button"
            onClick={() => copyLink(invitationLink)}
          >
            Kopiuj
          </Button>
        </div>
      </div>

      {!student?.hasAccount && (
        <>
          <div className="semi-bold mb20">LUB:</div>

          <form ref={formElement} className="mb20">
            <div className={cs.row}>
              <Input
                label="Wyślij zaproszenie przez e-mail"
                value={email}
                required
                type="email"
                placeholder="Adres e-mail ucznia"
                className="Input"
                onChange={setEmail}
              />

              <Button
                color="primary"
                isLoading={addInvitation.isLoading}
                onClick={handleConfirm}
              >
                Wyślij
              </Button>
            </div>
          </form>
        </>
      )}
    </Modal>
  );

  async function handleConfirm() {
    if (!student) return;

    const isValid = formElement.current?.reportValidity();

    if (!isValid) return;

    await addInvitation.mutateAsync({ email, studentId: student?._id });

    onClose();
  }

  function copyLink(link: string) {
    linkInputElement.current?.select();

    copy(link);

    ToastService.success(
      <div>
        <div className="semi-bold">Link został skopiowany do schowka.</div>

        <div className="mt10">
          Udostępnij go uczniowi aby umożliwić mu założenie konta w aplikacji.
        </div>

        <div className="mt10 trim-text">
          <a href={link} target="_blank" className="link" rel="noreferrer">
            {link.replace("https://", "")}
          </a>
        </div>
      </div>,
      { duration: 10000, toastId: link },
    );
  }
};
