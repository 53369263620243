// @ts-nocheck
import { useRef, useState } from "react";

import { useCurrentUser } from "../../hooks/useCurrentUser";
import { useReplaceCurrentUser } from "../../hooks/useReplaceCurrentUser";
import { ReactComponent as ArrowImg } from "../../img/arrow_right_long.svg";
import { ReactComponent as SettingsImg } from "../../img/settings_new.svg";
import { ReactComponent as TrashImg } from "../../img/trash.svg";
import { Button } from "../Button";
import { Dropdown } from "../Dropdown";
import { Input } from "../Input";
import { Pill } from "../Pill";
import { Popover } from "../Popover";

import cs from "./Prices.module.scss";
import parentCs from "./UpsertLessonModal.module.scss";

type PricesProps = {
  currentPrice: number | null;
  onChange: (price: number) => void;
};

const PopoverContent = ({ availablePrices, durations, onChange }) => {
  const [newPrice, setNewPrice] = useState("");

  const innerElement = useRef<HTMLDivElement>(null);

  return (
    <div>
      <section className={cs.pricesSection}>
        <div className={cs.sectionTitle}>Podpowiedzi:</div>

        <div className={cs.addNew}>
          <Input
            type="number"
            value={newPrice}
            min={0}
            onChange={setNewPrice}
          />

          <Button
            color="primary"
            type="outlined"
            size="small"
            onClick={handlePriceAdded}
          >
            Dodaj
          </Button>
        </div>

        <div className={cs.availablePrices}>
          {availablePrices.map((price) => (
            <div key={price} className={cs.price}>
              <span>{price} zł</span>

              <button
                className="btn-icon"
                onClick={() => handlePriceRemoved(price)}
              >
                <TrashImg width={14} />
              </button>
            </div>
          ))}
        </div>
      </section>

      <section className={cs.priceBookSection}>
        <div className={cs.sectionTitle} ref={innerElement}>
          Cennik:
        </div>

        <div>
          {durations.map(({ value, price }) => (
            <div key={value} className={cs.priceBookEntry}>
              <div>{value} min</div>

              <ArrowImg width={16} />

              <Dropdown
                options={availablePrices.map((price) => (
                  <div
                    key={price}
                    onClick={(e) => {
                      // NOTE: Prevent main dropdown from
                      //       closing but close this one
                      e.stopPropagation();
                      innerElement.current?.click();

                      handlePriceAssigned(value, price);
                    }}
                  >
                    {price} zł
                  </div>
                ))}
              >
                <div className={cs.priceDropdown}>{price || "-"} zł</div>
              </Dropdown>
            </div>
          ))}
        </div>
      </section>
    </div>
  );

  function handlePriceAdded() {
    const price = Number.parseInt(newPrice);

    if (!(price >= 0)) return;
    if (availablePrices.includes(price)) {
      setNewPrice("");

      return;
    }

    const updatedPrices = [...availablePrices, price].sort((a, b) =>
      a > b ? 1 : -1,
    );

    onChange(updatedPrices, durations);

    setNewPrice("");
  }

  function handlePriceRemoved(removedPrice: number) {
    const updatedPrices = availablePrices.filter(
      (price) => price !== removedPrice,
    );

    const updatedDurations = durations.map((duration) => {
      if (duration.price === removedPrice) {
        return {
          ...duration,
          price: null,
        };
      }

      return duration;
    });

    onChange(updatedPrices, updatedDurations);
  }

  function handlePriceAssigned(editedDuration: number, price: number) {
    const updatedDurations = durations.map((duration) => {
      if (duration.value === editedDuration) {
        return {
          ...duration,
          price: price,
        };
      }

      return duration;
    });

    onChange(availablePrices, updatedDurations);
  }
};

export const Prices = ({ currentPrice, onChange }: PricesProps) => {
  const { currentUser } = useCurrentUser();

  const replaceCurrentUser = useReplaceCurrentUser();

  const availablePrices = currentUser?.prices || [];
  const durations = currentUser?.durations || {};

  return (
    <div>
      <div className={parentCs.label}>
        <span>Cena</span>

        <Popover
          popover={
            <PopoverContent
              availablePrices={availablePrices}
              durations={durations}
              onChange={handleDefaultsChanged}
            />
          }
          position={["bottom", "top", "right", "left"]}
          square
          className={cs.popover}
        >
          <button className="btn-icon" type="button">
            <SettingsImg width={14} />
          </button>
        </Popover>
      </div>

      <div className={parentCs.prices}>
        {availablePrices.map((price) => (
          <Pill
            key={price}
            value={price}
            type={price === currentPrice ? "normal" : "outline"}
            onClick={onChange}
          >
            {price}zł
          </Pill>
        ))}
      </div>
    </div>
  );

  function handleDefaultsChanged(availablePrices: number[], durations: any[]) {
    replaceCurrentUser.mutate({
      currentUser: {
        ...currentUser,
        durations: durations,
        prices: availablePrices,
      },
    });
  }
};
