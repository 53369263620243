import { TimeSlotDtoType } from "@customTypes/TimeSlotDtoType";
import { TimeSlotType } from "@customTypes/TimeSlotType";
import { StudentDtoService } from "@services/StudentDtoService";
import { addMinutes, formatHour } from "@utils/date.utils";

export class TimeSlotDtoService {
  static transform(timeSlot: TimeSlotDtoType): TimeSlotType {
    const endDate = addMinutes(timeSlot.date, timeSlot.duration);

    return {
      ...timeSlot,
      date: new Date(timeSlot.date),
      endDate,
      startHourStr: formatHour(timeSlot.date),
      endHourStr: formatHour(endDate),
      applications: timeSlot.applications.map((application) => {
        const endDate = addMinutes(application.date, application.duration);

        return {
          ...application,
          date: new Date(application.date),
          endDate,
          startHourStr: formatHour(timeSlot.date),
          endHourStr: formatHour(endDate),
          student: StudentDtoService.transform(application.student),
        };
      }),
    };
  }
}
