import EmptyListImg from "./img/empty_list.png";

import cs from "./MaterialsPage.module.scss";

export const EmptyList = () => (
  <div className={cs.empty}>
    <div className={cs.empty_texts}>
      <div className={cs.empty_title}>
        Twoja biblioteka materiałów jest pusta.
      </div>
      <div className={cs.empty_text}>
        Pliki, które tutaj dodasz, w prosty sposób udostępnisz swoim uczniom,
        załączając je do tworzonych lekcji i szablonów lekcji. Polecamy tworzyć
        foldery i podfoldery, które ułatwią Ci nawigację wśród dodanych
        materiałów.
      </div>
    </div>

    <img src={EmptyListImg} alt="" className={cs.empty_img} />
  </div>
);
