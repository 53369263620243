import React from "react";

import classNames from "classnames";

import calendarImg from "./img/calendar.svg";
import chatImg from "./img/chat.svg";
import dashboardImg from "./img/dashboard.svg";
import financesImg from "./img/finances.svg";
import logoImg from "./img/logo.svg";
import settingsImg from "./img/settings.svg";
import studentsCenterImg from "./img/students_center.svg";
import studyPlanerImg from "./img/study_planer.svg";
import { MenuItem } from "./MenuItem";

import cs from "./Menu.module.scss";

export const Menu = ({ className }: { className?: string }) => {
  const rootsCs = classNames("Menu", cs.root, className);

  return (
    <aside className={rootsCs}>
      <div className={cs.top}>
        <img src={logoImg} alt="" />

        <span>up4edu</span>
      </div>

      <nav className={cs.navigation}>
        <MenuItem text="Dashboard" link="/" icon={dashboardImg} />
        <MenuItem text="Kalendarz" link="/calendar" icon={calendarImg} />
        <MenuItem
          text="Centrum uczniów"
          link="/students"
          icon={studentsCenterImg}
          subItems={[
            { text: "Uczniowie", link: "/students" },
            { text: "Lista spotkań", link: "/lessons" },
          ]}
        />
        <MenuItem
          text="Planer nauki"
          link="/plans"
          icon={studyPlanerImg}
          subItems={[
            { text: "Kursy", link: "/plans" },
            { text: "Szablony lekcji", link: "/templates" },
            { text: "Materiały", link: "/materials" },
          ]}
        />
        <MenuItem text="Finanse" link="/finances" icon={financesImg} />
      </nav>

      <nav className={cs.bottom}>
        <MenuItem text="Ustawienia" link="/settings" icon={settingsImg} />

        <a
          href="https://chatting.page/a8aebkn7ex2ofthcdxfkbpeneu4id14t"
          className={cs.menuItem}
          target="_blank"
          rel="noreferrer"
        >
          <img src={chatImg} alt="" />
          <span>Masz pytanie?</span>
        </a>

        {/*  TODO: Later */}
        {/*<MenuItem text="FAQ" link="/faq" icon={faqImg} />*/}
      </nav>
    </aside>
  );
};
