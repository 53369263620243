import { useMutation, useQueryClient } from "react-query";

import { TimeSlotApi } from "../api/TimeSlotApi";

export const useAddTimeSlot = () => {
  const queryClient = useQueryClient();

  return useMutation("time-slots", TimeSlotApi.create, {
    onSuccess() {
      return queryClient.invalidateQueries({
        queryKey: ["calendar"],
        refetchInactive: true,
      });
    },
  });
};
