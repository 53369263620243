import { useState } from "react";

import { BillingsApi } from "@api/BillingsApi";
import { Button } from "@components/Button";
import { loadStripe } from "@stripe/stripe-js";
import { formatDate, isFuture, isPast } from "@utils/date.utils";

import cs from "@pages/Settings/tabs/TabBilling.module.scss";

export const Period = ({
  bill,
  type,
}: {
  bill: any;
  type: "current" | "next";
}) => {
  const [isNavigatingToCheckout, setIsNavigatingToCheckout] = useState(false);

  if (!bill?._id) return null;

  return (
    <div className={type === "current" ? cs.package : cs.package__next}>
      <div className={cs.packageHeader}>
        {type === "current"
          ? "Aktualny okres rozliczeniowy"
          : "Nadchodzący okres rozliczeniowy"}
      </div>
      <div className={cs.packageDetails}>
        <div>
          <div className={cs.packageLabel}>Okres:</div>
          <div className={cs.packageValue}>
            <span>{formatDate(bill.periodStart)}</span>
            <span> - </span>
            <span>{formatDate(bill.periodEnd)}</span>
          </div>
        </div>

        <div>
          <div className={cs.packageLabel}>Cena</div>
          <div className={cs.packageValue}>{bill.price || 0} zł</div>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div>
            <div className={cs.packageLabel}>Status:</div>
            <div className={cs.packageValue}>{getStatus(bill)}</div>
          </div>

          <div>{getPaymentButton(bill)}</div>
        </div>

        <div>
          <div className={cs.packageLabel}>Termin płatności:</div>
          <div className={cs.packageValue}>{getDeadline(bill)}</div>
        </div>
      </div>
    </div>
  );

  function getStatus(bill: any) {
    if (!bill.price) return <span>Okres DARMOWY</span>;

    if (bill.paidAt) return <span>Opłacono ({formatDate(bill.paidAt)})</span>;

    return (
      <span className={isPast(bill.periodStart) ? "red semi-bold" : ""}>
        Brak płatności
      </span>
    );
  }

  function getDeadline(bill: any) {
    if (!bill.price) return <span>-</span>;

    const deadline = formatDate(bill.paymentDeadline);
    const periodNotYetStared = isFuture(bill.periodStart);

    if (bill.paidAt || periodNotYetStared) return <span>{deadline}</span>;

    return <span className="red semi-bold">{deadline}</span>;
  }

  function getPaymentButton(bill: any) {
    if (!bill.price || bill.paidAt) return null;

    return (
      <Button
        color={isPast(bill.periodStart) ? "danger" : "secondary"}
        isLoading={isNavigatingToCheckout}
        onClick={() => handlePayClicked(bill._id)}
      >
        Opłać
      </Button>
    );
  }

  async function handlePayClicked(billId: string) {
    setIsNavigatingToCheckout(true);

    try {
      const sessionId = await BillingsApi.createCheckoutSession(billId);

      const stripe = await loadStripe(
        process.env.REACT_APP_STRIPE_PUBLIC_KEY as string,
      );

      if (stripe) {
        const result = await stripe.redirectToCheckout({
          sessionId: sessionId,
        });

        if (result.error) {
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.
        }
      }
    } finally {
      setIsNavigatingToCheckout(false);
    }
  }
};
