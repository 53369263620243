export const columns = [
  {
    title: "Temat lekcji",
    field: "topic",
    sortable: true,
  },
  {
    title: "Data utworzenia",
    field: "createdAt",
    type: "date",
    typeArgs: {
      dateStyle: "numeric",
    },
    sortable: true,
  },
];
