import { useQuery } from "react-query";

import { TodoApi } from "../api/TodoApi";
import { TodoType } from "../types/TodoType";

export const useUndoneTodosByParticipant = (participantId: string) => {
  return useQuery<TodoType[]>(
    ["todos", "undone", "participant", participantId],
    () => TodoApi.fetchAllUndone(participantId),
  );
};
