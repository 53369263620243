import { Outlet } from "react-router-dom";

import { AppBar } from "@components/AppBar";
import { Menu } from "@components/Menu";

import cs from "./Layout.module.scss";

export const Layout = () => (
  <div className={cs.app}>
    <Menu className="hide-desktop-down" />

    <AppBar />

    <Outlet />
  </div>
);
