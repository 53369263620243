import { Dropdown } from "@components/Dropdown";
import {
  calendarViewOptions,
  CalendarViewType,
} from "@constants/CalendarViewOptions";
import { ReactComponent as DropdownArrowImg } from "@img/dropdown_arrow.svg";

import cs from "./ViewPicker.module.scss";

type ViewPickerProps = {
  selected: CalendarViewType;
  onChange: (selected: CalendarViewType) => void;
};

export const ViewPicker = ({ selected, onChange }: ViewPickerProps) => {
  const selectedOption = calendarViewOptions.find(
    (option) => option.value === selected,
  );

  return (
    <Dropdown
      options={calendarViewOptions.map((option) => (
        <div
          onClick={() => {
            onChange(option.value);
          }}
        >
          {option.label}
        </div>
      ))}
    >
      <span className={cs.root}>
        <span>{selectedOption?.label}</span>

        <DropdownArrowImg width={10} />
      </span>
    </Dropdown>
  );
};
