import { useQuery } from "react-query";

import { MaterialApi } from "@api/MaterialApi";

export const useMaterials = () => {
  const { data = [], isLoading } = useQuery("materials", MaterialApi.fetchAll);

  return {
    files: data.filter((material) => material.type !== "folder"),
    folders: data.filter((material) => material.type === "folder"),
    isLoading,
  };
};
