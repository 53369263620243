import { LessonDetails } from "@components/LessonDetails";
import { PaymentStatus } from "@components/PaymentStatus";
import { LessonPaymentStatus } from "@constants/LessonPaymentStatus";
import { StandaloneArrearType } from "@customTypes/StandaloneArrearType";
import { ReactComponent as OpenImg } from "@img/open.svg";
import { LessonDate } from "@pages/LessonsListPage/columns";
import {
  diff,
  formatDate,
  formatShortDate,
  formatWeekday,
  toRelative,
} from "@utils/date.utils";

import { ReactComponent as CancelImg } from "./img/cancel.svg";
import { ReactComponent as CheckImg } from "./img/check.svg";

type PropsType = {
  onStatusChange: (arrearId: string, status: LessonPaymentStatus) => void;
};

export const columnsMobile = ({ onStatusChange }: PropsType) => [
  {
    title: "Data lekcji",
    field: "date",
    sortable: true,
    width: 180,
    render(field: any, col: any, row: StandaloneArrearType) {
      const [firstStudent] = row.students;

      return (
        <LessonDetails lesson={row.lesson}>
          <span
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              gap: "12px",
            }}
          >
            <OpenImg width={12} style={{ flexShrink: 0 }} />

            <div>
              <div
                style={{
                  fontWeight: "600",
                  marginLeft: "20px",
                  marginBottom: "3px",
                }}
              >
                {firstStudent.shortName}
              </div>
              <div>
                <LessonDate lesson={row.lesson} />
              </div>
            </div>
          </span>
        </LessonDetails>
      );
    },
  },
  {
    title: "Status",
    field: "status",
    help: `Status "Oczekuje" oznacza, że lekcja nie została jeszcze opłacona. Gdy termin płatności mija, a lekcja pozostaje nieopłacona, status zmienia się na "Po terminie". "Opłacono" to informacja dla Ciebie, że uczeń (posiadający konto w aplikacji) oznaczył płatność jako wykonaną. Sprawdź swoje konto bankowe - jeśli płatność została dokonana, potwierdź tę akcję w aplikacji, a status zmieni się na "Rozliczono".`,
    width: 120,
    render(
      field: StandaloneArrearType["status"],
      col: any,
      row: StandaloneArrearType,
    ) {
      const deadline = row.lesson.paymentDeadline;
      const fullDate = formatDate(deadline, "full");
      const daysAfterDeadline = diff(new Date(), deadline);
      const rootCs = row.isAfterDeadline ? "red" : "";

      const relativeTime = toRelative(deadline);

      let deadlineEl = null;

      if (row.status !== LessonPaymentStatus.AWAITS || !deadline) {
        deadlineEl = null;
      } else if (daysAfterDeadline < -4 || daysAfterDeadline > 4) {
        const date = formatShortDate(deadline);
        const weekday = formatWeekday(deadline);

        deadlineEl = (
          <div
            className={rootCs}
            title={fullDate}
            style={{ fontWeight: "500", fontSize: "13px", userSelect: "none" }}
          >
            {date}, {weekday}
          </div>
        );
      } else {
        deadlineEl = (
          <div
            className={rootCs}
            title={fullDate}
            style={{
              fontWeight: "500",
              fontSize: "13px",
              whiteSpace: "normal",
              userSelect: "none",
            }}
          >
            {relativeTime}
          </div>
        );
      }

      return (
        <div>
          {row.lesson.price && (
            <div
              style={{
                fontSize: "13px",
                fontWeight: "500",
                userSelect: "none",
              }}
            >
              {row.lesson.price} zł
            </div>
          )}

          {deadlineEl}

          <PaymentStatus arrears={[row]} />
        </div>
      );
    },
  },
  {
    title: "",
    field: "status",
    width: 60,
    center: true,
    render(
      field: StandaloneArrearType["status"],
      col: any,
      row: StandaloneArrearType,
    ) {
      if (field === LessonPaymentStatus.CLOSED) {
        return (
          <button
            className="btn-icon"
            title="Oznacz jako oczekującą płatności"
            onClick={() => onStatusChange(row._id, LessonPaymentStatus.AWAITS)}
          >
            <CancelImg width={16} />
          </button>
        );
      }

      return (
        <button
          className="btn-icon"
          title="Oznacz jako rozliczoną"
          onClick={() => onStatusChange(row._id, LessonPaymentStatus.CLOSED)}
        >
          <CheckImg width={18} />
        </button>
      );
    },
  },
];
