import { ReactNode, useRef, useState } from "react";

import { TemplateType } from "../../types/TemplateType";
import { Popover, PopoverPosition } from "../Popover";
import { UpsertTemplateModal } from "../UpsertTemplateModal";

import { TemplateDetailsPopoverContent } from "./TemplateDetailsPopoverContent";

// TODO: Will create edit modal for each template

type TemplateDetailsPopoverProps = {
  template: TemplateType;
  popoverPosition?: PopoverPosition;
  children: ReactNode;
};

export const TemplateDetailsPopover = ({
  template,
  popoverPosition,
  children,
}: TemplateDetailsPopoverProps) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const popover = useRef<any>(null);

  return (
    <>
      <Popover
        popover={
          <TemplateDetailsPopoverContent
            template={template}
            onEdit={() => {
              popover.current.close();
              setIsEditModalOpen(true);
            }}
          />
        }
        position={popoverPosition}
        ref={popover}
      >
        {children}
      </Popover>

      <UpsertTemplateModal
        isOpen={isEditModalOpen}
        template={template}
        onClose={() => setIsEditModalOpen(false)}
      />
    </>
  );
};
