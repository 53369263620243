import { useQuery } from "react-query";

import { FinancesApi } from "@api/FinancesApi";
import { PaginationType } from "@customTypes/PaginationType";

// TODO: Stale time
export const useStandaloneArrears = (
  filters: Record<string, any>,
  sort: string[],
  pagination: PaginationType,
) => {
  return useQuery(
    ["standalone-arrears", filters, sort, pagination],
    () => {
      return FinancesApi.getStandaloneArrears(filters, sort, pagination);
    },
    { keepPreviousData: true },
  );
};
