import { RegularEventDtoType } from "../types/RegularEventDtoType";
import { RegularEventType } from "../types/RegularEventType";
import { addMinutes, formatHour } from "../utils/date.utils";

export class RegularEventDtoService {
  static transform(regularEvent: RegularEventDtoType): RegularEventType {
    const endDate = addMinutes(regularEvent.date, regularEvent.duration);

    return {
      ...regularEvent,
      date: new Date(regularEvent.date),
      endDate,
      startHourStr: formatHour(regularEvent.date),
      endHourStr: formatHour(endDate),
    };
  }
}
