import { Link } from "react-router-dom";

import { AuthApi } from "@api/AuthApi";
import { useCurrentUser } from "@hooks/useCurrentUser";
import { checkDemoUser } from "@utils/utils";

import { ReactComponent as ExclamationSvg } from "./img/exclamation.svg";

import cs from "./DemoUserBanner.module.scss";

export const DemoUserBanner = () => {
  const { currentUser } = useCurrentUser();

  const isDemoUser = checkDemoUser(currentUser);

  if (!isDemoUser) return null;

  return (
    <div className={cs.root}>
      <ExclamationSvg />

      <div>
        <span>Spodobało Ci się demo? </span>
        <Link to="#" onClick={goToRegisterPage}>
          Kliknij&nbsp;tutaj
        </Link>
        <span>&nbsp;i&nbsp;załóż darmowe konto.</span>
      </div>
    </div>
  );

  async function goToRegisterPage() {
    await AuthApi.logOut();

    window.location.href = "/register";
  }
};
