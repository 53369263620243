import { ReactComponent as DropdownArrowImg } from "../../img/dropdown_arrow.svg";
import pencilImg from "../../img/pencil2.svg";
import removeImg from "../../img/remove.svg";
import { TemplateType } from "../../types/TemplateType";
import { TemplateDetailsPopover } from "../TemplateDetailsPopover";

import cs from "./Template.module.scss";

type TemplateProps = {
  template: TemplateType;
  counter: number;
  onEdit: (template: TemplateType) => void;
  onDetach: (templateId: string) => void;
  onMoveUp: (templateId: string) => void;
  onMoveDown: (templateId: string) => void;
};

export const Template = ({
  template,
  counter,
  onEdit,
  onDetach,
  onMoveUp,
  onMoveDown,
}: TemplateProps) => (
  <div className={cs.root}>
    <span className={cs.counter}>{counter}.</span>

    <span className={cs.reorderBtns}>
      <button
        className={cs.rotate}
        type="button"
        onClick={() => onMoveUp(template._id)}
      >
        <DropdownArrowImg width={11} />
      </button>
      <button
        className="btn-icon"
        type="button"
        onClick={() => onMoveDown(template._id)}
      >
        <DropdownArrowImg width={11} />
      </button>
    </span>

    <TemplateDetailsPopover template={template}>
      <span className={cs.name}>{template.topic}</span>
    </TemplateDetailsPopover>

    <button className="btn-icon" type="button" onClick={() => onEdit(template)}>
      <img src={pencilImg} alt="" height={12} />
    </button>

    <button
      className="btn-icon"
      type="button"
      onClick={() => onDetach(template._id)}
    >
      <img src={removeImg} alt="" height={11} />
    </button>
  </div>
);
