import { useCurrentUser } from "@hooks/useCurrentUser";
import { formatDate } from "@utils/date.utils";

import cs from "./Greeting.module.scss";

const TODAY = formatDate(new Date(), "full");

export const Greeting = () => {
  const { currentUser } = useCurrentUser();

  return (
    <div className={cs.Greeting}>
      <p>{capitalizeFirstLetter(TODAY)}</p>
      <p>Dzień dobry, {currentUser?.firstName}</p>
    </div>
  );
};

function capitalizeFirstLetter(str: string) {
  return str[0].toUpperCase() + str.slice(1);
}
