import classNames from "classnames";

import { NotificationType } from "../../types/NotificationType";

import closeImg from "./img/close.svg";
import { NotificationBody } from "./NotificationBody";
import { NotificationIcon } from "./NotificationIcon";

import cs from "./Notification.module.scss";

type NotificationProps = {
  notification: NotificationType;
  personal?: boolean;
  onDelete?: (id: string) => void;
};

export const Notification = ({
  notification,
  personal,
  onDelete,
}: NotificationProps) => {
  const { type, seen, _id } = notification;

  const rootCs = classNames(cs.root, {
    [cs.unseen]: !seen,
  });

  return (
    <div className={rootCs}>
      <div className="f-center">
        <NotificationIcon type={type} />
      </div>

      <span>
        <NotificationBody notification={notification} personal={personal} />
      </span>

      <button
        className={cs.deleteAction}
        title="Usuń"
        onClick={() => onDelete?.(_id)}
      >
        <img src={closeImg} alt="" />
      </button>

      <span className={cs.unseenBulb} />
    </div>
  );
};
