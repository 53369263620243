import { useRef, useState } from "react";

import { Button } from "@components/Button";
import { Calendar } from "@components/Calendar";
import { DatePicker } from "@components/DatePicker";
import { Modal } from "@components/Modal";
import { Search } from "@components/Search";
import { useGenerateArrearsReport } from "@hooks/useGenerateArrearsReport";
import { useParticipants } from "@hooks/useParticipants";
import { endOf, formatDate, startOf } from "@utils/date.utils";
import { b64toBlob } from "@utils/utils";

import cs from "./ArrearsReportModal.module.scss";

type ArrearsReportModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const ArrearsReportModal = ({
  isOpen,
  onClose,
}: ArrearsReportModalProps) => {
  const [studentIds, setStudentIds] = useState<string[]>([]);
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);

  const formElement = useRef<HTMLFormElement>(null);

  const { students = [] } = useParticipants();

  const generateArrearsReport = useGenerateArrearsReport();

  const studentNames = studentIds
    .map((studentId) => {
      const student = students.find(({ details }) => details._id === studentId);

      return student?.details.lastName;
    })
    .join(", ");

  return (
    <Modal
      title="RAPORT FINANSOWY"
      isOpen={isOpen}
      className={cs.modal}
      helpTooltip="Czym jest Raport finansowy?"
      help={`Raport finansowy to plik programu Excel, zawierający wszystkie płatności stworzone w aplikacji w wybranym okresie czasu. Lekcje opłacone oraz oczekujące na płatność, wraz z takimi informacjami jak uczeń, data lekcji czy data ostatniej modyfikacji, znajdują się w osobnych zakładkach.`}
      footer={
        <Button
          color="primary"
          isLoading={generateArrearsReport.isLoading}
          onClick={handleConfirm}
        >
          Pobierz
        </Button>
      }
      onClose={handleClose}
    >
      <form ref={formElement}>
        <Search
          value={studentIds}
          selected={studentIds}
          items={students.map(({ details }) => details)}
          placeholder="Wszyscy"
          label="Student"
          className={cs.studentInput}
          multi
          inputValue={studentNames}
          onChange={setStudentIds}
        />

        <div style={{ width: 270 }}>
          <div className="label mt20">Wybierz przedział czasowy</div>

          <div className={cs.datePickers}>
            <DatePicker
              value={dateRange[0]}
              mode="date"
              required
              onChange={(value) => {
                const endDate = dateRange[1] || endOf(value);

                setDateRange([value, endDate]);
              }}
            />

            <DatePicker
              value={dateRange[1]}
              mode="date"
              required
              onChange={(value) => {
                const startDate = dateRange[0] || startOf(value);

                setDateRange([startDate, endOf(value)]);
              }}
            />
          </div>
          <div>
            <Calendar selectRange value={dateRange} onChange={setDateRange} />
          </div>
        </div>
      </form>
    </Modal>
  );

  async function handleConfirm() {
    const isValid = formElement.current?.reportValidity();

    if (!isValid) return;

    const response = await generateArrearsReport.mutateAsync({
      dateRange: dateRange as [Date, Date],
      students: studentIds,
    });

    downloadB64Report(response.data);

    handleClose();
  }

  function handleClose() {
    onClose();

    setStudentIds([]);
    setDateRange([null, null]);
  }
};

function downloadB64Report(b64: string) {
  const blob = b64toBlob(
    b64,
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  );
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = "raport_" + formatDate(Date.now(), "iso");
  link.click();
}
