import { useMutation, useQueryClient } from "react-query";

import { AuthApi } from "@api/AuthApi";

// TODO: Optimistic update
export const useReplaceCurrentUser = () => {
  const queryClient = useQueryClient();

  return useMutation("user", AuthApi.replaceCurrentUser, {
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["user"],
        refetchInactive: true,
      });
    },
  });
};
