import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { TabBilling } from "@pages/Settings/tabs/TabBilling";
import { TabGeneral } from "@pages/Settings/tabs/TabGeneral";
import { TabSecurity } from "@pages/Settings/tabs/TabSecurity";

import { TabKeyType, Tabs } from "./Tabs";

import cs from "./Settings.module.scss";

export const Settings = () => {
  const [activeTab, setActiveTab] = useState<TabKeyType>("general");
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const tab = searchParams.get("tab") as TabKeyType;

    if (tab) {
      setActiveTab(tab);
    }
  }, [searchParams]);

  return (
    <main className={cs.root}>
      <Tabs activeTab={activeTab} />

      {activeTab === "general" && <TabGeneral />}
      {activeTab === "subscription" && <TabBilling />}
      {activeTab === "security" && <TabSecurity />}
    </main>
  );
};
