import { useState } from "react";

import { LessonDetails } from "@components/LessonDetails";
import { StudentPopover } from "@components/StudentPopover";
import { TimeSlotDetailsModal } from "@components/TimeSlotDetailsModal";
import { NotificationKind } from "@constants/NotificationKind";
import { NotificationType } from "@customTypes/NotificationType";
import { TimeSlotType } from "@customTypes/TimeSlotType";

import { FormattedDate } from "./FormattedDate";

type Props = {
  notification: NotificationType;
  personal?: boolean;
};

export const NotificationBody = ({ notification, personal }: Props) => {
  // TODO: Should be in store like other common modals
  const [isTimeSlotModalOpen, setIsTimeSlotModalOpen] = useState(false);

  const { type, data } = notification;

  switch (type) {
    case NotificationKind.LessonCancelled:
      return (
        <>
          {personal ? (
            data.participant.details.firstName
          ) : (
            <StudentPopover participant={data.participant} />
          )}

          <span> odwołał(a) lekcję z dnia </span>

          <span className="link">
            <LessonDetails lesson={data.event}>
              <span>
                <FormattedDate date={data.event.date} />
              </span>
            </LessonDetails>
          </span>
        </>
      );
    case NotificationKind.HomeworkUpdated:
      return (
        <>
          {personal ? (
            data.participant.details.firstName
          ) : (
            <StudentPopover participant={data.participant} />
          )}

          <span> zaktualizował(a) zad. domowe w lekcji z dnia </span>

          <span className="link">
            <LessonDetails lesson={data.event}>
              <span>
                <FormattedDate date={data.event.date} />
              </span>
            </LessonDetails>
          </span>
        </>
      );
    case NotificationKind.LessonPaid:
      return (
        <>
          {personal ? (
            data.participant.details.firstName
          ) : (
            <StudentPopover participant={data.participant} />
          )}

          <span> opłacił(a) lekcję z dnia </span>

          <span className="link">
            <LessonDetails lesson={data.event}>
              <span>
                <FormattedDate date={data.event.date} />
              </span>
            </LessonDetails>
          </span>
        </>
      );
    case NotificationKind.Custom:
      return <span>{data.text}</span>;
    case NotificationKind.NewTimeSlotApplication:
      return (
        <>
          {personal ? (
            data.participant.details.firstName
          ) : (
            <StudentPopover participant={data.participant} />
          )}

          <span> zaaplikował(a) na dostępność z dnia </span>

          <span className="link">
            <span onClick={() => setIsTimeSlotModalOpen(true)}>
              <FormattedDate date={data.event.date} />
            </span>

            <TimeSlotDetailsModal
              timeSlot={data.event as TimeSlotType}
              isOpen={isTimeSlotModalOpen}
              onClose={() => setIsTimeSlotModalOpen(false)}
            />
          </span>
        </>
      );
    default:
      throw new Error("Unknown notification type: " + type);
  }
};
