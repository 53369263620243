export const modules = {
  toolbar: {
    container: [
      // [
      //   { header: 1 },
      //   { header: 2 },
      //   { header: 3 },
      // ],
      ["bold", "italic", "underline" /*, "strike"*/],
      /*[{ color: [] }, { background: [] }],*/
      // [/*"image", "video",*/],
      // [{ script: "sub" }, { script: "super" }],
      ["link" /*, "blockquote" */ /*, "code-block"*/],
      [{ list: "ordered" }, { list: "bullet" } /*, { align: [] }*/],
      // [/*{ indent: "-1" }, { indent: "+1" },*/ ],
      // ["clean"],
    ],
    handlers: {
      link: function (value: string) {
        if (value) {
          let url = prompt("Wpisz adres URL");

          if (!url) return;

          if (!/^https?:\/\//i.test(url)) {
            url = "https://" + url;
          }

          this.quill.format("link", url);
        } else {
          this.quill.format("link", false);
        }
      },
    },
  },
};
