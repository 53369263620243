import { forwardRef, useEffect, useRef, useState } from "react";

import classNames from "classnames";

import { Input } from "../Input";
import { Popover } from "../Popover";

import searchImg from "./img/search.svg";
import { SearchPopoverContent } from "./SearchPopoverContent";

import cs from "./Search.module.scss";

type SearchProps = {
  placeholder?: string;
  className?: string;
  items: any[];
  label?: string;
  labelField?: string;
  valueField?: string;
  multi?: boolean;
  disabled?: boolean;
  required?: boolean;
  inputValue?: string;
  value?: any;
  selected: any[];
  display?: "search" | "select";
  onChange: (selected: any[] | any) => void;
  onInputChange?: (value: string) => void;
};

export const Search = forwardRef<any, SearchProps>(
  (
    {
      placeholder = "Wyszukaj",
      className,
      items,
      label,
      labelField = "name",
      valueField = "_id",
      selected,
      multi,
      disabled,
      required,
      inputValue,
      value,
      display = "search",
      onChange,
      onInputChange,
    },
    ref,
  ) => {
    const [filterValue, setFilterValue] = useState("");

    const popoverElement = useRef<any>(null);

    const rootCs = classNames(cs.root, className, {
      [cs.withLabel]: Boolean(label),
    });

    const filteredResults = items.filter((item) =>
      item[labelField]?.toLowerCase().includes(filterValue.toLowerCase()),
    );

    useEffect(() => {
      if (value && value[labelField]) {
        setFilterValue(value[labelField]);
      }
    }, [labelField, value]);

    return (
      <Popover
        popover={
          <SearchPopoverContent
            items={filteredResults}
            labelField={labelField}
            valueField={valueField}
            selected={selected}
            multi={multi}
            onToggle={handleItemToggle}
            onSelect={handleSelected}
          />
        }
        position={["bottom", "top"]}
        square
        adjustWidth
        className={cs.popover}
        ref={popoverElement}
      >
        <div
          className={rootCs}
          onClickCapture={(e) => {
            if (disabled) {
              e.preventDefault();
              e.stopPropagation();
            }
          }}
        >
          <img src={searchImg} alt="" width={16} className={cs.icon} />

          <Input
            value={inputValue || filterValue}
            placeholder={placeholder}
            className={cs.input}
            label={label}
            disabled={disabled}
            required={required}
            ref={ref}
            onChange={handleInputChanged}
          />
        </div>
      </Popover>
    );

    function handleItemToggle(checked: boolean, itemValue: string) {
      if (checked) {
        onChange([...selected, itemValue]);
      } else {
        onChange(selected.filter((item) => item !== itemValue));
      }
    }

    function handleSelected(item: any) {
      onChange(item);

      popoverElement.current?.close();
    }

    function handleInputChanged(newValue: string) {
      if (multi && inputValue) {
        setFilterValue("");
        return;
      }

      setFilterValue(newValue);
      onInputChange?.(newValue);

      popoverElement.current?.open();
    }
  },
);
