import { useEffect } from "react";

import { CurrentUserType } from "@customTypes/CurrentUserType";

type DocumentWithTidio = Document &
  Partial<{
    tidioIdentify?: {
      distinct_id: string;
      name: string;
      email: string;
      phone: string;
    };
  }>;

export const useTidioChat = (user?: CurrentUserType) => {
  useEffect(() => {
    if (process.env.REACT_APP_ENV !== "production") return;

    if (!user) return;

    const document: DocumentWithTidio = window.document;
    const existingScript = document.getElementById("tidio_script");

    if (existingScript) return;

    document.tidioIdentify = {
      distinct_id: user._id,
      email: user.email,
      name: user.firstName + " " + user.lastName,
      phone: user.phone || "",
    };

    const script = document.createElement("script");

    script.id = "tidio_script";
    script.src = "//code.tidio.co/a8aebkn7ex2ofthcdxfkbpeneu4id14t.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [user]);
};
