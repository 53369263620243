import { Button } from "../Button";
import { Modal } from "../Modal";

import cs from "./ConfirmationModal.module.scss";

type ConfirmationModalProps = {
  isOpen: boolean;
  text?: string;
  confirmText?: string;
  onCancel: () => void;
  onConfirm: () => void;
};

export const ConfirmationModal = ({
  isOpen,
  text = "Czy na pewno chcesz wykonać tę akcję?",
  confirmText = "Potwierdzam",
  onCancel,
  onConfirm,
}: ConfirmationModalProps) => {
  return (
    <Modal
      title="Potwiedzenie"
      isOpen={isOpen}
      className={cs.modal}
      clickOutsideToClose={false}
      footer={
        <>
          <Button color="neutral" type="outlined" onClick={onCancel}>
            Anuluj
          </Button>
          <Button color="primary" className="ml8" onClick={onConfirm}>
            {confirmText}
          </Button>
        </>
      }
      onClose={onCancel}
    >
      {text}
    </Modal>
  );
};
