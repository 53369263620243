import { useEffect, useState } from "react";

import { CalendarSettingsModal } from "@components/CalendarSettingsModal";
import { CalendarViewType } from "@constants/CalendarViewOptions";
import { CurrentUserType } from "@customTypes/CurrentUserType";
import { useCalendarEvents } from "@hooks/useCalendarEvents";
import { useCurrentUser } from "@hooks/useCurrentUser";
import { ReactComponent as SettingsImg } from "@img/settings_new.svg";
import {
  addDays,
  addMonths,
  endOf,
  formatMonth,
  formatWeekday,
  getDatesInRange,
  isToday,
  startOf,
  subtractDays,
  subtractMonths,
} from "@utils/date.utils";

import { ReactComponent as ArrowLeftImg } from "./img/arrow_left.svg";
import { ReactComponent as ArrowRightImg } from "./img/arrow_right.svg";
import { MonthView } from "./MonthView";
import { ViewPicker } from "./ViewPicker";
import { WeekView } from "./WeekView";

import cs from "./CalendarPage.module.scss";

const TODAY = new Date();

export const CalendarPage = () => {
  const { currentUser } = useCurrentUser() as { currentUser: CurrentUserType };

  const [selectedDate, setSelectedDate] = useState(TODAY);
  const [currentView, setCurrentView] = useState<CalendarViewType>(
    currentUser.settingCalendarDefaultView,
  );
  const [startDate, setStartDate] = useState(
    getStartDate(currentView, selectedDate),
  );
  const [endDate, setEndDate] = useState(getEndDate(currentView, selectedDate));
  const [isCalendarSettingsModalOpen, setIsCalendarSettingsModalOpen] =
    useState(false);

  const month = formatMonth(selectedDate, "long");

  const { data = [] } = useCalendarEvents(startDate, endDate);

  useEffect(() => {
    setStartDate(getStartDate(currentView, selectedDate));
    setEndDate(getEndDate(currentView, selectedDate));
  }, [selectedDate, currentView]);

  const datesRange = getDatesInRange(startDate, endDate);

  return (
    <div className={cs.page}>
      <section className={cs.calendar}>
        <header className={cs.header}>
          <div className={cs.currentDate}>
            <span>
              {currentView !== "month" && (
                <span>
                  {startDate.getDate()} - {endDate.getDate()}{" "}
                </span>
              )}

              <span>
                {month} {currentView === "month" && selectedDate.getFullYear()}
              </span>
            </span>

            <button
              className="btn-icon ml8"
              onClick={() => setIsCalendarSettingsModalOpen(true)}
            >
              <SettingsImg width={16} />
            </button>
          </div>

          <div className={cs.dateNavigator}>
            <button className="btn-icon" onClick={handlePrevDate}>
              <ArrowLeftImg width={14} />
            </button>

            <button className="btn-icon" onClick={handleReturnToToday}>
              <span className={cs.dateNavigator_today}>Dzisiaj</span>
            </button>

            <button className="btn-icon" onClick={handleNextDate}>
              <ArrowRightImg width={14} />
            </button>
          </div>

          <div className="ml-auto">
            <ViewPicker selected={currentView} onChange={setCurrentView} />
          </div>
        </header>

        {currentView !== "month" && (
          <section className={cs.weekdayNames} data-cols={datesRange.length}>
            {datesRange.map((date) => {
              let className = cs.weekdayName;

              if (isToday(date)) {
                className += " " + cs.weekdayName__today;
              }

              return (
                <div key={date.getDate()} className={className}>
                  <div>
                    {formatWeekday(date, "short") + " " + date.getDate()}
                  </div>
                </div>
              );
            })}
          </section>
        )}

        {currentView !== "month" && (
          <WeekView startDate={startDate} endDate={endDate} events={data} />
        )}

        {currentView === "month" && (
          <MonthView currentDate={selectedDate} events={data} />
        )}
      </section>

      <CalendarSettingsModal
        isOpen={isCalendarSettingsModalOpen}
        onClose={() => setIsCalendarSettingsModalOpen(false)}
      />
    </div>
  );

  function handlePrevDate() {
    setSelectedDate((prevDate) => {
      if (currentView === "month") {
        return subtractMonths(selectedDate, 1);
      }

      return subtractDays(selectedDate, 7);
    });
  }

  function handleNextDate() {
    setSelectedDate((prevDate) => {
      if (currentView === "month") {
        return addMonths(selectedDate, 1);
      }

      return addDays(selectedDate, 7);
    });
  }

  function handleReturnToToday() {
    setSelectedDate(new Date());
  }
};

function getStartDate(currentView: CalendarViewType, selectedDate: Date) {
  if (currentView === "week") {
    return startOf(selectedDate, "week");
  } else if (currentView === "workweek") {
    return startOf(selectedDate, "week");
  } else if (currentView === "month") {
    return startOf(selectedDate, "month");
  }
}

function getEndDate(currentView: CalendarViewType, selectedDate: Date) {
  if (currentView === "week") {
    return endOf(selectedDate, "week");
  } else if (currentView === "workweek") {
    return subtractDays(endOf(selectedDate, "week"), 2);
  } else if (currentView === "month") {
    return endOf(selectedDate, "month");
  }
}
