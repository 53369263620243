import { LessonPaymentStatus } from "@constants/LessonPaymentStatus";
import { PaginationType } from "@customTypes/PaginationType";
import { StandaloneArrearDtoService } from "@services/StandaloneArrearDtoService";
import { filtersToUrlParams, paginationToUrlParams } from "@utils/api.utils";

import { createAxiosInstance } from "./_createAxiosInstance";

const api = createAxiosInstance("/api/finances");

export class FinancesApi {
  static async getStandaloneArrears(
    filters: Record<string, any>,
    sort: string[],
    pagination: PaginationType,
  ) {
    const { data } = await api.get("/arrear", {
      params: {
        ...filtersToUrlParams(filters),
        status: filters.status,
        sort,
        ...paginationToUrlParams(pagination),
      },
    });

    return StandaloneArrearDtoService.transformAll(data);
  }

  static changePaymentStatus({
    arrearId,
    status,
  }: {
    arrearId: string;
    status: LessonPaymentStatus;
  }) {
    return api.patch("/arrear/" + arrearId, {
      status,
    });
  }

  static generateArrearsReport({
    students,
    dateRange,
  }: {
    students: string[];
    dateRange: [Date, Date];
  }) {
    return api.post(
      "/arrear/report",
      {},
      {
        params: {
          dateRange,
          students,
          offset: new Date().getTimezoneOffset(),
        },
      },
    );
  }
}
