import { Checkbox } from "@components/Checkbox";
import { MaterialType } from "@customTypes/MaterialType";

import { ReactComponent as FolderImg } from "./img/folder.svg";

import cs from "./Materials.module.scss";

type FolderProps = {
  checked: boolean;
  material: MaterialType;
  onSelect: (folder: MaterialType) => void;
  onToggle: (material: MaterialType, checked: boolean) => void;
};

export const Folder = ({
  material,
  checked,
  onSelect,
  onToggle,
}: FolderProps) => (
  <div className={cs.list_item__folder} onClick={() => onSelect(material)}>
    <span
      className="v-center"
      onClick={(e) => {
        e.stopPropagation();
        onToggle(material, !checked);
      }}
    >
      <Checkbox checked={checked} className={cs.checkbox} />
    </span>

    <span className={cs.list_itemName}>
      <FolderImg width={14} />

      <span>{material.name}</span>
    </span>

    <span />
    <span />
  </div>
);
