import { ApplicationType } from "@customTypes/ApplicationType";

import calendarImg from "./img/calendar_blue.svg";

import cs from "./TimeSlotDetailsModal.module.scss";

export const Application = ({
  application,
  onSelect,
}: {
  application: ApplicationType;
  onSelect: (selectedApplication: ApplicationType) => void;
}) => (
  <div className={cs.application}>
    <div>{application.student.name}</div>

    <div className="mr40">
      <p className={cs.application_date}>
        {application.startHourStr}-{application.endHourStr}
      </p>
      <p className={cs.application_time}>{application.duration} minut</p>
    </div>

    <div>
      <button className="btn-icon" onClick={() => onSelect(application)}>
        <img src={calendarImg} alt="" />
      </button>
    </div>
  </div>
);
