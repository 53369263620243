// @ts-nocheck
import { useState } from "react";

import { Button } from "@components/Button";
import { RadioButton } from "@components/RadioButton";
import { ReactComponent as RemoveImg } from "@img/remove.svg";

import { FilterStudents } from "./FilterStudents";

import cs from "./FinancesListPage.module.scss";

export const Filters = ({ filters, onChange, onClear }) => {
  const [stateFiltersExpanded, setStateFiltersExpanded] = useState(false);

  return (
    <section className={cs.filters}>
      <div className="mb24">
        <div className={cs.filters_title}>Status</div>
        <div className={cs.filters_status}>
          <RadioButton
            checked={filters.status === "all"}
            label="Wszystkie"
            name="finances_status"
            value="all"
            onChange={handleStatusFilterChanged}
          />
          <RadioButton
            checked={filters.status === "after_deadline"}
            label="Po terminie płatności"
            name="finances_status"
            value="after_deadline"
            onChange={handleStatusFilterChanged}
          />
          <RadioButton
            checked={filters.status === "near_deadline"}
            label="Zbliżający się termin płatności"
            name="finances_status"
            value="near_deadline"
            onChange={handleStatusFilterChanged}
          />
          <RadioButton
            checked={filters.status === "recently_paid"}
            label="Niedawno opłacone"
            name="finances_status"
            value="recently_paid"
            onChange={handleStatusFilterChanged}
          />
          <RadioButton
            checked={filters.status === "incomplete_data"}
            label="Bez ceny lub terminu"
            name="finances_status"
            value="incomplete_data"
            onChange={handleStatusFilterChanged}
          />

          {stateFiltersExpanded && (
            <>
              <RadioButton
                checked={filters.status === "all_awaiting"}
                label="Wszystkie nieopłacone"
                name="finances_status"
                value="all_awaiting"
                onChange={handleStatusFilterChanged}
              />
              <RadioButton
                checked={filters.status === "paid"}
                label="Opłacone"
                name="finances_status"
                value="paid"
                onChange={handleStatusFilterChanged}
              />
            </>
          )}
        </div>

        {!stateFiltersExpanded && (
          <div className="text-center">
            <button
              className={cs.showMore}
              onClick={() => {
                setStateFiltersExpanded(true);
              }}
            >
              Pokaż więcej
            </button>
          </div>
        )}
      </div>

      <div>
        <FilterStudents students={filters.students} onChange={onChange} />
      </div>

      <div className="f-center mt35">
        <Button
          color="dark"
          type="outlined"
          className={cs.filters_clearBtn}
          onClick={onClear}
        >
          <RemoveImg width={11} height={11} />
          <span>Wyczyść filtry</span>
        </Button>
      </div>
    </section>
  );

  function handleStatusFilterChanged(value: string) {
    onChange("status", value);
  }
};
