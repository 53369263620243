import { useRef, useState } from "react";

import { Button } from "@components/Button";
import { Input } from "@components/Input";
import { Modal } from "@components/Modal";
import { useAddFolder } from "@hooks/useAddFolder";

import cs from "./AddFolderModal.module.scss";

type AddFolderModalProps = {
  isOpen: boolean;
  parentId: string | null;
  onClose: () => void;
};

export const AddFolderModal = ({
  isOpen,
  parentId,
  onClose,
}: AddFolderModalProps) => {
  const [name, setName] = useState("");

  const formElement = useRef<HTMLFormElement>(null);

  const addFolder = useAddFolder();

  return (
    <Modal
      title="Dodaj folder"
      isOpen={isOpen}
      className={cs.modal}
      footer={
        <Button
          color="primary"
          isLoading={addFolder.isLoading}
          onClick={handleConfirm}
        >
          Dodaj
        </Button>
      }
      onClose={onClose}
    >
      <form
        ref={formElement}
        className={cs.form}
        onKeyDown={async (e) => {
          if (e.code === "Enter") {
            e.preventDefault();

            await handleConfirm();
          }
        }}
      >
        <Input label="Nazwa" value={name} required onChange={setName} />
      </form>
    </Modal>
  );

  async function handleConfirm() {
    const isValid = formElement.current?.reportValidity();

    if (!isValid) return;

    await addFolder.mutateAsync({
      name,
      parentId,
    });

    onClose();
    clear();
  }

  function clear() {
    setName("");
  }
};
