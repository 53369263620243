import { ParticipantType } from "@customTypes/ParticipantType";
import { getState } from "@store";
import { replaceWithId } from "@utils/utils";

import { queryClient } from "../index";

export class ParticipantService {
  static updateCache(updatedParticipant: ParticipantType) {
    // NOTE: Query client
    queryClient.setQueryData<any>(
      ["participants", "participant", updatedParticipant._id],
      () => updatedParticipant,
    );
    queryClient.setQueryData<any>(
      ["participants"],
      (previous: ParticipantType[]) => {
        return replaceWithId(previous, updatedParticipant);
      },
    );

    // NOTE: Store
    const participants = getState().participants;
    const setParticipants = getState().setParticipants;

    setParticipants(
      participants.map((participant) => {
        if (participant._id === updatedParticipant._id) {
          return updatedParticipant;
        }

        return participant;
      }),
    );
  }
}
