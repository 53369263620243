import { useEffect, useState } from "react";

import { CompletedHomeworkPill } from "@components/CompletedHomeworkPill";
import { DatePicker } from "@components/DatePicker";
import { LessonBulb } from "@components/LessonBulb";
import { MaterialPill } from "@components/MaterialPill";
import { PaymentStatus } from "@components/PaymentStatus";
import { LessonPaymentStatus } from "@constants/LessonPaymentStatus";
import { LessonType } from "@customTypes/LessonType";
import { ReactComponent as PencilImg } from "@img/pencil2.svg";
import { formatPrice } from "@utils/currency.utils";
import { formatDate, formatShortDate, formatWeekday } from "@utils/date.utils";
import { downloadFile } from "@utils/utils";

import cs from "./Details.module.scss";

type DetailsProps = {
  lesson: LessonType;
  onParticipantClick: () => void;
  onDateChange: (updatedDate: Date) => void;
};

export const Details = ({
  lesson,
  onParticipantClick,
  onDateChange,
}: DetailsProps) => {
  const [date, setDate] = useState(lesson.date);

  useEffect(() => {
    if (lesson.date) {
      setDate(lesson.date);
    }
  }, [lesson.date]);

  return (
    <div className={cs.info}>
      <span className={cs.label}>Uczestnik:</span>
      <span className="link" onClick={onParticipantClick}>
        {lesson.participant.details.name}
      </span>

      <span className={cs.label}>Data:</span>
      <span>
        {!lesson.isDeleted && (
          <LessonBulb
            past={lesson.isPast}
            size="small"
            className="mr4"
            top={-2}
          />
        )}

        <span className="semi-bold">{formatShortDate(lesson.date)}</span>

        <span> ({formatWeekday(lesson.date, "long")})</span>

        {!lesson.isDeleted && (
          <button className="btn-icon ml6" style={{ position: "relative" }}>
            <PencilImg width={13} />

            <span className={cs.datepicker}>
              <DatePicker
                mode="datetime"
                value={date}
                asModal
                onChange={setDate}
                onCalendarClose={() => onDateChange(date)}
              />
            </span>
          </button>
        )}
      </span>

      <span className={cs.label}>Godzina:</span>
      <span>
        <span className="semi-bold">
          {lesson.startHourStr} - {lesson.endHourStr}
        </span>
        <span> ({lesson.duration} minut)</span>
      </span>

      <span className={cs.label}>Temat:</span>
      <span>{lesson.topic || "-"}</span>

      <div className={cs.label}>
        <div>
          Zad. <br /> domowe:
        </div>

        {lesson.completedHomeworks.length > 0 && (
          <button
            className="btn-icon link"
            onClick={(e) => {
              lesson.completedHomeworks.forEach((homework) => {
                downloadFile(homework.url);
              });
            }}
          >
            Pobierz
          </button>
        )}
      </div>
      <span className={cs.materials}>
        {lesson.completedHomeworks.length === 0 && "-"}

        {lesson.completedHomeworks.map((homework) => (
          <CompletedHomeworkPill key={homework._id} homework={homework} />
        ))}
      </span>

      <span className={cs.label}>Materiały:</span>
      <span className={cs.materials}>
        {lesson.materials.length === 0 && "-"}

        {lesson.materials.map((material) => (
          <MaterialPill key={material._id} material={material} />
        ))}
      </span>

      <span className={cs.label}>Finanse:</span>
      <span>
        <span className="semi-bold">
          {lesson.price ? formatPrice(lesson.price) : "-"}
        </span>

        {/* TODO: */}
        {lesson.arrears[0].status === LessonPaymentStatus.AWAITS &&
          lesson.paymentDeadline && (
            <span> ({formatDate(lesson.paymentDeadline)})</span>
          )}

        <PaymentStatus arrears={lesson.arrears} size="small" className="ml4" />
      </span>

      <span className={cs.label}>Prywatna notatka:</span>
      <span>{lesson.privateNote || "-"}</span>

      <span className={cs.label}>Opis lekcji:</span>
      <span dangerouslySetInnerHTML={{ __html: lesson.desc || "-" }} />
    </div>
  );
};
