import React, { forwardRef, HTMLInputTypeAttribute } from "react";

import classNames from "classnames";

import cs from "./Input.module.scss";

type InputProps = {
  value: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  name?: string;
  className?: string;
  pattern?: string;
  autocomplete?: string;
  min?: number;
  max?: number;
  type?: HTMLInputTypeAttribute;
  onChange?: (updatedValue: string) => void;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
};

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      value,
      label,
      placeholder,
      required,
      disabled,
      readonly,
      name,
      className,
      pattern,
      autocomplete,
      min,
      max,
      type = "text",
      onChange,
      onClick,
      onBlur,
    },
    ref,
  ) => {
    const rootCs = classNames(cs.input, className, {
      [cs.disabled]: disabled,
    });

    return (
      <span className={`${cs.root} InputRoot`}>
        {label && <label className="label">{label}</label>}

        <input
          value={value}
          className={rootCs}
          placeholder={placeholder}
          title={value}
          required={required}
          type={type}
          ref={ref}
          disabled={disabled}
          readOnly={readonly}
          pattern={pattern}
          name={name}
          autoComplete={autocomplete}
          min={min}
          max={max}
          onChange={(e) => onChange?.(e.target.value)}
          onClick={onClick}
          onBlur={onBlur}
        />
      </span>
    );
  },
);
