import React from "react";

import classNames from "classnames";

import cs from "./Chips.module.scss";

type ChipsProps = {
  children: React.ReactNode;
  color: "neutral" | "primary" | "danger" | "orange";
  className?: string;
  title?: string;
  onClick?: (e: React.MouseEvent) => void;
};

export const Chips = ({
  children,
  color,
  className,
  title,
  onClick,
}: ChipsProps) => {
  const rootCs = classNames("Chips", cs.root, cs[color], className);

  return (
    <span className={rootCs} title={title} onClick={onClick}>
      {children}
    </span>
  );
};

Chips.defaultProps = {
  onClick: () => {},
};
