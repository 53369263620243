import { useEffect, useState } from "react";

import { Button } from "@components/Button";
import { Modal } from "@components/Modal";
import { MaterialDtoType } from "@customTypes/MaterialDtoType";
import { MaterialType } from "@customTypes/MaterialType";
import { ReactComponent as RemoveImg } from "@img/remove.svg";
import { Materials } from "@pages/MaterialsPage/Materials";

import { EmptyList } from "./EmptyList";

import cs from "./MaterialsModal.module.scss";

type MaterialsModalProps = {
  isOpen: boolean;
  materials?: MaterialDtoType[] | null;
  onClose: () => void;
  onConfirm: (materials: MaterialDtoType[]) => void;
};

export const MaterialsModal = ({
  isOpen,
  materials,
  onClose,
  onConfirm,
}: MaterialsModalProps) => {
  const [currentFolder, setCurrentFolder] = useState<MaterialType | null>(null);
  const [checked, setChecked] = useState<MaterialDtoType[]>([]);

  useEffect(() => {
    if (isOpen) {
      setChecked(materials || []);
    }
  }, [isOpen, materials]);

  return (
    <Modal
      title="Załącz materiały"
      isOpen={isOpen}
      className={cs.modal}
      footer={
        <Button color="primary" onClick={handleConfirm}>
          Zatwierdź
        </Button>
      }
      onClose={onClose}
    >
      <div className={cs.body}>
        <Materials
          checked={checked}
          currentFolder={currentFolder}
          defaultView="list"
          condensed
          preventDownload={true}
          setCurrentFolder={setCurrentFolder}
          emptyList={<EmptyList />}
          onToggle={(material, isChecked) => {
            if (isChecked) {
              setChecked([...checked, material]);
            } else {
              uncheck(material);
            }
          }}
        />

        <div className={cs.selected}>
          <div className={cs.selected_header}>Wybrane:</div>

          <div className={cs.selected_list}>
            {checked.length === 0 && (
              <div className={cs.noMaterialsSelected}>
                Nie wybrano żadnych materiałów...
              </div>
            )}

            {checked.map((checked) => (
              <div className={cs.selected_listItem} title={checked.name}>
                <span></span>
                <span className={cs.selected_listItemName}>{checked.name}</span>
                <span>
                  <button className="btn-icon" onClick={() => uncheck(checked)}>
                    <RemoveImg width={10} />
                  </button>
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );

  function handleConfirm() {
    onConfirm(checked);
    onClose();
  }

  function uncheck(material: MaterialDtoType) {
    setChecked(checked.filter(({ _id }) => _id !== material._id));
  }
};
