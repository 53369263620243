import { useQuery } from "react-query";

import { LessonApi } from "@api/LessonApi";
import { PaginationType } from "@customTypes/PaginationType";

export const useLessons = (
  filters: any,
  sort: any,
  pagination: PaginationType,
) => {
  const { data, isLoading } = useQuery(
    ["lessons", sort, filters, pagination],
    () => {
      return LessonApi.fetchAll(filters, sort, pagination);
    },
    {
      staleTime: 3e4,
      keepPreviousData: true,
    },
  );

  return {
    data,
    isLoading,
  };
};
