// @ts-nocheck

import { MaterialType } from "../../types/MaterialType";

import cs from "./Materials.module.scss";

type RecursiveNavigationProps = {
  folder: MaterialType | null;
  onSelect: (folder: MaterialType | null) => void;
};

export const RecursiveNavigation = ({
  folder,
  onSelect,
}: RecursiveNavigationProps) => {
  if (!folder) return null;

  return (
    <>
      {folder.ancestors.map((ancestor) => (
        <>
          <span
            className={`${cs.navigationChips_navigate} RecursiveNavigation_name`}
            onClick={() => onSelect(ancestor._id)}
          >
            {ancestor.name}
          </span>
          <span>{" > "}</span>
        </>
      ))}

      <span
        className={`${cs.navigationChips_navigate} RecursiveNavigation_name`}
        onClick={() => onSelect(folder)}
      >
        {folder.name}
      </span>
    </>
  );
};
