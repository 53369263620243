import { ReactComponent as PencilImg } from "../../img/pencil.svg";
import { TemplateType } from "../../types/TemplateType";
import { MaterialPill } from "../MaterialPill";

import cs from "./TemplateDetailsPopover.module.scss";

type TemplateDetailsPopoverContentProps = {
  template: TemplateType;
  onEdit: () => void;
};

export const TemplateDetailsPopoverContent = ({
  template,
  onEdit,
}: TemplateDetailsPopoverContentProps) => {
  return (
    <div className={cs.root}>
      <div className={cs.header}>Szablon</div>
      <div className={cs.info}>
        <span className={cs.label}>Temat:</span>
        <span>{template.topic || "-"}</span>

        <span className={cs.label}>Materiały:</span>
        <span className={cs.materials}>
          {template.materials.length === 0 && "-"}

          {template.materials.map((material) => (
            <MaterialPill key={material._id} material={material} />
          ))}
        </span>

        <span className={cs.label}>Opis lekcji:</span>
        <span
          dangerouslySetInnerHTML={{ __html: template.noteForStudent || "-" }}
        />
      </div>

      <div className={cs.footer}>
        <button className="btn-icon" onClick={onEdit}>
          <PencilImg />
        </button>
      </div>
    </div>
  );
};
