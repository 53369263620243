import { useState } from "react";

import { ReactComponent as ThreeDotsImg } from "../../img/three_dots_horizontal.svg";
import { StudentType } from "../../types/StudentType";
import { Dropdown } from "../Dropdown";
import { InvitationModal } from "../InvitationModal";
import { UpdateStudentModal } from "../UpdateStudentModal";

type ActionProps = {
  student: StudentType;
};

export const Actions = ({ student }: ActionProps) => {
  const [isEditStudentModalOpen, setIsEditStudentModalOpen] = useState(false);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);

  const dropdownOptions = [
    <div onClick={() => setIsEditStudentModalOpen(true)}>Edytuj dane</div>,
    <div onClick={() => {}}>Usuń ucznia</div>,
  ];

  dropdownOptions.push(
    <div onClick={() => setIsInviteModalOpen(true)}>
      {student.hasAccount ? "Ponów zaproszenie" : "Wyślij zaproszenie"}
    </div>,
  );

  return (
    <>
      <Dropdown options={dropdownOptions}>
        <button className="btn-icon">
          <ThreeDotsImg width={21} />
        </button>
      </Dropdown>

      <UpdateStudentModal
        isOpen={isEditStudentModalOpen}
        student={student}
        onClose={() => {
          setIsEditStudentModalOpen(false);
        }}
      />

      <InvitationModal
        isOpen={isInviteModalOpen}
        student={student}
        onClose={() => setIsInviteModalOpen(false)}
      />
    </>
  );
};
