import { useMutation, useQueryClient } from "react-query";

import { ContactApi } from "@api/ContactApi";

export const useDeleteContact = () => {
  const queryClient = useQueryClient();

  return useMutation("contact", ContactApi.deleteById, {
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["students"],
        refetchInactive: true,
      });

      await queryClient.invalidateQueries({
        queryKey: ["participants"],
        refetchInactive: true,
      });
    },
  });
};
