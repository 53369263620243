import { useMutation, useQueryClient } from "react-query";

import { InvitationApi } from "@api/InvitationApi";

export const useAddInvitation = () => {
  const queryClient = useQueryClient();

  return useMutation("invitation", InvitationApi.create, {
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["students"],
        refetchInactive: true,
      });

      queryClient.invalidateQueries({
        queryKey: ["participants"],
        refetchInactive: true,
      });
    },
  });
};
