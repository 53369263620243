import { AssignedTemplatesManager } from "@components/AssignedTemplatesManager";
import { ElevatedIconBtn } from "@components/ElevatedIconBtn";
import { SidePanel as SidePanelBase } from "@components/SidePanel";
import { PlanType } from "@customTypes/PlanType";
import { formatDate } from "@utils/date.utils";
import { isSmallScreen } from "@utils/utils";

import { DetailsModal } from "./DetailsModal";

import cs from "./PlansListPage.module.scss";

type SidePanelProps = {
  activePlan: PlanType | null;
  onTemplateChange: (updatedPlan: PlanType) => void;
  onEdit: () => void;
  onDelete: () => void;
  onClose: () => void;
};

export const SidePanel = ({
  activePlan,
  onEdit,
  onTemplateChange,
  onDelete,
  onClose,
}: SidePanelProps) => {
  if (!activePlan) return null;

  if (isSmallScreen) {
    return (
      <DetailsModal
        isOpen
        activePlan={activePlan}
        onTemplateChange={onTemplateChange}
        onEdit={onEdit}
        onDelete={onDelete}
        onClose={onClose}
      />
    );
  }

  return (
    <SidePanelBase
      actions={
        <>
          <ElevatedIconBtn icon="edit" onClick={onEdit} />
          <ElevatedIconBtn icon="trash" onClick={onDelete} />
        </>
      }
      main={
        <>
          <div className={cs.sidePanelSection__main}>
            <div className="medium">Nazwa:</div>
            <div className="medium">{activePlan.name}</div>

            <div className="medium">Utworzono:</div>
            <div>{formatDate(activePlan.createdAt)}</div>

            <div className="medium">Notatka:</div>
            <div>{activePlan.note || "-"}</div>
          </div>

          <div>
            <AssignedTemplatesManager
              templates={activePlan.templates}
              onChange={(updatedTemplates) => {
                onTemplateChange({
                  ...activePlan,
                  templates: updatedTemplates,
                });
              }}
            />
          </div>
        </>
      }
      onClose={onClose}
    />
  );
};
