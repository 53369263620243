import React, { useRef } from "react";

import classNames from "classnames";

import { Popover } from "@components/Popover";

import cs from "./Dropdown.module.scss";

type DropdownProps = {
  title?: string;
  className?: string;
  adjustWidth?: boolean;
  options: React.ReactElement[];
  children: React.ReactNode;
};

export const Dropdown = ({
  title,
  className,
  adjustWidth,
  options,
  children,
}: DropdownProps) => {
  const popoverEl = useRef<any>(null);

  const popoverCs = classNames(cs.root, className);

  const dropdown = (
    <div>
      {title && <div className={cs.title}>{title}</div>}

      <div className={cs.options}>
        {options.map((option, index) => (
          <div
            key={index}
            className={cs.option}
            onClick={() => {
              popoverEl.current.close();
            }}
          >
            {option}
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <Popover
      position={["bottom", "top", "right", "left"]}
      popover={dropdown}
      className={popoverCs}
      ref={popoverEl}
      adjustWidth={adjustWidth}
    >
      {children}
    </Popover>
  );
};
