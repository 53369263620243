import { useMutation, useQueryClient } from "react-query";

import { MaterialApi } from "../api/MaterialApi";

export const useAddFolder = () => {
  const queryClient = useQueryClient();

  return useMutation("materials", MaterialApi.createFolder, {
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["materials"],
        refetchInactive: true,
      });
    },
  });
};
