import { useState } from "react";

import { StudentPopover } from "@components/StudentPopover";
import { ParticipantKind } from "@constants/ParticipantType";
import { TodoType } from "@customTypes/TodoType";

import { ReactComponent as DeleteImg } from "./img/delete_action.svg";
import { ReactComponent as DoneImg } from "./img/done_action.svg";
import { ReactComponent as EditImg } from "./img/edit_action.svg";
import { ReactComponent as TodoIconImg } from "./img/todo_icon.svg";
import { EditedTodo } from "./EditedTodo";

import cs from "./Todo.module.scss";

type TodoProps = {
  todo: TodoType;
  personal?: boolean;
  onDelete: (todoId: string) => void;
  onDone: (todo: TodoType) => void;
  onSave: (updatedTodo: TodoType) => Promise<any>;
};

export const Todo = ({
  todo,
  personal,
  onDelete,
  onDone,
  onSave,
}: TodoProps) => {
  const [isEdited, setIsEdited] = useState(false);

  if (isEdited) {
    return <EditedTodo todo={todo} personal={personal} onSave={handleSave} />;
  }

  return (
    <div className={cs.root}>
      <div className="f-center">
        <TodoIconImg width={16} />
      </div>

      <div>
        <div className={cs.value}>{todo.value}</div>

        {!personal && todo.participant?.type === ParticipantKind.student && (
          <StudentPopover participant={todo.participant} />
        )}
        <div></div>
      </div>

      <div className={cs.additionalActions}>
        <button
          className="btn-icon"
          title="Edytuj"
          onClick={() => setIsEdited(true)}
        >
          <EditImg width={28} />
        </button>

        <button
          className="btn-icon"
          title="Usuń"
          onClick={() => onDelete(todo._id)}
        >
          <DeleteImg width={28} />
        </button>
      </div>

      <button
        className="btn-icon"
        title="Oznacz jako wykonane"
        onClick={() => onDone(todo)}
      >
        <DoneImg width={28} />
      </button>
    </div>
  );

  async function handleSave(updatedTodo: TodoType) {
    await onSave(updatedTodo);

    setIsEdited(false);
  }
};
