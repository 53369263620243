import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";

import { RadioButton } from "@components/RadioButton";
import { LessonPaymentStatus } from "@constants/LessonPaymentStatus";
import { PaginationType } from "@customTypes/PaginationType";
import { StandaloneArrearType } from "@customTypes/StandaloneArrearType";
import { useChangeArrearStatus } from "@hooks/useChangeArrearStatus";
import { useStandaloneArrears } from "@hooks/useStandaloneArrears";
import { useAppStore } from "@store";

import { ListItem } from "./ListItem";

import cs from "./TabFinances.module.scss";

type FilterType =
  | "afterDeadline"
  | "incompleteData"
  | "recentlyPaid"
  | "nearDeadline"
  | undefined;

const PAGINATION: PaginationType = { page: 1, pageSize: 30 };

export const TabFinances = () => {
  const [filter, setFilter] = useState<FilterType>();

  const queryClient = useQueryClient();

  const { data: afterDeadline = [], isFetched: afterDeadlineFetched } =
    useStandaloneArrears(
      { status: "after_deadline" },
      ["paymentDeadline"],
      PAGINATION,
    );
  const { data: recentlyPaid = [], isFetched: recentlyPaidFetched } =
    useStandaloneArrears(
      { status: "recently_paid" },
      ["-updatedAt"],
      PAGINATION,
    );
  const { data: incompleteData = [], isFetched: incompleteDataFetched } =
    useStandaloneArrears({ status: "incomplete_data" }, ["date"], PAGINATION);
  const { data: nearDeadline = [], isFetched: nearDeadlineFetched } =
    useStandaloneArrears(
      { status: "near_deadline" },
      ["paymentDeadline"],
      PAGINATION,
    );

  const changeStatus = useChangeArrearStatus();
  const openStudentCenterModal = useAppStore(
    (state) => state.openStudentCenterModal,
  );

  const allQueriesFetched =
    afterDeadlineFetched &&
    recentlyPaidFetched &&
    incompleteDataFetched &&
    nearDeadlineFetched;

  const activeList =
    filter === "afterDeadline"
      ? afterDeadline
      : filter === "recentlyPaid"
      ? recentlyPaid
      : filter === "incompleteData"
      ? incompleteData
      : nearDeadline;

  useEffect(() => {
    if (filter && activeList.length > 0) {
      return;
    }

    if (!allQueriesFetched) return;

    if (afterDeadline.length > 0) {
      setFilter("afterDeadline");
      return;
    }
    if (incompleteData.length > 0) {
      setFilter("incompleteData");
      return;
    }
    if (nearDeadline.length > 0) {
      setFilter("nearDeadline");
      return;
    }
    if (recentlyPaid.length > 0) {
      setFilter("recentlyPaid");
      return;
    }
  }, [
    afterDeadline,
    recentlyPaid,
    incompleteData,
    nearDeadline,
    allQueriesFetched,
    filter,
    activeList.length,
  ]);

  return (
    <div>
      <section className={cs.filters}>
        <RadioButton
          label={`Po terminie płatności (${getSublistSize(afterDeadline)})`}
          name="finances_tab"
          checked={filter === "afterDeadline"}
          value={"afterDeadline"}
          disabled={afterDeadline.length === 0}
          onChange={setFilter}
        />
        <RadioButton
          label={`Bez ceny lub terminu płatności (${getSublistSize(
            incompleteData,
          )})`}
          name="finances_tab"
          checked={filter === "incompleteData"}
          value={"incompleteData"}
          disabled={incompleteData.length === 0}
          onChange={setFilter}
        />
        <RadioButton
          label={`Zbliżający się termin płatności (${getSublistSize(
            nearDeadline,
          )})`}
          name="finances_tab"
          checked={filter === "nearDeadline"}
          value={"nearDeadline"}
          disabled={nearDeadline.length === 0}
          onChange={setFilter}
        />
        <RadioButton
          label={`Niedawno opłacone (${getSublistSize(recentlyPaid)})`}
          name="finances_tab"
          checked={filter === "recentlyPaid"}
          value={"recentlyPaid"}
          disabled={recentlyPaid.length === 0}
          onChange={setFilter}
        />
      </section>

      <section className={cs.list}>
        {activeList.map((arrear) => (
          <ListItem
            key={arrear._id}
            arrear={arrear}
            onParticipantClicked={() => handleParticipantClick(arrear)}
            onStatusChange={handleStatusChanged}
          />
        ))}
      </section>
    </div>
  );

  async function handleStatusChanged(
    arrearId: string,
    status: LessonPaymentStatus,
  ) {
    await changeStatus.mutateAsync({ arrearId, status });

    await queryClient.invalidateQueries({
      queryKey: ["standalone-arrears"],
    });
  }

  function handleParticipantClick(arrear: StandaloneArrearType) {
    openStudentCenterModal(arrear.lesson.participant._id);
  }

  function getSublistSize(sublist: any[]) {
    const rows = sublist.length;

    if (rows > PAGINATION.pageSize) return PAGINATION.pageSize + "+";

    return rows;
  }
};
