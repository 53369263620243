import { Modal } from "@components/Modal";
import { ApplicationType } from "@customTypes/ApplicationType";
import { TimeSlotType } from "@customTypes/TimeSlotType";
import { useDeleteTimeSlot } from "@hooks/useDeleteTimeSlot";
import trashImg from "@img/icon_trash.svg";
import { useAppStore } from "@store";
import { formatDate } from "@utils/date.utils";

import { Button } from "../Button";

import { Application } from "./Application";

import cs from "./TimeSlotDetailsModal.module.scss";

type TimeSlotDetailsModalProps = {
  timeSlot: TimeSlotType;
  isOpen: boolean;
  onClose: () => void;
};

export const TimeSlotDetailsModal = ({
  timeSlot,
  isOpen,
  onClose,
}: TimeSlotDetailsModalProps) => {
  const deleteTimeSlot = useDeleteTimeSlot();

  const openConfirmationModal = useAppStore(
    (state) => state.openConfirmationModal,
  );
  const openUpsertLessonModal = useAppStore(
    (state) => state.openUpsertLessonModal,
  );
  const availableParticipants = useAppStore((state) => state.participants);

  const footer = (
    <div>
      <Button
        color="danger"
        isLoading={deleteTimeSlot.isLoading}
        onClick={handleDelete}
      >
        <img src={trashImg} alt="" width={16} />
        <span>Usuń</span>
      </Button>
    </div>
  );

  return (
    <Modal
      title="Dostępność"
      isOpen={isOpen}
      footer={footer}
      helpTooltip="Czym jest Dostępność?"
      help={`Utwórz Dostępność, aby poinformować swoich uczniów o wolnym czasie w Twoim kalendarzu. Zaznacz "Wyślij powiadomienie o dostępności", a każdy z Twoich uczniów otrzyma notyfikację, że Dostępność została utworzona w Twoim kalendarzu. Uczniowie mogą odpowiadać na Dostępność, proponując lekcje. Uwaga: funkcja dostępna tylko dla uczniów, którzy posiadają konto w aplikacji.`}
      onClose={onClose}
    >
      <div className={cs.dates}>
        <div className={cs.dates_label}>Data:</div>
        <div>{formatDate(timeSlot.date)}</div>

        <div className={cs.dates_label}>Czas:</div>
        <div>
          {timeSlot.startHourStr}-{timeSlot.endHourStr}
        </div>
      </div>

      <div className={cs.applications}>
        <div className={cs.applications_header}>
          Zgloszenia ({timeSlot.applications.length})
        </div>

        {timeSlot.applications.length > 0 ? (
          <div className={cs.applications_list}>
            {timeSlot.applications.map((application) => (
              <Application
                key={application._id}
                application={application}
                onSelect={handleCreateLessonFromApplication}
              />
            ))}
          </div>
        ) : (
          <div className={cs.noApplicationsInfo}>Brak zgłoszeń.</div>
        )}
      </div>
    </Modal>
  );

  async function handleDelete() {
    const isConfirmed = await openConfirmationModal({
      confirmText: "Usuń",
      text: `Czy na pewno chcesz usunąć ten slot czasowy?`,
    });

    if (isConfirmed) {
      await deleteTimeSlot.mutateAsync({ timeSlotId: timeSlot._id });

      onClose();
    }
  }

  function handleCreateLessonFromApplication(application: ApplicationType) {
    openUpsertLessonModal(
      {
        date: application.date,
        duration: application.duration,
        participant: availableParticipants.find(
          (participant) =>
            participant._id === application.student.participantId,
        ),
      },
      () => {
        deleteTimeSlot.mutateAsync({ timeSlotId: timeSlot._id });
      },
    );

    onClose();
  }
};
