import { useRef, useState } from "react";

import { DatePicker } from "@components/DatePicker";
import { LessonType } from "@customTypes/LessonType";
import { ReactComponent as PencilImg } from "@img/pencil2.svg";
import { ReactComponent as RemoveImg } from "@img/remove_light.svg";
import { formatShortDate } from "@utils/date.utils";

import { Node } from "./Node";

import cs from "./StudyProgress.module.scss";

export const Lesson = ({
  lesson,
  isNext,
  onEdit,
  onDelete,
  onDateSet,
}: {
  lesson: LessonType;
  isNext: boolean;
  onEdit: (lesson: LessonType) => void;
  onDelete: (lesson: LessonType) => void;
  onDateSet: (lesson: LessonType, newDate: Date) => void;
}) => {
  const [date, setDate] = useState<Date | null>(null);

  const datePickerEl = useRef<HTMLInputElement | null>(null);

  return (
    <div className={cs.row}>
      {lesson.date ? (
        <div className={cs.time}>
          <p>{formatShortDate(lesson.date)} (wt)</p>
          <p>
            {lesson.startHourStr}-{lesson.endHourStr}
          </p>
        </div>
      ) : (
        <div className={cs.assignDateText}>
          <span
            onClick={() => {
              datePickerEl.current?.click();
            }}
          >
            Przypisz datę
          </span>
          <span className={cs.datePicker}>
            <DatePicker
              value={date}
              mode="datetime"
              ref={datePickerEl}
              onChange={setDate}
              onCalendarClose={() => {
                if (date) {
                  onDateSet(lesson, date);
                }
              }}
            />
          </span>
        </div>
      )}

      <Node type={getNodeType(lesson, isNext)} />

      <div className={cs.lessonContainer}>
        <div className={cs.lesson}>
          {lesson.topic ? (
            <span className={cs.lesson_topic}>{lesson.topic}</span>
          ) : (
            <span className={cs.lesson_noTopic}>Brak tematu</span>
          )}
        </div>

        <div className={cs.lessonActions}>
          <button
            className={cs.action}
            title="Edytuj lekcję"
            onClick={() => onEdit(lesson)}
          >
            <PencilImg width={12} />
          </button>

          <button
            className={cs.action}
            title={`${lesson.isPast ? "Usuń" : "Odwołaj"} lekcję`}
            onClick={() => onDelete(lesson)}
          >
            <RemoveImg width={10} />
          </button>
        </div>
      </div>
    </div>
  );
};

function getNodeType(
  lesson: LessonType,
  isNext: boolean,
): "current" | "with_date" | "no_date" | undefined {
  if (lesson.isPast) {
    return;
  }

  if (isNext) {
    return "current";
  }

  if (lesson.date) {
    return "with_date";
  }

  return "no_date";
}
