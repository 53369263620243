import { LessonDtoType } from "@customTypes/LessonDtoType";
import { LessonType } from "@customTypes/LessonType";
import { ParticipantDtoService } from "@services/ParticipantDtoService";
import { addMinutes, formatHour } from "@utils/date.utils";

import { ArrearDtoService } from "./ArrearDtoService";

const NOW = new Date();

export class LessonDtoService {
  static transformAll(lessonDtos: LessonDtoType[]) {
    return lessonDtos.map(LessonDtoService.transform);
  }

  static transform(lessonDto: LessonDtoType): LessonType {
    lessonDto = LessonDtoService.formatDates(lessonDto);

    const endDate = lessonDto.date
      ? addMinutes(lessonDto.date, lessonDto.duration)
      : null;

    const arrears =
      lessonDto.arrears?.map((arrearDto) =>
        ArrearDtoService.transform(arrearDto, lessonDto),
      ) || [];

    return {
      ...lessonDto,
      endDate: endDate,
      isPast: endDate ? endDate < NOW : false,
      startHourStr: lessonDto.date ? formatHour(lessonDto.date) : "",
      endHourStr: endDate ? formatHour(endDate) : "",
      participant: ParticipantDtoService.transform(lessonDto.participant),
      arrears,
    };
  }

  private static formatDates(lessonDto: LessonDtoType): LessonDtoType {
    const copy = { ...lessonDto };

    if (copy.paymentDeadline) {
      copy.paymentDeadline = new Date(lessonDto.paymentDeadline);
    }
    if (lessonDto.date) {
      copy.date = new Date(lessonDto.date);
    }

    return copy;
  }
}
