import { useQuery } from "react-query";

import { TemplatesApi } from "../api/TemplatesApi";

export const useTemplates = (sort: string[] = []) => {
  return useQuery(["templates", sort], () => TemplatesApi.fetchAll(sort), {
    keepPreviousData: true,
    cacheTime: Infinity,
    staleTime: Infinity,
  });
};
