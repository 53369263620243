import axios from "axios";

export function handleApiError(error: any) {
  if (axios.isAxiosError(error)) {
    error = error.response?.data?.error?.message;
  }

  console.error(error);

  return {
    isSuccess: false,
    error: error,
  };
}
