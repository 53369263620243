import { useEffect, useState } from "react";

import { useParticipants } from "../../hooks/useParticipants";
import { ParticipantType } from "../../types/ParticipantType";
import { RadioButton } from "../RadioButton";

import cs from "./SelectParticipantPopover.module.scss";

type SelectParticipantPopoverContentProps = {
  participant: ParticipantType | null;
  onSelect: (selectedStudent: ParticipantType) => void;
};

export const SelectParticipantPopoverContent = ({
  participant,
  onSelect,
}: SelectParticipantPopoverContentProps) => {
  const [sorted, setSorted] = useState<ParticipantType[]>([]);

  const { students } = useParticipants();

  useEffect(() => {
    const sorted = [...students].sort((student1, student2) =>
      student1.details.name > student2.details.name ? 1 : -1,
    );

    setSorted(sorted);
  }, [students]);

  return (
    <div>
      {sorted.length === 0 && (
        <div className={cs.noStudentsInfo}>
          Dodaj pierwszego ucznia, aby móc przypisać zadanie.
        </div>
      )}

      {sorted.map((_participant) => (
        <div key={_participant._id} className={cs.option}>
          <RadioButton
            label={_participant.details.name}
            value={_participant}
            checked={_participant._id === participant?._id}
            onChange={onSelect}
          />
        </div>
      ))}
    </div>
  );
};
