import { useEffect, useRef, useState } from "react";

import { updatedDiff } from "deep-object-diff";

import { AddStudentModal } from "@components/AddStudentModal";
import { AssignedMaterialsManager } from "@components/AssignedMaterialsManager";
import { Button } from "@components/Button";
import { Checkbox } from "@components/Checkbox";
import { DatePicker } from "@components/DatePicker";
import { Dropdown } from "@components/Dropdown";
import { Input } from "@components/Input";
import { Modal } from "@components/Modal";
import { RichTextEditor } from "@components/RichTextEditor";
import { Textarea } from "@components/Textarea";
import { AssignedMaterialType } from "@customTypes/AssignedMaterialType";
import { LessonType } from "@customTypes/LessonType";
import { ParticipantType } from "@customTypes/ParticipantType";
import { StudentType } from "@customTypes/StudentType";
import { TemplateType } from "@customTypes/TemplateType";
import { useAddLesson } from "@hooks/useAddLesson";
import { useCurrentUser } from "@hooks/useCurrentUser";
import { useParticipants } from "@hooks/useParticipants";
import { useTemplates } from "@hooks/useTemplates";
import { useUpdateLesson } from "@hooks/useUpdateLesson";
import { ArrearService } from "@services/ArrearService";
import { ToastService } from "@services/ToastService";
import { useAppStore } from "@store";

import { ReactComponent as SettingsImg } from "../../img/settings_new.svg";
import { Search } from "../Search";

import { Duration } from "./Duration";
import { Prices } from "./Prices";

import cs from "./UpsertLessonModal.module.scss";

type UpsertLessonModalProps = {
  isOpen: boolean;
  lesson?: Partial<LessonType> | null;
  onAfter?: () => void;
  onClose: () => void;
};

export const UpsertLessonModal = ({
  isOpen,
  lesson,
  onAfter,
  onClose,
}: UpsertLessonModalProps) => {
  const [topic, setTopic] = useState("");
  const [student, setStudent] = useState<ParticipantType | null>(null);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [desc, setDesc] = useState("");
  const [materials, setMaterials] = useState<AssignedMaterialType[]>([]);
  const [duration, setDuration] = useState<number | null>(null);
  const [price, setPrice] = useState<number | null>(null);
  const [deadline, setDeadline] = useState<Date | null>(null);
  const [privateNote, setPrivateNote] = useState("");
  const [isNewStudentModalOpen, setIsNewStudentModalOpen] = useState(false);
  const [isFixed, setIsFixed] = useState(false);
  const [noOfWeeksToRepeat, setNoOfWeeksToRepeat] = useState(4);

  const formElement = useRef<HTMLFormElement>(null);
  const studentSearchElement = useRef<any>(null);

  const { students = [] } = useParticipants();
  const { data: templates = [] } = useTemplates();

  const addLesson = useAddLesson();
  const updateLesson = useUpdateLesson();
  const openStudentCenterModal = useAppStore(
    (state) => state.openStudentCenterModal,
  );

  const { currentUser } = useCurrentUser();

  const mode = lesson?._id ? "edit" : "new";

  useEffect(() => {
    if (isOpen && lesson) {
      lesson.topic && setTopic(lesson.topic);
      lesson.participant && setStudent(lesson.participant);
      lesson.date && setStartDate(lesson.date);
      lesson.desc && setDesc(lesson.desc);
      lesson.materials && setMaterials(lesson.materials);
      lesson.duration && setDuration(lesson.duration);
      lesson.price && setPrice(lesson.price);
      lesson.paymentDeadline && setDeadline(lesson.paymentDeadline);
      lesson.privateNote && setPrivateNote(lesson.privateNote);
    }
  }, [lesson, isOpen]);

  useEffect(() => {
    if (mode !== "new") return;

    if (isOpen && currentUser) {
      const defaultDuration = currentUser.durations.find(
        ({ isDefault }) => isDefault,
      );

      if (defaultDuration) {
        !lesson?.duration && setDuration(defaultDuration.value);
      }
    }
  }, [isOpen, lesson, currentUser, mode]);

  useEffect(() => {
    if (!isOpen || mode !== "new" || !duration || !currentUser) return;

    const durationDetails = currentUser.durations.find(
      ({ value }) => value === duration,
    );

    if (durationDetails?.price) {
      setPrice(durationDetails.price);
    }
  }, [isOpen, duration, currentUser, mode]);

  useEffect(() => {
    if (!isOpen || !student || mode !== "new" || !startDate) return;

    setDeadline(
      ArrearService.calcPaymentDeadline(
        student.details.paymentLogic,
        startDate,
      ),
    );
  }, [isOpen, student, mode, startDate]);

  let minTime;
  let maxTime;

  if (currentUser?.settingWorkStartHour) {
    minTime = new Date(
      new Date().setHours(currentUser.settingWorkStartHour, 0, 0, 0),
    );
  }
  if (currentUser?.settingWorkEndHour) {
    maxTime = new Date(
      new Date().setHours(currentUser.settingWorkEndHour, 0, 0, 0),
    );
  }

  return (
    <Modal
      title={mode === "new" ? "Dodaj lekcję" : "Edytuj lekcję"}
      isOpen={isOpen}
      className={cs.modal}
      footer={
        <Button
          color="primary"
          isLoading={addLesson.isLoading || updateLesson.isLoading}
          onClick={onConfirm}
        >
          <span>{mode === "new" ? "Dodaj lekcję" : "Zapisz"}</span>
        </Button>
      }
      clickOutsideToClose={false}
      onClose={handleClose}
    >
      <form ref={formElement}>
        <section className={cs.section}>
          <div className={cs.studentAndDate}>
            {students.length > 0 ? (
              <Search
                value={student?.details || null}
                items={students.map(({ details }) => details)}
                selected={[]}
                label="Uczeń"
                placeholder=""
                disabled={mode === "edit"}
                required
                ref={studentSearchElement}
                onChange={(selectedStudent: StudentType) => {
                  studentSearchElement.current.setCustomValidity("");

                  setStudent(
                    students.find(
                      ({ details }) => details._id === selectedStudent._id,
                    ) || null,
                  );
                }}
              />
            ) : (
              <div>
                <div className="label">Uczeń</div>

                <button
                  className={cs.addFirstStudentBtn}
                  type="button"
                  onClick={() => setIsNewStudentModalOpen(true)}
                >
                  + Dodaj pierwszego studenta
                </button>

                <input
                  className={cs.hiddenStudentValidator}
                  ref={studentSearchElement}
                />
              </div>
            )}

            <DatePicker
              value={startDate}
              label="Data i godzina"
              mode="datetime"
              minTime={minTime}
              maxTime={maxTime}
              onChange={setStartDate}
            />
          </div>

          <Search
            inputValue={topic}
            items={templates}
            labelField="topic"
            selected={[]}
            label="Temat"
            placeholder=""
            onChange={(template: TemplateType) => {
              setTopic(template.topic);
              template.noteForStudent && setDesc(template.noteForStudent);

              if (template.materials?.length > 0) {
                template.materials && setMaterials(template.materials);
              }
            }}
            onInputChange={setTopic}
          />
        </section>

        <div className={cs.split}>
          <section className={cs.section}>
            {mode === "new" && (
              <div className="mb8">
                <div>
                  <Checkbox
                    checked={isFixed}
                    label="Lekcja o stałej porze"
                    className={cs.isFixed_checkbox}
                    onChange={(value) => {
                      setIsFixed(value);
                      setNoOfWeeksToRepeat(4);
                    }}
                  />
                </div>

                {isFixed && (
                  <div className={cs.isFixed_weeks}>
                    <span>Powtarzaj przez </span>
                    <Dropdown
                      className={cs.isFixed_dropdown}
                      options={[
                        <div onClick={() => setNoOfWeeksToRepeat(2)}>2</div>,
                        <div onClick={() => setNoOfWeeksToRepeat(3)}>3</div>,
                        <div onClick={() => setNoOfWeeksToRepeat(4)}>4</div>,
                        <div onClick={() => setNoOfWeeksToRepeat(8)}>8</div>,
                      ]}
                    >
                      <Input
                        value={String(noOfWeeksToRepeat)}
                        className={cs.isFixed_input}
                        onChange={() => {}}
                      />
                    </Dropdown>

                    <span>
                      {noOfWeeksToRepeat < 5 ? "tygodnie" : "tygodni"}
                    </span>
                  </div>
                )}
              </div>
            )}

            <Textarea
              label="Twoja prywatna notatka"
              value={privateNote}
              onChange={setPrivateNote}
            />
          </section>

          <section className={cs.section}>
            <Duration selectedDuration={duration} onChange={setDuration} />

            <Prices currentPrice={price} onChange={setPrice} />

            <div>
              <div className={`${cs.label} mb4`}>
                <span>Termin płatności</span>

                {student && (
                  <button
                    className="btn-icon"
                    type="button"
                    onClick={() => {
                      openStudentCenterModal(student?._id, "settings");
                    }}
                  >
                    <SettingsImg width={14} />
                  </button>
                )}
              </div>

              <DatePicker value={deadline} onChange={setDeadline} />
            </div>
          </section>
        </div>
        <section className={cs.section}>
          <RichTextEditor value={desc} label="Opis lekcji" onChange={setDesc} />
        </section>
        <section className={cs.plansSection}>
          <AssignedMaterialsManager
            materials={materials}
            onChange={setMaterials}
          />
        </section>
      </form>

      <AddStudentModal
        isOpen={isNewStudentModalOpen}
        onClose={() => setIsNewStudentModalOpen(false)}
      />
    </Modal>
  );

  async function onConfirm() {
    const isValid = formElement.current?.reportValidity();

    if (!isValid) return;

    if (!student) {
      studentSearchElement.current.setCustomValidity("Wybierz ucznia.");
      studentSearchElement.current.reportValidity();
      return;
    }

    if (mode === "new") {
      await addLesson.mutateAsync({
        lesson: createLessonObject(),
        repeat: isFixed ? noOfWeeksToRepeat : undefined,
      });

      ToastService.success("Lekcja została utworzona.");
    } else if (lesson?._id) {
      const currentForm = createLessonObject();

      const updatedFields = updatedDiff(
        lesson,
        currentForm,
      ) as Partial<LessonType>;

      updatedFields.materials = materials;

      await updateLesson.mutateAsync({
        lessonId: lesson._id,
        updatedFields,
      });

      ToastService.success("Zmiany zostały zapisane.");
    }

    onAfter?.();

    handleClose();
  }

  function clear() {
    setTopic("");
    setStudent(null);
    setStartDate(null);
    setDesc("");
    setMaterials([]);
    setDuration(null);
    setPrice(null);
    setDeadline(null);
    setPrivateNote("");
    setIsFixed(false);
    setNoOfWeeksToRepeat(4);
  }

  function handleClose() {
    onClose();
    clear();
  }

  function createLessonObject() {
    return {
      topic,
      date: startDate as Date,
      participant: student as ParticipantType,
      price,
      duration: duration as number,
      materials,
      privateNote,
      desc,
      paymentDeadline: deadline as Date,
      incomplete: false,
    };
  }
};
