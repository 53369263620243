import { ReactNode } from "react";
import { toast, ToastPosition } from "react-toastify";

export class ToastService {
  static success(
    text: ReactNode,
    options?: { duration?: number; position?: ToastPosition; toastId?: string },
  ) {
    toast.success(text, {
      position: options?.position || "bottom-left",
      autoClose: options?.duration || 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "light",
      toastId: options?.toastId,
    });
  }

  static error(
    text: ReactNode,
    options?: { duration?: number; position?: ToastPosition },
  ) {
    toast.error(text, {
      position: options?.position || "bottom-left",
      autoClose: options?.duration || 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "light",
    });
  }

  static generalError() {
    ToastService.error(
      "Wystąpił błąd. Zostaliśmy o nim poinformowani. Prosimy spróbować ponownie później.",
      { duration: 5000 },
    );
  }
}
