import { useMutation, useQueryClient } from "react-query";

import { NotificationApi } from "../api/NotificationApi";
import { deleteById } from "../utils/utils";

export const useDeleteNotification = () => {
  const queryClient = useQueryClient();

  return useMutation(NotificationApi.deleteById, {
    onMutate: async (notificationId) => {
      await queryClient.cancelQueries("notifications");

      const currentCache = queryClient.getQueriesData("notifications");

      queryClient.setQueriesData(["notifications"], (previous: any = {}) => ({
        ...previous,
        data: deleteById(notificationId, previous.data),
      }));

      return { currentCache };
    },
    onError: (err, newTodo, context) => {
      queryClient.setQueriesData("notifications", context?.currentCache);
    },
  });
};
