import { useEffect, useRef, useState } from "react";

import { Button } from "@components/Button";
import { Checkbox } from "@components/Checkbox";
import { InfoBox } from "@components/InfoBox";
import { Input } from "@components/Input";
import { Modal } from "@components/Modal";
import { Textarea } from "@components/Textarea";
import { StudentType } from "@customTypes/StudentType";
import { useAddStudent } from "@hooks/useAddStudent";
import dropdownIcon from "@img/dropdown_arrow.svg";

import cs from "./AddStudentModal.module.scss";

type AddStudentModalProps = {
  isOpen: boolean;
  student?: StudentType | null;
  onClose: () => void;
};

export const AddStudentModal = ({
  isOpen,
  student,
  onClose,
}: AddStudentModalProps) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [sendInvitation, setSendInvitation] = useState(true);
  const [phone, setPhone] = useState("");
  const [note, setNote] = useState("");
  const [additionalFieldsVisible, setAdditionalFieldsVisible] = useState(false);

  const formElement = useRef<HTMLFormElement>(null);
  const emailElement = useRef<HTMLInputElement>(null);

  const addStudent = useAddStudent();

  useEffect(() => {
    if (isOpen && student) {
      setFirstName(student.firstName || "");
      setLastName(student.lastName || "");
      setEmail(student.email || "");
      setPhone(student.phone || "");
      setNote(student.note || "");

      setAdditionalFieldsVisible(true);
    }
  }, [student, isOpen]);

  const wasValidEmailProvided = Boolean(
    email && emailElement.current?.checkValidity(),
  );

  return (
    <Modal
      title="Dodaj ucznia"
      isOpen={isOpen}
      className={cs.modal}
      footer={
        <Button
          color="primary"
          isLoading={addStudent.isLoading}
          onClick={handleConfirm}
        >
          Dodaj
        </Button>
      }
      onClose={onClose}
    >
      <form
        ref={formElement}
        className={cs.form}
        onKeyDown={(e) => e.key === "Enter" && handleConfirm()}
      >
        <Input
          label="Imię"
          value={firstName}
          required
          name="first_name"
          onChange={setFirstName}
        />

        <Input
          label="Nazwisko"
          value={lastName}
          required
          name="last_name"
          onChange={setLastName}
        />

        <Input
          label="E-mail"
          value={email}
          type="email"
          ref={emailElement}
          onChange={setEmail}
        />

        <div className={cs.invitationRow}>
          <Checkbox
            checked={wasValidEmailProvided && sendInvitation}
            label="Wyślij zaproszenie do aplikacji"
            disabled={!wasValidEmailProvided}
            onChange={setSendInvitation}
          />

          <InfoBox
            desc={`Każdy z Twoich uczniów może, choć nie musi, posiadać darmowe konto w aplikacji. Konto ucznia umożliwia mu dostęp do swojego kalendarza lekcji, otrzymywanie i dostarczanie zadań domowych, dostęp do historii spotkań oraz notatek, a także umożliwia proponowanie lekcji, jeśli korepetytor utworzy Dostępność w swoim kalendarzu. Zaznacz opcję "Wyślij zaproszenie do aplikacji", a utworzymy dla Twojego ucznia darmowe konto - na jego adres e-mail zostanie wysłany login i hasło, dzięki któremu uzyska dostęp do aplikacji.`}
          />
        </div>

        {!additionalFieldsVisible && (
          <div
            className={cs.showMore}
            onClick={() => setAdditionalFieldsVisible(true)}
          >
            <img src={dropdownIcon} alt="" width={8} />

            <span>Pokaż więcej opcji</span>
          </div>
        )}

        {additionalFieldsVisible && (
          <>
            <Input
              label="Telefon"
              value={phone}
              name="phone"
              onChange={setPhone}
            />

            <Textarea label="Notatka" value={note} onChange={setNote} />
          </>
        )}
      </form>
    </Modal>
  );

  async function handleConfirm() {
    const isValid = formElement.current?.reportValidity();

    if (!isValid) return;

    await addStudent.mutateAsync({
      student: {
        firstName,
        lastName,
        email,
        phone,
        note,
      },
      sendInvitation: Boolean(wasValidEmailProvided) && sendInvitation,
    });

    onClose();
    clear();
  }

  function clear() {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setNote("");
  }
};
