import copy from "copy-to-clipboard";

import { LessonApi } from "@api/LessonApi";
import { ToastService } from "@services/ToastService";

export class LessonService {
  static async share(lessonId: string) {
    await LessonApi.update({
      lessonId,
      updatedFields: {
        isShared: true,
      },
    });

    const studentLink = `${process.env.REACT_APP_STUDENT_APP}/shared-lesson/${lessonId}`;

    copy(studentLink);

    ToastService.success(
      <div>
        <div className="semi-bold">Link został skopiowany do schowka.</div>

        <div className="mt10">
          Udostępnij go uczniowi lub rodzicom aby mieli dostęp do tej lekcji nie
          posiadając konta w aplikacji.
        </div>

        <div className="mt10 trim-text">
          <a
            href={studentLink}
            target="_blank"
            className="link"
            rel="noreferrer"
          >
            {studentLink.replace("https://", "")}
          </a>
        </div>
      </div>,
      { duration: 10000 },
    );
  }
}
