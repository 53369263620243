import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";

import { enableMapSet } from "immer";

import "./wdyr"; // INFO: Must be the first import

import App from "./App";

import "intro.js/introjs.css";
import "react-toastify/dist/ReactToastify.css";
import "./index.scss";

enableMapSet();

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.register("sw.js");
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <QueryClientProvider client={queryClient}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </QueryClientProvider>,
);
