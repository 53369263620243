import { ParticipantType } from "@customTypes/ParticipantType";
import { ReactComponent as NewLessonImg } from "@img/new_lesson.svg";
import { useAppStore } from "@store";

import cs from "./StudyProgress.module.scss";

type NodeNewLessonProps = {
  participant: ParticipantType;
};

export const NodeNewLesson = ({ participant }: NodeNewLessonProps) => {
  const openUpsertLessonModal = useAppStore(
    (state) => state.openUpsertLessonModal,
  );

  return (
    <div className={cs.row}>
      <div />

      <div className={cs.nodeShowMore} onClick={createLesson}>
        <NewLessonImg width={18} />
      </div>

      <div className={cs.actionText} onClick={createLesson}>
        Dodaj lekcję
      </div>
    </div>
  );

  function createLesson() {
    openUpsertLessonModal({ participant });
  }
};
