import { ReactQueryDevtools } from "react-query/devtools";
import { ToastContainer } from "react-toastify";

import { ConfirmationModal } from "@components/ConfirmationModal";
import { Spinner } from "@components/Spinner";
import { StudentCenterModal } from "@components/StudentCenterModal";
import { UpsertLessonModal } from "@components/UpsertLessonModal";
import { useCurrentUser } from "@hooks/useCurrentUser";
import { useSmartlook } from "@hooks/useSmartlook";
import { useTidioChat } from "@hooks/useTidioChat";
import * as Sentry from "@sentry/react";
import { useAppStore } from "@store";

import { ApplicationRoutes } from "./router/ApplicationRoutes";

initSentry();

const App = () => {
  const upsertLessonModal = useAppStore((state) => state.upsertLessonModal);
  const closeUpsertLessonModal = useAppStore(
    (state) => state.closeUpsertLessonModal,
  );
  const studentCenterModal = useAppStore((state) => state.studentCenterModal);
  const closeStudentCenterModal = useAppStore(
    (state) => state.closeStudentCenterModal,
  );
  const confirmationModal = useAppStore((state) => state.confirmationModal);

  const { isLoading, currentUser } = useCurrentUser();

  useTidioChat(currentUser);
  useSmartlook(currentUser);

  if (isLoading) {
    return <Spinner loading className="fullViewSpinner" size={54} />;
  }

  return (
    <div className="App">
      <ApplicationRoutes />

      <ToastContainer />

      {currentUser && (
        <>
          <UpsertLessonModal
            isOpen={upsertLessonModal.isOpen}
            lesson={upsertLessonModal.lesson}
            onAfter={upsertLessonModal.onAfter}
            onClose={closeUpsertLessonModal}
          />

          <StudentCenterModal
            isOpen={studentCenterModal.isOpen}
            participantId={studentCenterModal.participantId!}
            tab={studentCenterModal.tab}
            onClose={closeStudentCenterModal}
          />

          <ConfirmationModal
            isOpen={confirmationModal.isOpen}
            text={confirmationModal.text}
            confirmText={confirmationModal.confirmText}
            onCancel={confirmationModal.onCancel!}
            onConfirm={confirmationModal.onConfirm!}
          />

          {process.env.REACT_APP_ENV === "local" && (
            <ReactQueryDevtools initialIsOpen={false} />
          )}
        </>
      )}
    </div>
  );
};

export default App;

function initSentry() {
  Sentry.init({
    dsn: "https://9472842360244047af6dca62d013969c@o4504570743160833.ingest.sentry.io/4504570748141568",
    environment: process.env.REACT_APP_ENV,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
    integrations:
      process.env.REACT_APP_ENV === "local" ? [] : [new Sentry.Replay()],
    beforeSend(event) {
      if (event.environment === "local") return null;

      return event;
    },
    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.category === "ui.click") {
        const { target } = hint?.event;

        if (target.ariaLabel) {
          breadcrumb.message = target.ariaLabel;
        } else {
          breadcrumb.message += ` (${target.innerText})`;
        }
      } else if (
        breadcrumb.category === "fetch" &&
        breadcrumb?.data?.url?.match(/^data:/)
      ) {
        breadcrumb.data.url = "b64_file";
      }

      return breadcrumb;
    },
    ignoreErrors: ["Non-Error promise rejection captured"],
  });
}
