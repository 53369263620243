import { createAxiosInstance } from "@api/_createAxiosInstance";
import { PaymentLogicType } from "@customTypes/PaymentLogicType";

const api = createAxiosInstance("/api/account");

type UpdatePaymentLogicParams = {
  paymentLogic: PaymentLogicType;
};

export class AccountApi {
  static async deleteAccount() {
    return api.delete(`/`);
  }

  static async updatePaymentLogic({ paymentLogic }: UpdatePaymentLogicParams) {
    return api.put(`/payment-logic`, paymentLogic);
  }
}
