import { StudentCenterTabType } from "@components/StudentCenterModal/Tabs";

import { ReactComponent as NewLessonIcon } from "../../img/new_lesson.svg";
import { ReactComponent as StudentCenterIcon } from "../../img/open_student_center.svg";
import { ParticipantType } from "../../types/ParticipantType";
import { StudentType } from "../../types/StudentType";

import cs from "./StudentPopover.module.scss";

export const StudentPopoverContent = ({
  participant,
  onNewLesson,
  onOpenStudentCenter,
}: {
  participant: ParticipantType;
  onNewLesson: () => void;
  onOpenStudentCenter: (tab?: StudentCenterTabType) => void;
}) => {
  const student = participant.details as StudentType;

  return (
    <div className={cs.contentRoot}>
      <div className={cs.header}>{student.name}</div>

      <div className={cs.contactInfo}>
        <span>Email:</span>
        {student.mainContact?.email ? (
          <a
            href={`mailto:${student.mainContact?.email}`}
            className={cs.email}
            title={student.mainContact?.email}
          >
            {student.mainContact?.email}
          </a>
        ) : (
          <span className="medium">-</span>
        )}

        <span>Telefon:</span>

        {student.mainContact?.phone ? (
          <a href={`tel:${student.mainContact?.phone}`} className="link">
            {student.mainContact?.phone}
          </a>
        ) : (
          <span className="medium">-</span>
        )}
      </div>

      {student.contact.length > 1 && (
        <div className="text-center" style={{ marginTop: -2 }}>
          <button
            className={cs.showMoreBtn}
            onClick={() => onOpenStudentCenter("contact")}
          >
            Pokaż wszystkie kontakty
          </button>
        </div>
      )}

      <div className={cs.footer}>
        <button
          className="btn-icon"
          title="Otwórz centrum ucznia"
          onClick={() => onOpenStudentCenter()}
        >
          <StudentCenterIcon height={17} />
        </button>
        <button
          className="btn-icon"
          title="Dodaj nową lekcję z tym uczniem"
          onClick={onNewLesson}
        >
          <NewLessonIcon height={17} />
        </button>
      </div>
    </div>
  );
};
