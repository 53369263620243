import cs from "./StudyProgress.module.scss";

export const NodeConnection = ({ color = "#909498", length = 60 }) => (
  <div className={cs.row}>
    <div />
    <div className="f-center">
      <div
        className={cs.nodeConnection}
        style={{
          backgroundColor: color,
          height: length,
        }}
      />
    </div>
    <div />
  </div>
);
