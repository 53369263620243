import React from "react";

import cs from "./Box.module.scss";

type BoxProps = {
  children: React.ReactNode;
};

export const Box = ({ children }: BoxProps) => {
  return <div className={cs.root}>{children}</div>;
};
