import { useEffect, useRef, useState } from "react";

import { Button } from "@components/Button";
import { Input } from "@components/Input";
import { Modal } from "@components/Modal";
import { Textarea } from "@components/Textarea";
import { StudentType } from "@customTypes/StudentType";
import { useReplaceStudent } from "@hooks/useReplaceStudent";

import cs from "./UpdateStudentModal.module.scss";

type UpdateStudentModalProps = {
  isOpen: boolean;
  student: StudentType | null;
  onClose: () => void;
};

export const UpdateStudentModal = ({
  isOpen,
  student,
  onClose,
}: UpdateStudentModalProps) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [note, setNote] = useState("");

  const formElement = useRef<HTMLFormElement>(null);

  const replaceStudent = useReplaceStudent();

  useEffect(() => {
    if (isOpen && student) {
      setFirstName(student.firstName || "");
      setLastName(student.lastName || "");
      setNote(student.note || "");
    }
  }, [student, isOpen]);

  return (
    <Modal
      title="Edytuj dane ucznia"
      isOpen={isOpen}
      className={cs.modal}
      footer={
        <Button
          color="primary"
          isLoading={replaceStudent.isLoading}
          onClick={handleConfirm}
        >
          Zapisz
        </Button>
      }
      onClose={onClose}
    >
      <form
        ref={formElement}
        className={cs.form}
        onKeyDown={(e) => e.key === "Enter" && handleConfirm()}
      >
        <Input
          label="Imię"
          value={firstName}
          required
          name="first_name"
          onChange={setFirstName}
        />

        <Input
          label="Nazwisko"
          value={lastName}
          required
          name="last_name"
          onChange={setLastName}
        />

        <Textarea label="Notatka" value={note} onChange={setNote} />
      </form>
    </Modal>
  );

  async function handleConfirm() {
    const isValid = formElement.current?.reportValidity();

    if (!isValid) return;

    await replaceStudent.mutateAsync({
      student: {
        _id: student!._id,
        firstName,
        lastName,
        note,
      },
    });

    onClose();
    clear();
  }

  function clear() {
    setFirstName("");
    setLastName("");
    setNote("");
  }
};
