import { useRef, useState } from "react";

import { Button } from "../../components/Button";
import { Input } from "../../components/Input";
import { Modal } from "../../components/Modal";
import { useAddLink } from "../../hooks/useAddLink";

import cs from "./AddFolderModal.module.scss";

type AddLinkModalProps = {
  isOpen: boolean;
  parentId: string | null;
  onClose: () => void;
};

export const AddLinkModal = ({
  isOpen,
  parentId,
  onClose,
}: AddLinkModalProps) => {
  const [name, setName] = useState("");
  const [link, setLink] = useState("");

  const formElement = useRef<HTMLFormElement>(null);

  const addLink = useAddLink();

  return (
    <Modal
      title="Dodaj link"
      isOpen={isOpen}
      className={cs.modal}
      footer={
        <Button
          color="primary"
          isLoading={addLink.isLoading}
          onClick={handleConfirm}
        >
          Dodaj
        </Button>
      }
      onClose={onClose}
    >
      <form
        ref={formElement}
        className={cs.form}
        onKeyDown={async (e) => {
          if (e.code === "Enter") {
            e.preventDefault();

            await handleConfirm();
          }
        }}
      >
        <Input label="Tekst" value={name} required onChange={setName} />
        <Input label="Link" value={link} required onChange={setLink} />
      </form>
    </Modal>
  );

  async function handleConfirm() {
    const isValid = formElement.current?.reportValidity();

    if (!isValid) return;

    await addLink.mutateAsync({
      name,
      link,
      parentId,
    });

    onClose();
    clear();
  }

  function clear() {
    setName("");
    setLink("");
  }
};
