import { LessonDetails } from "@components/LessonDetails";
import { PaymentStatus } from "@components/PaymentStatus";
import { LessonPaymentStatus } from "@constants/LessonPaymentStatus";
import { ReactComponent as OpenImg } from "@img/open.svg";
import { LessonDate } from "@pages/LessonsListPage/columns";

import { PaymentAction } from "./PaymentAction";
import { PaymentDeadline } from "./PaymentDeadline";

import cs from "./TabFinances.module.scss";

export function ListItem({
  arrear,
  onStatusChange,
}: {
  arrear: any;
  onStatusChange: (
    arrearId: string,
    status: LessonPaymentStatus,
  ) => Promise<void>;
}) {
  return (
    <>
      <div className={cs.listItem}>
        <LessonDetails lesson={arrear.lesson}>
          <span
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              gap: "12px",
            }}
            title="Otwórz lekcję"
          >
            <OpenImg width={12} style={{ flexShrink: 0 }} />

            <LessonDate lesson={arrear.lesson} />
          </span>
        </LessonDetails>

        <div>
          <div>
            <span>{arrear.lesson.price || "-"} zł</span>
          </div>

          <div style={{ fontSize: 14, marginTop: 2 }}>
            <PaymentDeadline arrear={arrear} />
          </div>
        </div>

        <PaymentStatus arrears={[arrear]} />

        <PaymentAction arrear={arrear} onStatusChange={onStatusChange} />
      </div>
      <div className={cs.listItemMobile}>
        <LessonDetails lesson={arrear.lesson}>
          <span
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              gap: "12px",
            }}
            title="Otwórz lekcję"
          >
            <OpenImg width={14} style={{ flexShrink: 0 }} />

            <div>
              <LessonDate lesson={arrear.lesson} />
            </div>
          </span>
        </LessonDetails>

        <div style={{ lineHeight: "21px", fontWeight: 500, fontSize: 13 }}>
          <div>{arrear.lesson.price || "-"} zł</div>

          <PaymentDeadline arrear={arrear} />

          <PaymentStatus arrears={[arrear]} />
        </div>

        <PaymentAction arrear={arrear} onStatusChange={onStatusChange} />
      </div>
    </>
  );
}
