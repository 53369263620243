import { useRef, useState } from "react";
import { useQueryClient } from "react-query";

import { MaterialApi } from "@api/MaterialApi";
import { Button } from "@components/Button";
import { MaterialDtoType } from "@customTypes/MaterialDtoType";
import { MaterialType } from "@customTypes/MaterialType";
import { useDeleteMaterials } from "@hooks/useDeleteMaterials";
import { ToastService } from "@services/ToastService";
import { useAppStore } from "@store";

import { ReactComponent as CopyImg } from "./img/copy.svg";
import { ReactComponent as EditImg } from "./img/edit.svg";
import { ReactComponent as FolderImg } from "./img/folder.svg";
import { ReactComponent as LinkImg } from "./img/link.svg";
import { ReactComponent as RemoveImg } from "./img/remove.svg";
import { AddFolderModal } from "./AddFolderModal";
import { AddLinkModal } from "./AddLinkModal";
import { ChangeNameModal } from "./ChangeNameModal";
import { EmptyList } from "./EmptyList";
import { Materials } from "./Materials";

import cs from "./MaterialsPage.module.scss";

export const MaterialsPage = () => {
  const [isAddNewFolderOpen, setIsAddNewFolderOpen] = useState(false);
  const [isAddLinkModalOpen, setIsAddLinkModalOpen] = useState(false);
  const [isChangeNameModalOpen, setIsChangeNameModalOpen] = useState(false);
  const [currentFolder, setCurrentFolder] = useState<MaterialType | null>(null);
  const [checked, setChecked] = useState<MaterialDtoType[]>([]);

  const deleteMaterials = useDeleteMaterials();

  const openConfirmationModal = useAppStore(
    (state) => state.openConfirmationModal,
  );

  return (
    <main className={cs.page}>
      <header className={cs.header}>
        <Button color="primary" onClick={() => setIsAddNewFolderOpen(true)}>
          <FolderImg width={14} />

          <span>Utwórz folder</span>
        </Button>

        <AddFileMaterialsBtn currentFolder={currentFolder} />

        <Button color="primary" onClick={() => setIsAddLinkModalOpen(true)}>
          <LinkImg width={14} />

          <span>Załącz link</span>
        </Button>
      </header>

      <section>
        <Materials
          currentFolder={currentFolder}
          setCurrentFolder={setCurrentFolder}
          checked={checked}
          footer={
            checked.length > 0 ? (
              <footer className={cs.footer}>
                {/* TODO: Later */}
                {/*<button className={cs.footer_action}>*/}
                {/*  <MoveImg width={14} />*/}
                {/*  <span>Przenieś</span>*/}
                {/*</button>*/}

                <button className={cs.footer_action} onClick={handleDelete}>
                  <RemoveImg height={14} />
                  <span>Usuń</span>
                </button>

                {checked.length === 1 && (
                  <button
                    className={cs.footer_action}
                    onClick={() => setIsChangeNameModalOpen(true)}
                  >
                    <EditImg width={14} />
                    <span>Zmień nazwę</span>
                  </button>
                )}
              </footer>
            ) : null
          }
          emptyList={<EmptyList />}
          onToggle={(material, isChecked) => {
            if (isChecked) {
              setChecked([...checked, material]);
            } else {
              uncheck(material);
            }
          }}
        />
      </section>

      <AddFolderModal
        isOpen={isAddNewFolderOpen}
        parentId={currentFolder?._id || null}
        onClose={() => setIsAddNewFolderOpen(false)}
      />

      <AddLinkModal
        isOpen={isAddLinkModalOpen}
        parentId={currentFolder?._id || null}
        onClose={() => setIsAddLinkModalOpen(false)}
      />

      <ChangeNameModal
        isOpen={isChangeNameModalOpen}
        material={checked?.[0]}
        onClose={() => {
          setIsChangeNameModalOpen(false);
          setChecked([]);
        }}
      />
    </main>
  );

  function uncheck(material: MaterialDtoType) {
    setChecked((prev) => prev.filter(({ _id }) => _id !== material._id));
  }

  async function handleDelete() {
    const isConfirmed = await openConfirmationModal({
      confirmText: "Usuń",
      text: `Czy na chcesz usunąć zaznaczone materiały?`,
    });

    if (isConfirmed) {
      await deleteMaterials.mutateAsync({
        materialIds: checked.map(({ _id }) => _id),
      });

      setChecked([]);
    }
  }
};

type AddFileMaterialsBtnProps = {
  currentFolder: MaterialType | null;
  label?: string;
  color?: "primary" | "danger" | "secondary" | "neutral" | "dark";
};

export const AddFileMaterialsBtn = ({
  currentFolder,
  label = "Dodaj pliki",
  color = "primary",
}: AddFileMaterialsBtnProps) => {
  const queryClient = useQueryClient();

  const fileInputElement = useRef<HTMLInputElement>(null);

  return (
    <Button
      color={color}
      onClick={() => {
        fileInputElement.current?.click();
      }}
    >
      <CopyImg height={14} />

      <span>{label}</span>

      <input
        type="file"
        multiple
        className={cs.fileInput}
        ref={fileInputElement}
        onChange={(e) => {
          if (!e.target.files) return;

          if (e.target.files?.length > 0) {
            const promises: Promise<any>[] = [];

            Array.from(e.target.files).forEach((file) => {
              const promise = MaterialApi.createFromFile({
                file,
                parentId: currentFolder?._id || null,
              });

              promise.then((result) => {
                const error = result?.error;

                if (!error) return result;

                if (error.message === "FILE_TOO_LARGE") {
                  ToastService.error(
                    `Plik ${file.name} przekracza dopuszczalny rozmiar 15MB.`,
                    { duration: 3_000 },
                  );
                }

                throw error;
              });

              promises.push(promise);
            });

            Promise.allSettled(promises).then((results) => {
              const anyUploadSucceeded = results.some(
                (result) => result.status === "fulfilled",
              );

              if (anyUploadSucceeded) {
                return queryClient.invalidateQueries({
                  queryKey: ["materials"],
                  refetchInactive: true,
                });
              }
            });
          }

          e.target.value = "";
        }}
      />
    </Button>
  );
};
