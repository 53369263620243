import classNames from "classnames";

import { ReactComponent as CloneImg } from "@img/clone.svg";
import { ReactComponent as PencilImg } from "@img/pencil2.svg";
import { ReactComponent as ShareImg } from "@img/share.svg";
import { ReactComponent as TrashImg } from "@img/trash_light.svg";

import cs from "./ElevatedIconBtn.module.scss";

type iconType = "clone" | "trash" | "edit" | "share";

type ElevatedIconBtnProps = {
  icon: iconType;
  className?: string;
  title?: string;
  onClick: () => void;
};

export const ElevatedIconBtn = ({
  icon,
  className,
  title,
  onClick,
}: ElevatedIconBtnProps) => {
  let Icon = null;

  switch (icon) {
    case "edit":
      Icon = PencilImg;
      break;
    case "clone":
      Icon = CloneImg;
      break;
    case "trash":
      Icon = TrashImg;
      break;
    case "share":
      Icon = ShareImg;
      break;
  }

  const rootCs = classNames(cs.root, className);

  return (
    <button className={rootCs} title={title} onClick={onClick}>
      <Icon />
    </button>
  );
};
