import { useMutation, useQueryClient } from "react-query";

import { TodoApi } from "../api/TodoApi";
import { TodoType } from "../types/TodoType";
import { deleteById } from "../utils/utils";

export const useDeleteTodo = () => {
  const queryClient = useQueryClient();

  return useMutation(TodoApi.deleteById, {
    onMutate: async (todoId) => {
      await queryClient.cancelQueries(["todos"]);

      const cachedValue =
        queryClient.getQueriesData<TodoType[]>(["todos"]) || [];

      queryClient.setQueriesData<any[]>(["todos"], (previous = []) =>
        deleteById(todoId, previous),
      );

      return { cachedValue };
    },
    onError: (err, newTodo, context) => {
      queryClient.setQueriesData(["todos"], context?.cachedValue);
    },
  });
};
