import { useMutation, useQueryClient } from "react-query";

import { TemplatesApi } from "../api/TemplatesApi";

export const useDeleteTemplate = () => {
  const queryClient = useQueryClient();

  return useMutation("templates", TemplatesApi.deleteById, {
    onSuccess() {
      return queryClient.invalidateQueries({
        queryKey: ["templates"],
        refetchInactive: true,
      });
    },
  });
};
