import { useMutation, useQueryClient } from "react-query";

import { LessonApi } from "@api/LessonApi";

export const useDeleteLesson = () => {
  const queryClient = useQueryClient();

  return useMutation(LessonApi.deleteById, {
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries({
          queryKey: ["lessons"],
          refetchInactive: true,
        }),
        queryClient.invalidateQueries({
          queryKey: ["calendar"],
          refetchInactive: true,
        }),
        queryClient.invalidateQueries({
          queryKey: ["standalone-arrears"],
          refetchInactive: false,
        }),
      ]);
    },
  });
};
