import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Spinner } from "@components/Spinner";
import { ToastService } from "@services/ToastService";

export const PaymentSucceededPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    ToastService.success("Dziękujemy za dokonanie płatnośći!", {
      duration: 3000,
    });

    navigate("/", { replace: true });
  }, [navigate]);

  return <Spinner loading className="fullViewSpinner" size={54} />;
};
