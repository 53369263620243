import { Button } from "@components/Button";
import { Modal } from "@components/Modal";
import { LessonType } from "@customTypes/LessonType";
import { ReactComponent as PencilImg } from "@img/pencil2.svg";
import { ReactComponent as ShareImg } from "@img/share.svg";
import { ReactComponent as TrashImg } from "@img/trash_2.svg";

import { Details } from "./Details";

import cs from "./LessonDetailsModal.module.scss";

type LessonDetailsModalProps = {
  isOpen: boolean;
  lesson: LessonType;
  onDelete: () => void;
  onEdit: () => void;
  onParticipantClick: () => void;
  onDateChange: (updatedDate: Date) => void;
  onShare: () => void;
  onClose: () => void;
};

export const LessonDetailsModal = ({
  isOpen,
  lesson,
  onEdit,
  onDelete,
  onParticipantClick,
  onDateChange,
  onShare,
  onClose,
}: LessonDetailsModalProps) => {
  const footer = lesson.isDeleted ? null : (
    <div className={cs.footer}>
      <Button
        color="danger"
        type="outlined"
        size="small"
        className={cs.btn}
        onClick={onDelete}
      >
        <TrashImg height={14} />
        <span>{lesson?.isPast ? "Usuń" : "Odwołaj"}</span>
      </Button>

      <Button
        color="secondary"
        size="small"
        className={cs.btn}
        onClick={onEdit}
      >
        <PencilImg height={14} />
        <span>Edytuj</span>
      </Button>
    </div>
  );

  return (
    <Modal
      isOpen={isOpen}
      title="Spotkanie"
      clickOutsideToClose
      center
      footer={footer}
      className={cs.modal}
      onClose={onClose}
    >
      <div style={{ textAlign: "right", marginBottom: 8 }}>
        <Button
          color="primary"
          size="small"
          className={cs.shareBtn}
          onClick={onShare}
        >
          <ShareImg height={12} />
          <span>Udostępnij</span>
        </Button>
      </div>

      <Details
        lesson={lesson}
        onParticipantClick={onParticipantClick}
        onDateChange={onDateChange}
      />
    </Modal>
  );
};
