import { useTemplates } from "../../hooks/useTemplates";
import { Search } from "../Search";

type TemplatePickerProps = {
  placeholder?: string;
  selected: any[];
  onChange: (value: any) => void;
};

export const TemplatePicker = ({
  placeholder,
  selected,
  onChange,
}: TemplatePickerProps) => {
  const { data = [] } = useTemplates();

  const selectedIds = selected.map((template) => template._id);

  return (
    <Search
      placeholder={placeholder}
      items={data}
      selected={selectedIds}
      multi
      labelField="topic"
      onChange={handleTemplatesChanged}
    />
  );

  function handleTemplatesChanged(selectedTemplateIds: string[]) {
    onChange(
      data.filter((template) => selectedTemplateIds.includes(template._id)),
    );
  }
};
