import { ReactNode, useState } from "react";

import { useDeleteLesson } from "@hooks/useDeleteLesson";
import { useUpdateLesson } from "@hooks/useUpdateLesson";
import { LessonService } from "@services/LessonService";
import { ToastService } from "@services/ToastService";
import { useAppStore } from "@store";

import { LessonDetailsModal } from "./LessonDetailsModal";

type LessonDetailsProps = {
  lesson: any;
  children: ReactNode;
};

export const LessonDetails = ({ lesson, children }: LessonDetailsProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openUpsertLessonModal = useAppStore(
    (state) => state.openUpsertLessonModal,
  );
  const openConfirmationModal = useAppStore(
    (state) => state.openConfirmationModal,
  );
  const openStudentCenterModal = useAppStore(
    (state) => state.openStudentCenterModal,
  );

  const deleteLesson = useDeleteLesson();
  const updateLesson = useUpdateLesson();

  return (
    <>
      <LessonDetailsModal
        isOpen={isModalOpen}
        lesson={lesson}
        onDelete={handleDelete}
        onEdit={handleEdit}
        onParticipantClick={handleParticipantClick}
        onDateChange={handleDateChange}
        onShare={handleShare}
        onClose={() => setIsModalOpen(false)}
      />

      <span onClick={() => setIsModalOpen(true)}>{children}</span>
    </>
  );

  function handleParticipantClick() {
    openStudentCenterModal(lesson.participant._id);
  }

  function handleEdit() {
    openUpsertLessonModal(lesson);
  }

  async function handleDelete() {
    const verb = lesson?.isPast ? "usunąć" : "odwołać";
    const verbSubmit = lesson?.isPast ? "Usuń" : "Odwołaj";

    const isConfirmed = await openConfirmationModal({
      confirmText: verbSubmit,
      text: `Czy na pewno chcesz ${verb} tę lekcję?`,
    });

    if (isConfirmed && lesson) {
      await deleteLesson.mutate({ lessonId: lesson._id });

      setIsModalOpen(false);

      const verb = lesson?.isPast ? "usunięta" : "odwołana";
      ToastService.success(`Lekcja została ${verb}.`);
    }
  }

  async function handleShare() {
    await LessonService.share(lesson._id);
  }

  async function handleDateChange(updateDate: Date) {
    if (updateDate.valueOf() === lesson.date.valueOf()) return;

    await updateLesson.mutateAsync({
      lessonId: lesson._id,
      updatedFields: {
        date: updateDate,
      },
    });

    ToastService.success(`Lekcja została zaktualizowana.`);
  }
};
