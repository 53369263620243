import axios from "axios";
import produce from "immer";

type WithId = {
  _id: string;
};

export function deleteById<Type extends WithId>(
  id: string,
  collection: Type[],
) {
  const copy = [...collection];

  const index = copy.findIndex(({ _id }) => _id === id);

  if (index !== -1) {
    copy.splice(index, 1);
  }

  return copy;
}

export function findById<Type extends WithId>(id: string, collection: Type[]) {
  return collection.find(({ _id }) => _id === id);
}

export function deleteByIdShallow(id: string, collection: WithId[]) {
  const index = collection.findIndex(({ _id }) => _id === id);

  if (index !== -1) {
    collection.splice(index, 1);
  }

  return collection;
}

export function genRandomId() {
  return String(Math.ceil(Math.random() * 1e6));
}

export function withRandomId(collection: any[]) {
  return collection.map((item) => ({ ...item, _id: genRandomId() }));
}

export function replaceWithId(collection: WithId[], updatedItem: WithId) {
  return produce(collection, (draft) => {
    const index = draft.findIndex(({ _id }) => _id === updatedItem._id);

    draft[index] = updatedItem;
  });
}

export async function downloadFile(fileUrl: string, parent?: HTMLElement) {
  const response = await axios({
    url: fileUrl,
    method: "GET",
    responseType: "blob",
  });

  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;

  const contentDisposition = response.headers["content-disposition"];

  if (!contentDisposition) return;

  const fileName = decodeURIComponent(
    contentDisposition.split("''")[1].replace(";", ""),
  );
  link.setAttribute("download", fileName);

  (parent || document.body).appendChild(link);
  link.click();
  (parent || document.body).removeChild(link);
}

export async function openLink(link: string, parent?: HTMLElement) {
  const anchorEl = document.createElement("a");
  anchorEl.href = link;
  anchorEl.target = "_blank";

  (parent || document.body).appendChild(anchorEl);
  anchorEl.click();
  (parent || document.body).removeChild(anchorEl);
}

export const isSmallScreen = window.innerWidth < 768;

export function b64toBlob(b64Data: string, contentType = "", sliceSize = 512) {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
}

export function omitEmptyStrings(object: Record<string, any>) {
  return Object.fromEntries(
    Object.entries(object).filter(
      ([, value]) => !(typeof value === "string" && value.length === 0),
    ),
  );
}

export function checkDemoUser(user?: { email: string } | null) {
  return /contact\+\S*@up4edu\.com/gim.test(user?.email || "");
}
