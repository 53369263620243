import { TemplateType } from "@customTypes/TemplateType";

import { createAxiosInstance } from "./_createAxiosInstance";

const api = createAxiosInstance("/api/template");

export class TemplatesApi {
  static async fetchAll(sort: string[]) {
    const { data } = await api.get<TemplateType[]>("", {
      params: { sort },
    });

    return data;
  }

  static async deleteById({ templateId }: { templateId: string }) {
    return api.delete(`/${templateId}`);
  }

  static async copy({ template }: { template: TemplateType }) {
    return TemplatesApi.create({
      topic: template.topic + " - kopia",
      noteForStudent: template.noteForStudent,
      materials: template.materials,
    });
  }

  static async create(
    template: Pick<TemplateType, "topic" | "noteForStudent" | "materials">,
  ) {
    const { data } = await api.post("", template);

    return data;
  }

  static async replace(template: TemplateType) {
    return api.put("", template);
  }
}
