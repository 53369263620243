import { ReactNode } from "react";

import classNames from "classnames";

import cs from "./RadioButton.module.scss";

type RadioButtonProps = {
  value?: any;
  checked?: boolean;
  label?: ReactNode;
  name?: string;
  disabled?: boolean;
  onChange?: (selected: RadioButtonProps["value"]) => void;
};

export const RadioButton = ({
  value,
  checked,
  label,
  name = "radio",
  disabled,
  onChange,
}: RadioButtonProps) => {
  const rootCs = classNames(cs.root, {
    [cs.disabled]: disabled,
  });

  return (
    <label className={rootCs}>
      <input
        type="radio"
        checked={checked}
        name={name}
        disabled={disabled}
        onChange={() => onChange?.(value)}
      />

      {label && <span>{label}</span>}
    </label>
  );
};
