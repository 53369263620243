import { Button } from "@components/Button";
import { ElevatedIconBtn } from "@components/ElevatedIconBtn";
import { MaterialPill } from "@components/MaterialPill";
import { Modal } from "@components/Modal";
import { TemplateType } from "@customTypes/TemplateType";
import { formatDate } from "@utils/date.utils";

import cs from "./TemplatesListPage.module.scss";

type DetailsModalProps = {
  isOpen: boolean;
  activeTemplate: TemplateType | null;
  onClose: () => void;
  onEdit: () => void;
  onCopy: (template: TemplateType) => void;
  onDelete: (templateId: string) => void;
};

export const DetailsModal = ({
  isOpen,
  activeTemplate,
  onEdit,
  onDelete,
  onCopy,
  onClose,
}: DetailsModalProps) => {
  if (!activeTemplate) return null;

  return (
    <Modal
      title="Szablon lekcji"
      isOpen={isOpen}
      className={cs.modal}
      footer={
        <Button color="primary" onClick={onClose}>
          Zamknij
        </Button>
      }
      onClose={onClose}
    >
      <div className={cs.detailsModal_actions}>
        <ElevatedIconBtn icon="edit" title="Edytuj" onClick={onEdit} />
        <ElevatedIconBtn
          icon="clone"
          title="Kopiuj"
          onClick={() => onCopy(activeTemplate)}
        />
        <ElevatedIconBtn
          icon="trash"
          title="Usuń"
          onClick={() => onDelete(activeTemplate?._id)}
        />
      </div>

      <div className={cs.detailsModalBody} tabIndex={0}>
        <div className={cs.sidePanelSection__main}>
          <div className="medium">Temat lekcji:</div>
          <div className="medium">{activeTemplate.topic}</div>

          <div className="medium">Utworzono:</div>
          <div>{formatDate(activeTemplate.createdAt)}</div>
        </div>

        <div className={cs.sidePanelSection}>
          <div className="medium">Materiały:</div>
          <div className={cs.materials}>
            {activeTemplate.materials.length === 0 && "-"}

            {activeTemplate.materials.map((material) => (
              <MaterialPill key={material._id} material={material} />
            ))}
          </div>

          <div className="medium">Opis lekcji:</div>
          <div
            dangerouslySetInnerHTML={{
              __html: activeTemplate.noteForStudent || "-",
            }}
          />
        </div>
      </div>
    </Modal>
  );
};
