import { useEffect, useState } from "react";

import { Button } from "@components/Button";
import { Modal } from "@components/Modal";
import { PlansSearch } from "@components/PlansSearch";
import { PlanType } from "@customTypes/PlanType";
import { TemplateType } from "@customTypes/TemplateType";
import { useCreateLessonsFromTemplates } from "@hooks/useCreateLessonsFromTemplates";
import { deleteById, findById } from "@utils/utils";

import { Template } from "./Template";

import cs from "./AssignPlanModal.module.scss";

type AssignPlanModalProps = {
  isOpen: boolean;
  participantId: string;
  onClose: () => void;
  onAfter?: () => void;
};

export const AssignPlanModal = ({
  isOpen,
  participantId,
  onClose,
  onAfter,
}: AssignPlanModalProps) => {
  const [plan, setPlan] = useState<PlanType | null>(null);
  const [templates, setTemplates] = useState<TemplateType[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const createLessonsFromTemplates = useCreateLessonsFromTemplates();

  useEffect(() => {
    if (isOpen) {
      if (plan) {
        setTemplates([...plan.templates]);
      } else {
        setTemplates([]);
      }
    }
  }, [plan, isOpen]);

  return (
    <Modal
      title="Przypisz kurs"
      isOpen={isOpen}
      className={cs.modal}
      helpTooltip="Czym jest kurs?"
      help={`Kurs jest zbiorem lekcji, który możesz przypisać do ucznia. Dzięki temu nie musisz w kółko tworzyć tych samych lekcji. Po przypisaniu kursu wszystkie lekcje, bez przypisanej daty, pojawią się w profilu ucznia w Centrum Ucznia. Zaimportowane lekcje możesz dowolnie edytować, usuwać i zmieniać ich kolejność. Lekcje pojawią się w kalendarzu Twoim oraz ucznia po uzupełnieniu daty i godziny lekcji.`}
      footer={
        <Button color="primary" isLoading={isLoading} onClick={onConfirm}>
          Wybierz
        </Button>
      }
      onClose={handleClose}
    >
      <section className={cs.planSection}>
        <PlansSearch value={plan} onChange={setPlan} />
      </section>

      <section className={cs.templatesSection}>
        <div className={cs.templatesSection_title}>
          Szablony lekcji, które zostaną przypisane:
        </div>

        {!plan && (
          <div className={cs.templatesSection_noTemplates}>
            Musisz najpierw wybrać kurs.
          </div>
        )}

        {plan?.templates.length === 0 && (
          <div className={cs.templatesSection_noTemplates}>
            Ten plan nie posiada żadnych szablonów.
          </div>
        )}

        {templates.map((template, index) => (
          <Template
            key={template._id}
            template={template}
            counter={index + 1}
            onDetach={handleTemplateDetached}
            onMoveUp={handleMoveUp}
            onMoveDown={handleMoveDown}
          />
        ))}
      </section>
    </Modal>
  );

  function handleTemplateDetached(templateId: string) {
    setTemplates(deleteById(templateId, templates));
  }

  function handleMoveUp(templateId: string) {
    const template = findById(templateId, templates);
    const index = templates.findIndex(
      (template) => template._id === templateId,
    );

    if (index > 0 && template) {
      const updatedTemplates = deleteById(templateId, templates);
      updatedTemplates.splice(index - 1, 0, template);

      setTemplates(updatedTemplates);
    }
  }

  function handleMoveDown(templateId: string) {
    const template = findById(templateId, templates);
    const index = templates.findIndex(
      (template) => template._id === templateId,
    );

    if (index < templates.length - 1 && template) {
      const updatedTemplates = deleteById(templateId, templates);
      updatedTemplates.splice(index + 1, 0, template);

      setTemplates(updatedTemplates);
    }
  }

  async function onConfirm() {
    setIsLoading(true);

    try {
      if (templates.length > 0) {
        await createLessonsFromTemplates.mutateAsync({
          templateIds: templates.map((template) => template._id),
          participantId,
        });

        onAfter?.();
      }

      handleClose();
    } finally {
      setIsLoading(false);
    }
  }

  function handleClose() {
    clear();
    onClose();
  }

  function clear() {
    setPlan(null);
    setTemplates([]);
  }
};
