import { useMutation, useQueryClient } from "react-query";

import { TimeSlotApi } from "../api/TimeSlotApi";

export const useDeleteTimeSlot = () => {
  const queryClient = useQueryClient();

  return useMutation(TimeSlotApi.deleteById, {
    onSuccess() {
      return queryClient.invalidateQueries({
        queryKey: ["calendar"],
      });
    },
  });
};
