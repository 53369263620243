//@ts-nocheck
import { useEffect, useState } from "react";

import { TouchedCellProvider } from "@components/CalendarSingleDay/TouchedCell";
import { UpsertRegularEventModal } from "@components/UpsertRegularEventModal";
import { UpsertTimeSlotModal } from "@components/UpsertTimeSlotModal";
import { useCurrentUser } from "@hooks/useCurrentUser";
import { getDatesInRange, isCurrentHour } from "@utils/date.utils";

import { WeekViewDay } from "./WeekViewDay";

import cs from "./WeekView.module.scss";

export const WeekView = ({ startDate, endDate, events }) => {
  const { currentUser } = useCurrentUser();

  const [isNewRegularEventModalOpen, setIsNewRegularEventModalOpen] =
    useState(false);
  const [isAddTimeSlotModalOpen, setIsAddTimeSlotModalOpen] = useState(false);
  const [clickedHour, setClickedHour] = useState<Date>();
  const [calendarHours, setCalendarHours] = useState(calcWorkingHours);

  const datesRange = getDatesInRange(startDate, endDate);

  useEffect(() => {
    setCalendarHours(calcWorkingHours());
  }, [currentUser]);

  return (
    <TouchedCellProvider>
      <div className={cs.WeekView}>
        <div>
          {calendarHours.map((calendarHour: any) => {
            let className = cs.hour;

            if (isCurrentHour(calendarHour.hour)) {
              className += " " + cs.hour__current;
            }

            return (
              <div key={calendarHour.hour} className={cs.day}>
                <div className={cs.day__hour}>
                  <span className={className}>{calendarHour.hour}</span>
                </div>
              </div>
            );
          })}
        </div>

        <div className={cs.days} data-cols={datesRange.length}>
          {datesRange.map((date) => (
            <WeekViewDay
              key={date}
              date={date}
              events={events}
              onNewTimeSlot={onNewTimeSlot}
              onNewRegularEvent={onNewRegularEvent}
            />
          ))}
        </div>

        <UpsertRegularEventModal
          isOpen={isNewRegularEventModalOpen}
          regularEvent={{ date: clickedHour }}
          onClose={() => setIsNewRegularEventModalOpen(false)}
        />

        <UpsertTimeSlotModal
          isOpen={isAddTimeSlotModalOpen}
          timeSlot={{ date: clickedHour }}
          onClose={() => setIsAddTimeSlotModalOpen(false)}
        />
      </div>
    </TouchedCellProvider>
  );

  function calcWorkingHours() {
    const startHour = currentUser?.settingWorkStartHour;
    const endHour = currentUser?.settingWorkEndHour;

    return new Array(endHour - startHour + 1)
      .fill(1)
      .map((el, index) => ({ hour: index + startHour }));
  }

  function onNewTimeSlot(clickedHour: Date) {
    setClickedHour(clickedHour);
    setIsAddTimeSlotModalOpen(true);
  }

  function onNewRegularEvent(clickedHour: Date) {
    setClickedHour(clickedHour);
    setIsNewRegularEventModalOpen(true);
  }
};
