import sortBy from "lodash.sortby";

import { StudentDtoType } from "@customTypes/StudentDtoType";
import { StudentType } from "@customTypes/StudentType";
import { getState } from "@store";

export class StudentDtoService {
  static transformAll(studentDtos: StudentDtoType[]): StudentType[] {
    return studentDtos.map((dto) => StudentDtoService.transform(dto));
  }

  static transform(studentDto: StudentDtoType): StudentType {
    const currentUser = getState().currentUser;

    const withDates = this.formatDates(studentDto);

    return {
      ...withDates,
      shortName: studentDto.firstName[0] + ". " + studentDto.lastName,
      paymentLogic:
        studentDto.paymentLogic || currentUser?.paymentLogic || null,
      contact: sortBy(studentDto.contact ?? [], ["_id"]),
      mainContact:
        studentDto.contact?.find((contact) => contact.isMain) ??
        studentDto.contact?.at(0),
    };
  }

  private static formatDates(studentDto: StudentDtoType): StudentDtoType {
    const copy = { ...studentDto };

    if (copy.lastLesson) {
      copy.lastLesson.date = new Date(copy.lastLesson.date);
    }
    if (copy.invitationLastSent) {
      copy.invitationLastSent = new Date(copy.invitationLastSent);
    }

    return copy;
  }
}
