import { useMutation, useQueryClient } from "react-query";

import { TodoApi } from "../api/TodoApi";
import { TodoType } from "../types/TodoType";

export const useSaveTodo = () => {
  const queryClient = useQueryClient();

  return useMutation(TodoApi.replace, {
    onMutate: async (updatedTodo) => {
      await queryClient.cancelQueries(["todos"]);

      const cachedValue =
        queryClient.getQueriesData<TodoType[]>(["todos"]) || [];

      queryClient.setQueriesData<TodoType[]>(["todos"], (previous = []) =>
        previous.map((todo) =>
          todo._id !== updatedTodo._id
            ? todo
            : {
                ...todo,
                value: updatedTodo.value,
                participant: updatedTodo.participant,
                done: updatedTodo.done,
                updatedAt: new Date(),
              },
        ),
      );

      return { cachedValue };
    },
    onError: (err, newTodo, context) => {
      queryClient.setQueriesData(["todos"], context?.cachedValue);
    },
  });
};
