import { LessonsSubList } from "@components/LessonsSubList";
import { PaginationType } from "@customTypes/PaginationType";
import { useLessons } from "@hooks/useLessons";

export type TabProps = {
  participantId: string;
};

const PAGINATION: PaginationType = { page: 1, pageSize: 50 };

export const TabLessons = ({ participantId }: TabProps) => {
  // TODO: Later
  // const { data: incomplete = [] } = useLessons(
  //   { status: "incomplete", participants: [participantId] },
  //   [],
  // );

  const { data: updatedHomework = [] } = useLessons(
    { status: "homework-updated", participants: [participantId] },
    [],
    PAGINATION,
  );

  return (
    <>
      {/*<LessonsSubList*/}
      {/*  title="Oznaczone jako wymagające uzupełnienia"*/}
      {/*  items={incomplete}*/}
      {/*  type="lesson"*/}
      {/*  expanded={incomplete.length > 0}*/}
      {/*/>*/}

      <LessonsSubList
        title="Uczeń zaktualizował zad. domowe"
        items={updatedHomework}
        type="lesson"
        expanded={updatedHomework.length > 0}
      />
    </>
  );
};
