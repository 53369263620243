import { PaymentLogicType } from "@customTypes/PaymentLogicType";
import { addDays, endOf, startOf, subtractDays } from "@utils/date.utils";

export class ArrearService {
  static calcPaymentDeadline(paymentLogic: PaymentLogicType, baseDate: Date) {
    if (baseDate === null) return null;

    const days = paymentLogic.days || 0;

    switch (paymentLogic.paymentLogicId) {
      case 1:
        return endOf(baseDate, "month");
      case 2:
        return startOf(baseDate, "month");
      case 3:
        if (isNaN(days) || days < 1) return null;

        return subtractDays(baseDate, days);
      case 4:
        if (isNaN(days) || days < 1) return null;

        return addDays(baseDate, days);
    }
  }
}
