import { useEffect, useState } from "react";

import classNames from "classnames";

import { ArrearBox } from "../ArrearBox";
import { LessonBox } from "../LessonBox";

import caretDownImg from "./img/caret_down.svg";

import cs from "./LessonsSubList.module.scss";

type LessonsSubListProps = {
  title: string;
  items: any[];
  expanded?: boolean;
  type?: "lesson" | "arrear";
};

export const LessonsSubList = ({
  title,
  items,
  expanded = false,
  type = "lesson", // TODO: Default doesn't make sense
}: LessonsSubListProps) => {
  const [isExpanded, setIsExpanded] = useState(expanded);

  const rootCs = classNames(cs.root, {
    [cs.expanded]: isExpanded,
  });

  useEffect(() => {
    setIsExpanded(expanded);
  }, [expanded]);

  return (
    <div className={rootCs}>
      <div className={cs.header} onClick={toggle}>
        <img src={caretDownImg} alt="" className={cs.caret} />

        <span>
          {title} ({items.length})
        </span>
      </div>

      {isExpanded && (
        <div className={cs.list}>
          {items.map((item) =>
            type === "lesson" ? (
              <LessonBox key={item._id} lesson={item} />
            ) : (
              <ArrearBox key={item._id} arrear={item} />
            ),
          )}
        </div>
      )}
    </div>
  );

  function toggle() {
    setIsExpanded(!isExpanded);
  }
};
