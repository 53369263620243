// @ts-nocheck
import { useState } from "react";

import { useCurrentUser } from "../../hooks/useCurrentUser";
import { useReplaceCurrentUser } from "../../hooks/useReplaceCurrentUser";
import { ReactComponent as SettingsImg } from "../../img/settings_new.svg";
import { ReactComponent as TrashImg } from "../../img/trash.svg";
import { Button } from "../Button";
import { Input } from "../Input";
import { Pill } from "../Pill";
import { Popover } from "../Popover";

import cs from "./Prices.module.scss";
import parentCs from "./UpsertLessonModal.module.scss";

type DurationProps = {
  selectedDuration: number | null;
  onChange: (price: number) => void;
};

const PopoverContent = ({ availableDurations, onChange }) => {
  const [newDuration, setNewDuration] = useState("");

  return (
    <div>
      <section className={cs.pricesSection}>
        <div className={cs.sectionTitle}>Podpowiedzi:</div>

        <div className={cs.addNew}>
          <Input
            type="number"
            value={newDuration}
            min={1}
            onChange={setNewDuration}
          />

          <Button
            color="primary"
            type="outlined"
            size="small"
            onClick={handleDurationAdded}
          >
            Dodaj
          </Button>
        </div>

        <div className={cs.availablePrices}>
          {availableDurations.map((duration) => (
            <div key={duration.value} className={cs.price}>
              <span>{duration.value} min</span>

              <button
                className="btn-icon"
                onClick={() => handleDurationRemoved(duration.value)}
              >
                <TrashImg width={14} />
              </button>
            </div>
          ))}
        </div>
      </section>
    </div>
  );

  function handleDurationAdded() {
    const duration = Number.parseInt(newDuration);

    if (!(duration > 0)) {
      return;
    }

    if (availableDurations.some((d) => d.value === duration)) {
      setNewDuration("");
      return;
    }

    const updateDurations = [
      ...availableDurations,
      { value: duration, isDefault: false },
    ].sort((a, b) => (a.value > b.value ? 1 : -1));

    onChange(updateDurations);

    setNewDuration("");
  }

  function handleDurationRemoved(removedDuration: number) {
    onChange(
      availableDurations.filter(
        (duration) => duration.value !== removedDuration,
      ),
    );
  }
};

export const Duration = ({ selectedDuration, onChange }: DurationProps) => {
  const { currentUser } = useCurrentUser();

  const replaceCurrentUser = useReplaceCurrentUser();

  const availableDurations = currentUser?.durations || [];

  return (
    <div>
      <div className={parentCs.label}>
        <span>Czas trwania</span>

        <Popover
          popover={
            <PopoverContent
              availableDurations={availableDurations}
              onChange={handleAvailableDurationsChanged}
            />
          }
          position={["bottom", "top", "right", "left"]}
          square
          className={cs.popover}
        >
          <button className="btn-icon" type="button">
            <SettingsImg width={14} />
          </button>
        </Popover>
      </div>

      <div className={parentCs.prices}>
        {availableDurations.map((duration) => (
          <Pill
            key={duration.value}
            value={duration}
            type={duration.value === selectedDuration ? "normal" : "outline"}
            onClick={() => onChange(duration.value)}
          >
            {duration.value}min
          </Pill>
        ))}
      </div>
    </div>
  );

  function handleAvailableDurationsChanged(availableDurations: number[]) {
    replaceCurrentUser.mutate({
      currentUser: {
        ...currentUser,
        durations: availableDurations,
      },
    });
  }
};
