import { useEffect, useRef, useState } from "react";
import { useQueryClient } from "react-query";

import { PlansApi } from "../../api/PlansApi";
import { PlanType } from "../../types/PlanType";
import { TemplateType } from "../../types/TemplateType";
import { AssignedTemplatesManager } from "../AssignedTemplatesManager";
import { Button } from "../Button";
import { Input } from "../Input";
import { Modal } from "../Modal";
import { Textarea } from "../Textarea";

import cs from "./UpsertPlanModal.module.scss";

type UpsertPlanModalProps = {
  isOpen: boolean;
  plan?: PlanType | null;
  onClose: () => void;
  onAfterUpsert?: (plan: PlanType) => void;
};

export const UpsertPlanModal = ({
  isOpen,
  plan,
  onClose,
  onAfterUpsert,
}: UpsertPlanModalProps) => {
  const [name, setName] = useState("");
  const [note, setNote] = useState("");
  const [templates, setTemplates] = useState<TemplateType[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const formElement = useRef<HTMLFormElement>(null);

  const queryClient = useQueryClient();

  useEffect(() => {
    if (isOpen && plan) {
      setName(plan.name || "");
      setNote(plan.note || "");
      setTemplates(plan.templates || []);
    }
  }, [plan, isOpen]);

  const mode = plan ? "edit" : "new";

  return (
    <Modal
      title={mode === "new" ? "Dodaj kurs" : "Edytuj kurs"}
      isOpen={isOpen}
      className={cs.modal}
      helpTooltip="Czym jest kurs?"
      help={`Kurs jest zbiorem lekcji, który możesz przypisać do ucznia. Dzięki temu nie musisz w kółko tworzyć tych samych lekcji. Po przypisaniu kursu wszystkie lekcje, bez przypisanej daty, pojawią się w profilu ucznia w Centrum Ucznia. Zaimportowane lekcje możesz dowolnie edytować, usuwać i zmieniać ich kolejność. Lekcje pojawią się w kalendarzu Twoim oraz ucznia po uzupełnieniu daty i godziny lekcji.`}
      footer={
        <Button color="primary" isLoading={isLoading} onClick={onConfirm}>
          <span>{mode === "new" ? "Dodaj" : "Zapisz"}</span>
        </Button>
      }
      onClose={onClose}
    >
      <form ref={formElement}>
        <section className={cs.section}>
          <Input label="Nazwa" value={name} required onChange={setName} />

          <Textarea label="Notatka" value={note} onChange={setNote} />
        </section>

        <section className={cs.plansSection}>
          <AssignedTemplatesManager
            templates={templates}
            onChange={setTemplates}
          />
        </section>
      </form>
    </Modal>
  );

  async function onConfirm() {
    const isValid = formElement.current?.reportValidity();

    if (!isValid) return;

    setIsLoading(true);

    try {
      let returnedItem;

      if (mode === "new") {
        returnedItem = await PlansApi.create({
          name,
          note,
          templates,
        });
      } else if (plan) {
        returnedItem = {
          ...plan,
          name,
          note,
          templates,
        };

        await PlansApi.replace({ updatedPlan: returnedItem });
      }

      await queryClient.invalidateQueries({
        queryKey: ["plans"],
        refetchInactive: true,
      });

      onAfterUpsert?.(returnedItem);

      onClose();
      clear();
    } finally {
      setIsLoading(false);
    }
  }

  function clear() {
    setName("");
    setNote("");
    setTemplates([]);
  }
};
