import { StandaloneArrearType } from "../../types/StandaloneArrearType";
import {
  diff,
  formatDate,
  formatShortDate,
  formatWeekday,
} from "../../utils/date.utils";
import { Chips } from "../Chips";
import calendarImg from "../LessonBox/img/calendar.svg";
import { LessonBulb } from "../LessonBulb";
import { LessonDetails } from "../LessonDetails";

import cs from "./ArrearBox.module.scss";

type DeadlineChipsProps = {
  deadline: Date;
};

export const DeadlineChips = ({ deadline }: DeadlineChipsProps) => {
  const daysAfterDeadline = diff(deadline, new Date());
  const fullDate = formatDate(deadline, "full");

  return (
    <Chips color="danger" className={cs.deadlineChips} title={fullDate}>
      {"$ "}
      {daysAfterDeadline}dni
    </Chips>
  );
};

type ArrearBoxProps = {
  arrear: StandaloneArrearType;
};

export const ArrearBox = ({ arrear }: ArrearBoxProps) => {
  const { lesson } = arrear;

  const date = formatShortDate(lesson.date);
  const weekday = formatWeekday(lesson.date, "long");

  return (
    <LessonDetails lesson={lesson}>
      <div className={cs.root}>
        <img src={calendarImg} alt="" />

        <div>
          <div className={cs.topInfo}>
            <LessonBulb past={lesson.isPast} size="small" />

            <span>
              {date} ({weekday}),
            </span>

            <span>
              {lesson.startHourStr}-{lesson.endHourStr};
            </span>

            <span className={cs.students}>
              {lesson.participant.details.name}
            </span>
          </div>

          <div className={cs.topicContainer}>
            <span title={lesson.topic} className={cs.topic}>
              {lesson.topic}
            </span>

            {arrear.isAfterDeadline && (
              <DeadlineChips deadline={lesson.paymentDeadline} />
            )}
          </div>
        </div>
      </div>
    </LessonDetails>
  );
};
