// @ts-nocheck

import React from "react";

import { ReactComponent as DropdownArrowImg } from "../../img/dropdown_arrow.svg";
import { MaterialType } from "../../types/MaterialType";

import { ReactComponent as FolderImg } from "./img/folder.svg";

import cs from "./FoldersList.module.scss";

type FolderProps = {
  folder: MaterialType;
  onSelect: (folderId: string) => void;
};

export const FolderListItem = ({ folder, onSelect }: FolderProps) => {
  const childFolders = folder.immediateChildren.filter(
    (material) => material.type === "folder",
  );

  const hasChildren = childFolders.length > 0;

  return (
    <div className={cs.item} onClick={handleSelected}>
      <div className={cs.itemName}>
        <DropdownArrowImg
          width={8}
          style={{
            opacity: hasChildren ? 1 : 0,
          }}
        />

        <FolderImg width={13} />

        <div>{folder.name}</div>
      </div>

      {hasChildren && (
        <div className={cs.subItems}>
          {childFolders.map((child) => (
            <FolderListItem
              key={child._id}
              folder={child}
              onSelect={onSelect}
            />
          ))}
        </div>
      )}
    </div>
  );

  function handleSelected(e: React.MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
    onSelect(folder._id);
  }
};
