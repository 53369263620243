//@ts-nocheck
import { LessonBulb } from "@components/LessonBulb";
import { LessonType } from "@customTypes/LessonType";
import { ReactComponent as DownloadImg } from "@img/download.svg";
import { formatShortDate, formatWeekday } from "@utils/date.utils";
import { downloadFile } from "@utils/utils";

import cs from "./LessonsListPage.module.scss";

// TODO:
export const LessonDate = ({ lesson }) => {
  const shortDate = formatShortDate(lesson.date);
  const weekday = formatWeekday(lesson.date, "short");

  return (
    <div className={cs.LessonDate}>
      <LessonBulb past={lesson.isPast} size="default" />

      <div>
        <div>
          {shortDate}, {weekday}
        </div>
        <div className={cs.LessonDate_hours}>
          {lesson.startHourStr}-{lesson.endHourStr}
        </div>
      </div>

      <div></div>
    </div>
  );
};

export const LessonDateWithStudent = ({ lesson }) => {
  const shortDate = formatShortDate(lesson.date);
  const weekday = formatWeekday(lesson.date, "short");

  return (
    <div className={cs.LessonDate}>
      <LessonBulb past={lesson.isPast} size="default" />

      <div>
        <div style={{ fontWeight: 600, wordBreak: "break-all" }}>
          {lesson.participant.details.shortName}
        </div>
        <div>
          {shortDate}, {weekday}
        </div>
        <div className={cs.LessonDate_hours}>
          {lesson.startHourStr}-{lesson.endHourStr}
        </div>
      </div>

      <div></div>
    </div>
  );
};

export const columns = [
  {
    title: "Data lekcji",
    field: "date",
    sortable: true,
    width: 160,
    render(value: any, col: any, row: LessonType) {
      if (!value) return "-";

      return <LessonDate lesson={row} />;
    },
  },
  {
    title: "Uczeń",
    field: "participant",
    width: 180,
    render(field: LessonType["participant"]) {
      return <span title={field.details.name}>{field.details.shortName}</span>;
    },
  },
  {
    title: "Temat",
    field: "topic",
    hideWidth: 500,
  },
  {
    title: "Odesłane zad. domowe",
    field: "completedHomeworks",
    width: 119,
    center: true,
    hideWidth: 767,
    render: function (field: LessonType["completedHomeworks"]): JSX.Element {
      return (
        <span
          className={cs.homeworkCol}
          onClick={(e) => {
            field.forEach((homework) => {
              e.stopPropagation();

              downloadFile(homework.url);
            });
          }}
        >
          <span className="semi-bold mr8">{field.length}</span>

          <button
            className="btn-icon"
            style={{ opacity: field.length > 0 ? 1 : 0 }}
          >
            <DownloadImg width={12} style={{ color: "#197BBD" }} />
          </button>
        </span>
      );
    },
  },
];
