import { useEffect, useState } from "react";

import classNames from "classnames";

import { Event } from "@components/CalendarSingleDay/Event";
import { CalendarEvent } from "@customTypes/CalendarEvent";
import { useCurrentUser } from "@hooks/useCurrentUser";
import { formatDate, isToday } from "@utils/date.utils";

import { AddEvent } from "./AddEvent";

import cs from "./WeekView.module.scss";

function getFreshCalendarHours(date: Date, startHour: number, endHour: number) {
  return new Array(endHour - startHour + 1).fill(1).map((el, index) => {
    const hour = index + startHour;
    const dateCopy = new Date(date);

    return {
      hour,
      date: new Date(dateCopy.setHours(hour, 0, 0, 0)),
    };
  });
}

export const WeekViewDay = ({
  date,
  events,
  onNewTimeSlot,
  onNewRegularEvent,
}: {
  date: Date;
  events: any;
  onNewTimeSlot: (selectedHour: Date) => void;
  onNewRegularEvent: (selectedHour: Date) => void;
}) => {
  const { currentUser } = useCurrentUser();

  const [calendarHours, setCalendarHours] = useState(() => {
    return getFreshCalendarHours(
      date,
      currentUser?.settingWorkStartHour || 6,
      currentUser?.settingWorkEndHour || 23,
    );
  });

  useEffect(() => {
    const selectedDayAsIso = formatDate(date, "iso");

    const selectedDayEvents = events[selectedDayAsIso];

    if (!selectedDayEvents?.length) {
      const freshCopy = getFreshCalendarHours(
        date,
        currentUser.settingWorkStartHour,
        currentUser.settingWorkEndHour,
      );
      setCalendarHours(freshCopy);
      return;
    }

    // TODO: Use immer
    const calendarHoursCopy = getFreshCalendarHours(
      date,
      currentUser.settingWorkStartHour,
      currentUser.settingWorkEndHour,
    );

    selectedDayEvents.forEach((event: any) => {
      const relatedHour = calendarHoursCopy.find(
        (calendarHour) => event.startHour === calendarHour.hour,
      ) as any;

      if (!relatedHour) return;

      // TODO:
      relatedHour.data = [...(relatedHour.data || []), event];
    });

    setCalendarHours(calendarHoursCopy);
  }, [date, events, currentUser]);

  const csRoot = classNames(cs.root, {
    [cs.root_today]: isToday(date),
  });

  return (
    <div className={csRoot}>
      <div>
        {calendarHours.map((calendarHour: any, index) => (
          <div
            key={calendarHour.hour}
            className={cs.day}
            style={{ zIndex: 30 - index }}
          >
            <div className={cs.day__content}>
              {calendarHour.data?.map((event: CalendarEvent) => (
                <Event key={event.details._id} event={event} />
              ))}

              <AddEvent
                calendarHour={calendarHour}
                onNewTimeSlot={onNewTimeSlot}
                onNewRegularEvent={onNewRegularEvent}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
