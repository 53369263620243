import { PaginationType } from "@customTypes/PaginationType";

export function paginationToUrlParams(pagination: PaginationType) {
  const params: any = {};

  params.page = pagination.page;
  params.size = pagination.pageSize;

  return params;
}

export function filtersToUrlParams(filters: Record<string, any>) {
  const params: any = {};

  if (filters.students) {
    params.students = filters.students;
  }
  if (filters.participants) {
    params.participants = filters.participants;
  }
  if (filters.dateRange) {
    params.dateRange = filters.dateRange.filter((date: Date) => date);
  }
  if (filters.status) {
    params.status = filters.status;
  }

  return params;
}
