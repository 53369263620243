import { useRef, useState } from "react";

import { Button } from "@components/Button";
import { Popover } from "@components/Popover";
import { UpsertRegularEventModal } from "@components/UpsertRegularEventModal";
import { UpsertTimeSlotModal } from "@components/UpsertTimeSlotModal";
import { useAppStore } from "@store";

import { useTouchedCell } from "./TouchedCell";

import cs from "./CalendarSingleDay.module.scss";

export const AddEvent = ({ calendarHour }) => {
  const [clickedHour, setClickedHour] = useState<Date>();
  const [isNewRegularEventModalOpen, setIsNewRegularEventModalOpen] =
    useState(false);
  const [isAddTimeSlotModalOpen, setIsAddTimeSlotModalOpen] = useState(false);

  const [touchedCell, setTouchedCell] = useTouchedCell();

  const actionsPopoverEl = useRef<any>();
  const openUpsertLessonModal = useAppStore(
    (state) => state.openUpsertLessonModal,
  );

  const isTouched = touchedCell === calendarHour;

  return (
    <>
      <Popover
        popover={
          <div>
            <Button
              type="outlined"
              color="primary"
              className={cs.action}
              onClick={() => {
                actionsPopoverEl.current.close();
                openUpsertLessonModal({ date: calendarHour.date });
              }}
            >
              + Lekcję
            </Button>
            <Button
              type="outlined"
              color="secondary"
              className={cs.action}
              onClick={() => {
                actionsPopoverEl.current.close();
                setClickedHour(calendarHour.date);
                setIsAddTimeSlotModalOpen(true);
              }}
            >
              + Dostępność
            </Button>
            <Button
              type="outlined"
              color="dark"
              className={cs.action}
              onClick={() => {
                actionsPopoverEl.current.close();
                setClickedHour(calendarHour.date);
                setIsNewRegularEventModalOpen(true);
              }}
            >
              + Wydarzenie
            </Button>
          </div>
        }
        position={["bottom"]}
        adjustWidth
        square
        className={cs.actionsPopover}
        ref={actionsPopoverEl}
      >
        <div className={`calendar-cell-clickable ${cs.clickableBg}`}>
          <div
            style={{ width: "100%", height: "100%" }}
            className={isTouched ? cs.touched : ""}
            onTouchEnd={(e) => {
              if (!isTouched) {
                e.stopPropagation();
                e.preventDefault();

                setTouchedCell(calendarHour);
                document.body.click();
              } else {
                setTouchedCell(null);
              }
            }}
          >
            {isTouched && "+"}
          </div>
        </div>
      </Popover>

      <UpsertRegularEventModal
        isOpen={isNewRegularEventModalOpen}
        regularEvent={{ date: clickedHour }}
        onClose={() => setIsNewRegularEventModalOpen(false)}
      />

      <UpsertTimeSlotModal
        isOpen={isAddTimeSlotModalOpen}
        timeSlot={{ date: clickedHour }}
        onClose={() => setIsAddTimeSlotModalOpen(false)}
      />
    </>
  );
};
