import { useMutation, useQueryClient } from "react-query";

import { RegularEventApi } from "@api/RegularEventApi";

export const useDeleteRegularEvent = () => {
  const queryClient = useQueryClient();

  return useMutation(RegularEventApi.deleteById, {
    onSuccess() {
      return queryClient.invalidateQueries({
        queryKey: ["calendar"],
      });
    },
  });
};
