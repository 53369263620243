import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";

import { AuthApi } from "@api/AuthApi";
import { Button } from "@components/Button";
import { Input } from "@components/Input";

import { GoogleAuthBtn } from "../components/GoogleAuthBtn";
import { OAuthSeparator } from "../components/OAuthSeparator";
import { Page } from "../components/Page";

import cs from "./LoginPage.module.scss";

export const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const passwordElement = useRef<HTMLInputElement>(null);

  return (
    <Page>
      <Page.Box>
        <header>
          <div className={cs.title}>Witaj</div>
          <div className={cs.subtitle}>Logujesz się jako korepetytor</div>
        </header>

        <form onSubmit={handleSubmit}>
          <Input
            value={email}
            label="Adres e-mail"
            type="email"
            required
            name="email"
            autocomplete="username"
            onChange={(value) => {
              setEmail(value);
              passwordElement.current?.setCustomValidity("");
            }}
          />
          <Input
            value={password}
            label="Hasło"
            type="password"
            required
            ref={passwordElement}
            name="password"
            autocomplete="current-password"
            onChange={(value) => {
              setPassword(value);
              passwordElement.current?.setCustomValidity("");
            }}
          />

          <div className={cs.forgotPassword}>
            <Link to="/remind-password" className="link">
              Zapomniałem hasła
            </Link>
          </div>

          <div className="f-center mt24">
            <Button
              color="secondary"
              className={cs.submitBtn}
              htmlType="submit"
              isLoading={isLoading}
            >
              Zaloguj się
            </Button>
          </div>
        </form>

        <OAuthSeparator />

        <GoogleAuthBtn type="login" />

        <div className={cs.noAccountText}>
          <span>Nie masz konta? </span>

          <Link to="/register" className="link">
            Zrejestruj się
          </Link>
        </div>
      </Page.Box>
    </Page>
  );

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    try {
      setIsLoading(true);

      await AuthApi.logIn({
        email,
        password,
      });

      window.location.href = "/";
    } catch (error: any) {
      setIsLoading(false);

      if (error.status === 401) {
        passwordElement.current?.setCustomValidity(
          "Hasło lub e-mail nie są prawidłowe",
        );
        passwordElement.current?.reportValidity();
      }
    }
  }
};
