import { useEffect, useState } from "react";
import { useQuery } from "react-query";

import { ParticipantApi } from "@api/ParticipantApi";
import { ParticipantKind } from "@constants/ParticipantType";
import { ParticipantType } from "@customTypes/ParticipantType";
import { useAppStore } from "@store";

export const useParticipants = () => {
  const [students, setStudents] = useState<ParticipantType[]>([]);

  const setParticipants = useAppStore((state) => state.setParticipants);

  const { data, isLoading } = useQuery(
    "participants",
    ParticipantApi.fetchAll,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      onSuccess(participants) {
        setParticipants(participants);
      },
    },
  );

  useEffect(() => {
    if (!data || data.length === 0) {
      setStudents([]);
      return;
    }

    setStudents(
      data
        .filter((participant) => participant.type === ParticipantKind.student)
        .sort((p1, p2) => {
          const studentA = p1.details.firstName.toUpperCase();
          const studentB = p2.details.firstName.toUpperCase();

          if (studentA < studentB) return -1;
          if (studentA > studentB) return 1;

          return 0;
        }),
    );
  }, [data]);

  return { data, students, isLoading };
};
