import { Chips } from "@components/Chips";
import { CompletedHomeworkType } from "@customTypes/CompletedHomeworkType";
import { ReactComponent as DownloadImg } from "@img/download.svg";
import { ReactComponent as HouseImg } from "@img/house_icon_new.svg";
import { downloadFile } from "@utils/utils";

import cs from "./LessonBox.module.scss";

type HomeworksProps = {
  homeworks: CompletedHomeworkType[];
};

export const Homeworks = ({ homeworks }: HomeworksProps) => {
  return (
    <Chips
      color="orange"
      className={cs.homeworksChips}
      title="Pobierz"
      onClick={(e) => {
        e.stopPropagation();

        homeworks.forEach((homework) => downloadFile(homework.url));
      }}
    >
      <HouseImg width={8} height={9} />

      <span className="ml4">{homeworks.length}</span>

      <button className={cs.downloadHomeworksBtn}>
        <DownloadImg height={9} className="ml4" />
      </button>
    </Chips>
  );
};
