import cs from "./Tabs.module.scss";

export type StudentCenterTabType =
  | "general"
  | "subscription"
  | "settings"
  | "contact";

type TabsProps = {
  activeTab: StudentCenterTabType;
  onChange: (tab: StudentCenterTabType) => void;
};

export const Tabs = ({ activeTab, onChange }: TabsProps) => {
  return (
    <header className={cs.root}>
      <div className={getCs("general")} onClick={() => onChange("general")}>
        Ogólne
      </div>
      <div className={getCs("contact")} onClick={() => onChange("contact")}>
        Kontakt
      </div>
      <div className={getCs("settings")} onClick={() => onChange("settings")}>
        Ustawienia
      </div>
    </header>
  );

  function getCs(tabKey: StudentCenterTabType) {
    if (activeTab === tabKey) return cs.tab__active;

    return cs.tab;
  }
};
