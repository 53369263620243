import { BrowserRouter, Route, Routes } from "react-router-dom";

import { LoginPage } from "@pages/auth/LoginPage";
import { NewPasswordPage } from "@pages/auth/NewPasswordPage";
import { RegisterPage } from "@pages/auth/RegisterPage";
import { ResetPasswordPage } from "@pages/auth/ResetPasswordPage";
import { CalendarPage } from "@pages/CalendarPage";
import { DashboardPage } from "@pages/DashboardPage";
import { DemoPage } from "@pages/DemoPage";
import { FinancesListPage } from "@pages/FinancesListPage";
import { LessonsListPage } from "@pages/LessonsListPage";
import { MaterialsPage } from "@pages/MaterialsPage";
import { MyStudentsPage } from "@pages/MyStudentsPage";
import { PaymentSucceededPage } from "@pages/PaymentSucceededPage";
import { PlansListPage } from "@pages/PlansListPage";
import { Settings } from "@pages/Settings";
import { TemplatesListPage } from "@pages/TemplatesListPage";

import { Layout } from "./Layout";
import { RequireAuth } from "./RequireAuth";

export const ApplicationRoutes = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/remind-password" element={<ResetPasswordPage />} />
      <Route path="/new-password/:token" element={<NewPasswordPage />} />
      <Route path="/demo" element={<DemoPage />} />

      <Route
        path="/"
        element={
          <RequireAuth>
            <Layout />
          </RequireAuth>
        }
      >
        <Route index element={<DashboardPage />} />
        <Route path="students" element={<MyStudentsPage />} />
        <Route path="templates" element={<TemplatesListPage />} />
        <Route path="calendar" element={<CalendarPage />} />
        <Route path="plans" element={<PlansListPage />} />
        <Route path="lessons" element={<LessonsListPage />} />
        <Route path="materials" element={<MaterialsPage />} />
        <Route path="finances" element={<FinancesListPage />} />
        <Route path="settings" element={<Settings />} />
        <Route path="payment-succeeded" element={<PaymentSucceededPage />} />
        <Route path="*" element={<div>Strona jeszcze nie istnieje</div>} />
      </Route>
    </Routes>
  </BrowserRouter>
);
