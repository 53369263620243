import { useState } from "react";
import { useQuery } from "react-query";

import { NotificationApi } from "../api/NotificationApi";

// TODO: Simplify - tutor never can fetch more than 40 notifications

export const useNotificationsByParticipant = (participantId: string = "") => {
  const [page, setPage] = useState(1);
  const [retrieveCount, setRetrieveCount] = useState(40);

  const { data, isLoading } = useQuery(
    ["notifications", retrieveCount, "participant", participantId],
    () => {
      return NotificationApi.fetchAll(retrieveCount, participantId);
    },
    {
      keepPreviousData: true,
      // refetchInterval: 60 * 1000,
      // refetchIntervalInBackground: true,
    },
  );

  const unseenCount =
    data?.data.filter((notification) => !notification.seen).length || 0;

  const displayed = data?.data.slice(0, page * 4) || [];

  // TODO: Gdy 40 unseen - trzeba counter pokazać jakoś 40+

  return {
    data: displayed,
    isLoading,
    hasMore: displayed.length < (data?.data.length || 0) || data?.hasMore,
    unseenCount,
    getNext,
  };

  function getNext() {
    const howManyShouldBeDisplayed = page * 4;
    const howManyDisplayed = displayed.length;

    if (
      howManyShouldBeDisplayed > howManyDisplayed ||
      howManyDisplayed === retrieveCount
    ) {
      setRetrieveCount((prev) => prev + 40);
      setPage((prevPage) => prevPage + 1);
    } else {
      setPage((prevPage) => prevPage + 1);
    }
  }
};
