import { ParticipantKind } from "../constants/ParticipantType";
import { ParticipantDtoType } from "../types/ParticipantDtoType";
import { ParticipantType } from "../types/ParticipantType";

import { StudentDtoService } from "./StudentDtoService";

export class ParticipantDtoService {
  static transformAll(participants: ParticipantDtoType[]) {
    return participants.map((participant) =>
      ParticipantDtoService.transform(participant),
    );
  }

  static transform(participant: ParticipantDtoType): ParticipantType {
    if (participant.type === ParticipantKind.student) {
      return {
        ...participant,
        details: StudentDtoService.transform(participant.details),
      };
    }

    return participant as ParticipantType;
  }
}
