import { NotificationKind } from "@constants/NotificationKind";

import applicationImg from "./img/application.svg";
import cancelledImg from "./img/cancelled.svg";
import dollarImg from "./img/dollar.svg";
import houseImg from "./img/house.svg";
import messageImg from "./img/message.svg";

export const NotificationIcon = ({ type }: { type: NotificationKind }) => {
  switch (type) {
    case NotificationKind.LessonCancelled:
      return <img src={cancelledImg} alt="" />;
    case NotificationKind.HomeworkUpdated:
      return <img src={houseImg} alt="" />;
    case NotificationKind.LessonPaid:
      return <img src={dollarImg} alt="" height={16} />;
    case NotificationKind.Custom:
      return <img src={messageImg} alt="" height={16} />;
    case NotificationKind.NewTimeSlotApplication:
      return <img src={applicationImg} alt="" height={14} />;
    default:
      throw new Error("Unknown notification type: " + type);
  }
};
