import { Dropdown } from "@components/Dropdown";
import { Input } from "@components/Input";
import { ReactComponent as DropdownArrowImg } from "@img/dropdown_arrow.svg";

import cs from "./Select.module.scss";

type SelectProps = {
  options: any[];
  value: any;
  label?: string;
  className?: string;
  required?: boolean;
  onChange: any;
};

export const Select = ({
  value,
  label,
  className,
  options,
  required,
  onChange,
}: SelectProps) => {
  return (
    <Dropdown
      className={cs.dropdown}
      options={options.map((option) => (
        <div className={cs.option} onClick={() => onChange(option)}>
          {option.label}
        </div>
      ))}
      adjustWidth
    >
      <div className={`${cs.root} ${className}`}>
        <Input
          value={value}
          label={label}
          readonly
          required={required}
          onChange={() => {}}
        />

        <DropdownArrowImg width={8} className={cs.svg} />
      </div>
    </Dropdown>
  );
};
