import { useLocation } from "react-router-dom";

import { AccountBlockedBanner } from "@components/AppBar/AccountBlockedBanner";
import { UserTourService } from "@services/UserTourService";

import { Account } from "./Account";
import { DemoUserBanner } from "./DemoUserBanner";
import { MobileMenu } from "./MobileMenu";
import { StudentsSearch } from "./StudentsSearch";

import cs from "./AppBar.module.scss";

export const AppBar = () => {
  const location = useLocation();

  const pageName = getPageName(location.pathname);

  return (
    <div>
      <header className={cs.root}>
        <div className="f-center">
          <div className={cs.pageName}>{pageName}</div>

          {pageName === "Dashboard" && (
            <button
              className={`launch-user-tour ${cs.userTour}`}
              title="Pokaż samouczek"
              onClick={() => {
                const userTourService = new UserTourService();

                userTourService.start("first-visit");
              }}
            >
              ?
            </button>
          )}
        </div>

        <StudentsSearch />

        <div className={cs.actions}>
          <Account />

          <MobileMenu />
        </div>
      </header>

      <DemoUserBanner />
      <AccountBlockedBanner />
    </div>
  );

  function getPageName(urlPath: string) {
    // TODO:
    switch (urlPath) {
      case "/":
        return "Dashboard";
      case "/templates":
        return "Szablony lekcji";
      case "/calendar":
        return "Kalendarz";
      case "/plans":
        return "Kursy";
      case "/students":
        return "Lista studentów";
      case "/lessons":
        return "Lista lekcji";
      case "/finances":
        return "Finanse";
      case "/materials":
        return "Materiały";
      case "/settings":
        return "Ustawienia";
      default:
        return "Test";
    }
  }
};
