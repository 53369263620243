import React, { useEffect, useRef, useState } from "react";

import { AccountApi } from "@api/AccountApi";
import { Button } from "@components/Button";
import { DatePicker } from "@components/DatePicker";
import { InfoBox } from "@components/InfoBox";
import { Input } from "@components/Input";
import { PaymentLogic } from "@components/PaymentLogic";
import { Select } from "@components/Select";
import {
  calendarViewOptions,
  CalendarViewOptionType,
  CalendarViewType,
} from "@constants/CalendarViewOptions";
import { PaymentLogicType } from "@customTypes/PaymentLogicType";
import { useCurrentUser } from "@hooks/useCurrentUser";
import { useReplaceCurrentUser } from "@hooks/useReplaceCurrentUser";
import { ToastService } from "@services/ToastService";

import { queryClient } from "../../../index";

import cs from "@pages/Settings/Settings.module.scss";

export const TabGeneral = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [paymentLogic, setPaymentLogic] = useState<PaymentLogicType>(
    {} as PaymentLogicType,
  );
  const [start, setStart] = useState<Date | null>(null);
  const [end, setEnd] = useState<Date | null>(null);
  const [defaultCalendarView, setDefaultCalendarView] =
    useState<CalendarViewOptionType>();

  const endTimeInput = useRef<any>(null);

  const { currentUser } = useCurrentUser();
  const replaceCurrentUser = useReplaceCurrentUser();
  const replaceCurrentUser2 = useReplaceCurrentUser();

  const setPaymentLogicTimer = useRef<any>(null);

  useEffect(() => {
    if (currentUser) {
      setFirstName(currentUser.firstName);
      setLastName(currentUser.lastName);
      setPhone(currentUser.phone || "");
      setPaymentLogic(currentUser.paymentLogic || ({} as PaymentLogicType));

      const workStartHour = currentUser.settingWorkStartHour;
      const workEndHour = currentUser.settingWorkEndHour;

      setStart(initHour(workStartHour));
      setEnd(initHour(workEndHour));
      setDefaultCalendarView(
        calendarViewOptions.find(
          (option) => option.value === currentUser.settingCalendarDefaultView,
        ),
      );
    }
  }, [currentUser]);

  return (
    <div className={cs.generalTab}>
      <section className={cs.box}>
        <header className="mb12">
          <span className={cs.subheader}>Profil</span>
        </header>

        <Input
          value={currentUser?.email || ""}
          label="Adres e-mail"
          type="email"
          disabled
        />

        <form className="mt32" onSubmit={handleSubmit}>
          <Input
            value={firstName}
            label="Imię"
            required
            onChange={setFirstName}
          />
          <Input
            value={lastName}
            label="Nazwisko"
            required
            onChange={setLastName}
          />
          <Input
            value={phone}
            label="Numer telefonu"
            type="tel"
            pattern="(?<!\w)(\(?(\+|00)?48\)?)?[ -]?\d{3}[ -]?\d{3}[ -]?\d{3}(?!\w)"
            onChange={setPhone}
          />

          <Input value="Polski" label="Język" disabled />
          <Input value="PLN" label="Waluta" disabled />

          <div className="f-center mt32">
            <Button
              color="secondary"
              className={cs.submitBtn}
              htmlType="submit"
              isLoading={replaceCurrentUser.isLoading}
            >
              Aktualizuj dane
            </Button>
          </div>
        </form>
      </section>

      <section className={cs.box}>
        <div>
          <header>
            <span className={cs.subheader}>Najczęstszy sposób rozliczania</span>

            <InfoBox
              desc="Domyślne ustawienie dla wszystkich studentów. Możesz ustawić indywidualną logikę rozliczania dla konkretnego studenta wchodzą w jego Centrum studenta > Ustawienia."
              className="ml6"
            />
          </header>

          <PaymentLogic
            paymentLogic={paymentLogic}
            onChange={handlePaymentLogicChanged}
          />
        </div>
      </section>

      <section className={cs.box}>
        <div>
          <header className="mb12">
            <span className={cs.subheader}>Ustawienia kalendarza</span>
          </header>

          <Select
            options={calendarViewOptions}
            value={defaultCalendarView?.label}
            label="Widok domyślny"
            required
            onChange={async (option: CalendarViewOptionType) => {
              setDefaultCalendarView(option);
              await handleCalendarSettingsChanged(option.value);
            }}
          />
        </div>

        <div className="mt32">
          <header className="mb12">
            <span className={cs.subheader}>Godziny pracy</span>
          </header>

          <DatePicker
            value={start}
            mode="time"
            label="Godzina rozpoczęcia"
            timeIntervals={60}
            required
            onChange={(value) => {
              setStart(value);
              endTimeInput.current.setCustomValidity("");

              handleWorkingHoursChanged(value, end!);
            }}
          />

          <div className="mt8">
            <DatePicker
              value={end}
              mode="time"
              label="Godzina zakończenia"
              timeIntervals={60}
              required
              ref={endTimeInput}
              onChange={(value) => {
                setEnd(value);
                endTimeInput.current.setCustomValidity("");

                handleWorkingHoursChanged(start!, value);
              }}
            />
          </div>
        </div>
      </section>
    </div>
  );

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    await replaceCurrentUser.mutateAsync({
      currentUser: {
        ...currentUser,
        firstName,
        lastName,
        phone,
      },
    });

    ToastService.success("Zmiany zostały zapisane.");
  }

  async function handlePaymentLogicChanged(paymentLogic: PaymentLogicType) {
    setPaymentLogic(paymentLogic);

    if (setPaymentLogicTimer.current) {
      clearTimeout(setPaymentLogicTimer.current);
    }

    setPaymentLogicTimer.current = setTimeout(async () => {
      await AccountApi.updatePaymentLogic({
        paymentLogic,
      });

      ToastService.success("Zmiany zostały zapisane.");

      await queryClient.invalidateQueries({
        queryKey: ["user"],
        refetchInactive: true,
      });

      // NOTE: Refresh default value for those without individual payment logic
      await queryClient.invalidateQueries({
        queryKey: ["participants"],
        refetchInactive: true,
      });
    }, 2000);
  }

  async function handleWorkingHoursChanged(start: Date, end: Date) {
    if (start && end) {
      const startNb = start.valueOf();
      const endNb = end.valueOf();

      if (endNb <= startNb) {
        endTimeInput.current.setCustomValidity(
          "Godzina zakończenia nie może być mniejsza niż godzina rozpoczęcia.",
        );
        endTimeInput.current.reportValidity();

        return;
      }
    }

    await replaceCurrentUser2.mutateAsync({
      currentUser: {
        ...currentUser,
        settingWorkStartHour: start?.getHours(),
        settingWorkEndHour: end?.getHours(),
      },
    });

    ToastService.success("Zmiany zostały zapisane.");
  }

  async function handleCalendarSettingsChanged(value: CalendarViewType) {
    await replaceCurrentUser2.mutateAsync({
      currentUser: {
        ...currentUser,
        settingCalendarDefaultView: value,
      },
    });

    ToastService.success("Zmiany zostały zapisane.");
  }
};

function initHour(hour: number) {
  return new Date(new Date().setHours(hour, 0, 0, 0));
}
