import { LessonPaymentStatus } from "@constants/LessonPaymentStatus";
import { ArrearDtoType } from "@customTypes/ArrearDtoType";
import { ArrearType } from "@customTypes/ArrearType";
import { LessonDtoType } from "@customTypes/LessonDtoType";
import { StudentDtoService } from "@services/StudentDtoService";

const NOW = new Date();

export class ArrearDtoService {
  static transform(
    arrearDto: ArrearDtoType,
    relatedLesson: LessonDtoType,
  ): ArrearType {
    return {
      ...arrearDto,
      isAfterDeadline: ArrearDtoService.calcIsAfterDeadline(
        arrearDto.status,
        relatedLesson.paymentDeadline,
      ),
      students: StudentDtoService.transformAll(arrearDto.students),
    };
  }

  static calcIsAfterDeadline(
    status: LessonPaymentStatus,
    paymentDeadline: Date | null,
  ) {
    return (
      status === LessonPaymentStatus.AWAITS &&
      !!paymentDeadline &&
      paymentDeadline < NOW
    );
  }
}
