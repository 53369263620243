import { useEffect, useRef, useState } from "react";
import { useQueryClient } from "react-query";

import { TemplatesApi } from "@api/TemplatesApi";
import { AssignedMaterialsManager } from "@components/AssignedMaterialsManager";
import { Button } from "@components/Button";
import { Input } from "@components/Input";
import { Modal } from "@components/Modal";
import { RichTextEditor } from "@components/RichTextEditor";
import { AssignedMaterialType } from "@customTypes/AssignedMaterialType";
import { TemplateType } from "@customTypes/TemplateType";

import cs from "./UpsertTemplateModal.module.scss";

type UpsertTemplateModalProps = {
  isOpen: boolean;
  template?: TemplateType | null;
  onClose: () => void;
  onAfterUpsert?: (newTemplate: TemplateType) => void;
};

export const UpsertTemplateModal = ({
  isOpen,
  template,
  onClose,
  onAfterUpsert,
}: UpsertTemplateModalProps) => {
  const [name, setName] = useState("");
  const [topic, setTopic] = useState("");
  const [noteForStudent, setNoteForStudent] = useState("");
  const [materials, setMaterials] = useState<AssignedMaterialType[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const formElement = useRef<HTMLFormElement>(null);

  const queryClient = useQueryClient();

  useEffect(() => {
    if (isOpen && template) {
      template.topic && setTopic(template.topic);
      template.noteForStudent && setNoteForStudent(template.noteForStudent);
      template.materials && setMaterials(template.materials);
    }
  }, [template, isOpen]);

  const mode = template ? "edit" : "new";

  return (
    <Modal
      title={mode === "new" ? "Dodaj szablon" : "Edytuj szablon"}
      isOpen={isOpen}
      className={cs.modal}
      helpTooltip="Czym są Szablony lekcji?"
      help={`Szablon lekcji posiada częściowo uzupełnione pola tworzenia lekcji np. temat, materiały czy zadania domowe. Jeden szablon możesz wykorzystywać wielokrotnie, importując go podczas tworzenia lekcji, a następnie, w miarę potrzeby, dostosowując odpowiednio przez kliknięciem Dodaj lekcję. Każda stworzona przez Ciebie lekcja z oryginalnym, niepowtarzalnym tematem automatycznie zapisywana jest w sekcji Szablony, aby usprawnić tworzenie przez Ciebie lekcji w przyszłości. Szablon możesz także utworzyć ręcznie.`}
      footer={
        <Button color="primary" isLoading={isLoading} onClick={onConfirm}>
          <span>{mode === "new" ? "Dodaj" : "Zapisz"}</span>
        </Button>
      }
      onClose={onClose}
    >
      <form ref={formElement}>
        <section className={cs.section}>
          <Input label="Temat lekcji" value={topic} onChange={setTopic} />

          <RichTextEditor
            label="Opis lekcji"
            value={noteForStudent}
            onChange={setNoteForStudent}
          />
        </section>

        <section className={cs.materialsSection}>
          <AssignedMaterialsManager
            materials={materials}
            onChange={setMaterials}
          />
        </section>
      </form>
    </Modal>
  );

  async function onConfirm() {
    const isValid = formElement.current?.reportValidity();

    if (!isValid) return;

    setIsLoading(true);

    try {
      let returnedItem;

      if (mode === "new") {
        returnedItem = await TemplatesApi.create({
          name,
          topic,
          noteForStudent,
          materials,
        });
      } else if (template) {
        returnedItem = {
          ...template,
          name,
          topic,
          noteForStudent,
          materials,
        };

        await TemplatesApi.replace(returnedItem);
      }

      await queryClient.invalidateQueries({
        queryKey: ["templates"],
        refetchInactive: true,
      });
      await queryClient.invalidateQueries({
        queryKey: ["plans"],
        refetchInactive: true,
      });

      onAfterUpsert?.(returnedItem);

      onClose();
      clear();
    } finally {
      setIsLoading(false);
    }
  }

  function clear() {
    setName("");
    setTopic("");
    setMaterials([]);
    setNoteForStudent("");
  }
};
