import { useMutation, useQueryClient } from "react-query";

import { StudentApi } from "../api/StudentApi";

export const useDeleteStudent = () => {
  const queryClient = useQueryClient();

  return useMutation("students", StudentApi.deleteById, {
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["students"],
        refetchInactive: true,
      });

      await queryClient.invalidateQueries({
        queryKey: ["participants"],
        refetchInactive: true,
      });
    },
  });
};
