import { create } from "zustand";
import { devtools } from "zustand/middleware";

import { StudentCenterTabType } from "@components/StudentCenterModal/Tabs";
import { CurrentUserType } from "@customTypes/CurrentUserType";
import { LessonType } from "@customTypes/LessonType";
import { ParticipantType } from "@customTypes/ParticipantType";

type UpsertLessonModal = {
  upsertLessonModal: {
    isOpen: boolean;
    lesson?: Partial<LessonType>;
    onAfter?: () => void;
  };
  closeUpsertLessonModal: () => void;
  openUpsertLessonModal: (
    lesson?: Partial<LessonType>,
    onAfter?: () => void,
  ) => void;
};

type StudentCenterModal = {
  studentCenterModal: {
    isOpen: boolean;
    participantId: string | null;
    tab?: StudentCenterTabType;
  };
  closeStudentCenterModal: () => void;
  openStudentCenterModal: (
    participantId: string | null,
    tab?: StudentCenterTabType,
  ) => void;
};

type ConfirmationModal = {
  confirmationModal: {
    isOpen: boolean;
    text?: string;
    confirmText?: string;
    onConfirm?: () => void;
    onCancel?: () => void;
  };
  closeConfirmationModal: () => void;
  openConfirmationModal: ({
    text,
    confirmText,
  }: {
    text?: string;
    confirmText?: string;
  }) => Promise<boolean>;
};

type Store = ConfirmationModal &
  UpsertLessonModal &
  StudentCenterModal & {
    participants: ParticipantType[];
    setParticipants: (participants: ParticipantType[]) => void;
  } & {
    currentUser: CurrentUserType | null;
    setCurrentUser: (currentUser: CurrentUserType | null) => void;
  };

const useAppStore = create<Store>()(
  devtools((set, get) => ({
    // CURRENT USER
    currentUser: null,
    setCurrentUser: (currentUser) =>
      set({ currentUser }, false, "setCurrentUser"),
    // END - CURRENT USER

    // PARTICIPANTS
    participants: [],
    setParticipants: (participants) =>
      set({ participants }, false, "setParticipants"),
    // END - PARTICIPANTS

    // UPSERT LESSON MODAL
    upsertLessonModal: {
      isOpen: false,
    },
    closeUpsertLessonModal: () =>
      set(
        { upsertLessonModal: { isOpen: false } },
        false,
        "closeUpsertLessonModal",
      ),
    openUpsertLessonModal: (lesson?: Partial<LessonType>, onAfter?) =>
      set(
        { upsertLessonModal: { isOpen: true, lesson, onAfter } },
        false,
        "openUpsertLessonModal",
      ),
    // END - UPSERT LESSON MODAL

    // STUDENT CENTER MODAL
    studentCenterModal: {
      isOpen: false,
      participantId: null,
    },
    closeStudentCenterModal: () =>
      set(
        { studentCenterModal: { isOpen: false, participantId: null } },
        false,
        "closeStudentCenterModal",
      ),
    openStudentCenterModal: (participantId, tab) =>
      set(
        { studentCenterModal: { isOpen: true, participantId, tab } },
        false,
        "openStudentCenterModal",
      ),
    // END - STUDENT CENTER MODAL

    // CONFIRMATION MODAL
    confirmationModal: {
      isOpen: false,
    },
    closeConfirmationModal: () => {
      // NOTE: For better visual effect - keep labels
      //       the same until closed.
      set(
        (prev) => {
          return {
            confirmationModal: {
              ...prev.confirmationModal,
              isOpen: false,
            },
          };
        },
        false,
        "closeConfirmationModal1",
      );

      setTimeout(() => {
        set(
          { confirmationModal: { isOpen: false } },
          false,
          "closeConfirmationModal2",
        );
      }, 400);
    },
    openConfirmationModal: ({ text, confirmText } = {}) => {
      return new Promise((resolve) => {
        set(
          {
            confirmationModal: {
              isOpen: true,
              text,
              confirmText,
              onConfirm: () => {
                resolve(true);
                get().closeConfirmationModal();
              },
              onCancel: () => {
                resolve(false);
                get().closeConfirmationModal();
              },
            },
          },
          false,
          "openConfirmationModal",
        );
      });
    },
    // END - CONFIRMATION MODAL
  })),
);

const { getState } = useAppStore;

export { useAppStore, getState };
