import { LessonDtoType } from "@customTypes/LessonDtoType";
import { PaginationType } from "@customTypes/PaginationType";
import { LessonDtoService } from "@services/LessonDtoService";
import { filtersToUrlParams, paginationToUrlParams } from "@utils/api.utils";
import { getUserTimeZone } from "@utils/date.utils";

import { createAxiosInstance } from "./_createAxiosInstance";

const api = createAxiosInstance("/api/lesson");

type CreateParams = {
  lesson: Omit<LessonDtoType, "_id" | "arrears" | "completedHomeworks">;
  repeat?: number;
};

type CreateFromTemplatesParams = {
  templateIds: string[];
  participantId: string;
};

type UpdateParams = {
  lessonId: string;
  updatedFields: Partial<LessonDtoType>;
};

export class LessonApi {
  static async fetchAll(filters: any, sort: any, pagination: PaginationType) {
    const { data } = await api.get("", {
      params: {
        ...filtersToUrlParams(filters),
        sort,
        ...paginationToUrlParams(pagination),
      },
    });

    return LessonDtoService.transformAll(data);
  }

  static async create({ lesson, repeat }: CreateParams) {
    return api.post("", lesson, {
      params: {
        repeat,
        tz: getUserTimeZone(),
      },
    });
  }

  static async createFromTemplates({
    templateIds,
    participantId,
  }: CreateFromTemplatesParams) {
    return api.post("/from-templates", { templateIds, participantId });
  }

  static async update({ lessonId, updatedFields }: UpdateParams) {
    return api.patch(`/${lessonId}`, updatedFields);
  }

  static async deleteById({ lessonId }: { lessonId: string }) {
    return api.delete(`/${lessonId}`);
  }

  static async fetchStudyProgress(params: any) {
    const { data } = await api.get("/progress", {
      params,
    });

    return LessonDtoService.transformAll(data);
  }
}
