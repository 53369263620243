import React from "react";
import { Navigate } from "react-router-dom";

import { useAppStore } from "@store";

import { ReactComponent as LogoImg } from "./img/logo.svg";
import { Box } from "./Box";

import cs from "./Page.module.scss";

type PageProps = {
  children: React.ReactNode;
};

export const Page = ({ children }: PageProps) => {
  const currentUser = useAppStore((state) => state.currentUser);

  if (currentUser) {
    return <Navigate to="/" replace />;
  }

  return (
    <main className={cs.root}>
      <LogoImg width={200} />

      {children}
    </main>
  );
};

Page.Box = Box;
