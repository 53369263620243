import { Button } from "@components/Button";
import { ReactComponent as EmailImg } from "@img/email.svg";

import cs from "./StudentCenterModal.module.scss";

export const NoAccountPopoverContent = ({
  onInvite,
}: {
  onInvite: () => void;
}) => (
  <div>
    <div className={cs.noAccountPopover_header}>
      Uczeń nie posiada konta w aplikacji
    </div>

    <p>
      Każdy z Twoich uczniów może, choć nie musi, posiadać darmowe konto w
      aplikacji. Konto ucznia umożliwia mu dostęp do swojego kalendarza lekcji,
      otrzymywanie i dostarczanie zadań domowych, dostęp do historii spotkań
      oraz notatek, a także umożliwia proponowanie lekcji, jeśli korepetytor
      utworzy Dostępność w swoim kalendarzu.
    </p>

    <div className={cs.noAccountPopover_actions}>
      <Button color="primary" onClick={onInvite}>
        <EmailImg width={12} />
        <span>Wyślij zaproszenie</span>
      </Button>
    </div>
  </div>
);
