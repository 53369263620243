import { useState } from "react";

import produce from "immer";

import { MaterialsModal } from "@components/MaterialsModal";
import { AssignedMaterialType } from "@customTypes/AssignedMaterialType";
import { ReactComponent as PlusImg } from "@img/plus.svg";
import { deleteById, findById } from "@utils/utils";

import { Material } from "./Material";

import cs from "./AssignedMaterialsManager.module.scss";

// TODO: Add files directly - check Figma

type AssignedMaterialsManagerProps = {
  materials: AssignedMaterialType[];
  onChange: (updatedMaterials: AssignedMaterialType[]) => void;
};

export const AssignedMaterialsManager = ({
  materials,
  onChange,
}: AssignedMaterialsManagerProps) => {
  const [isMaterialsModelOpen, setIsMaterialsModelOpen] = useState(false);

  return (
    <div>
      <div className={cs.header}>
        <span>Materiały</span>

        <button
          className={cs.action}
          type="button"
          title="Wybierz materiały"
          onClick={() => setIsMaterialsModelOpen(true)}
        >
          <PlusImg width={10} />
        </button>
      </div>

      <div className={cs.list}>
        {materials.map((material) => (
          <Material
            key={material._id}
            material={material}
            onDelete={handleMaterialDelete}
            onToggleHomework={handleToggleHomework}
          />
        ))}

        {materials.length === 0 && (
          <div className={cs.noMaterialsInfo}>
            Nie wybrano żadnych materiałów...
          </div>
        )}
      </div>

      <MaterialsModal
        isOpen={isMaterialsModelOpen}
        materials={materials}
        onClose={() => setIsMaterialsModelOpen(false)}
        onConfirm={(selectedMaterials) => {
          onChange(
            selectedMaterials.map((material) => ({
              ...material,
              isHomework: false,
            })),
          );
        }}
      />
    </div>
  );

  function handleMaterialDelete(materialId: string) {
    onChange(deleteById(materialId, materials));
  }

  function handleToggleHomework(materialId: string) {
    onChange(
      produce(materials, (draft) => {
        const material = findById(materialId, draft);

        if (material) {
          material.isHomework = !material.isHomework;
        }
      }),
    );
  }
};
