export type CalendarViewType = "workweek" | "week" | "month";

export type CalendarViewOptionType = {
  value: CalendarViewType;
  label: string;
};

export const calendarViewOptions: CalendarViewOptionType[] = [
  {
    value: "workweek",
    label: "Tydzień roboczy",
  },
  {
    value: "week",
    label: "Tydzień",
  },
  {
    value: "month",
    label: "Miesiąc",
  },
];

export const defaultCalendarView = calendarViewOptions[0].value;
