import cs from "./OAuthSeparator.module.scss";

export const OAuthSeparator = () => {
  return (
    <div className={cs.root}>
      <div className={cs.line} />
      <span>lub</span>
      <div className={cs.line} />
    </div>
  );
};
