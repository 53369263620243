import { useEffect } from "react";

import Smartlook from "smartlook-client";

import { CurrentUserType } from "@customTypes/CurrentUserType";

export const useSmartlook = (user?: CurrentUserType) => {
  useEffect(() => {
    if (!user) return;
    if (!process.env.REACT_APP_SMARTLOOK_KEY) return;

    Smartlook.init(process.env.REACT_APP_SMARTLOOK_KEY);
    Smartlook.record({ api: true, emails: true, forms: true, numbers: true });
    Smartlook.identify(user._id, {
      name: user.firstName + " " + user.lastName,
      email: user.email,
    });
  }, [user]);
};
