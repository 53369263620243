import { Chips } from "@components/Chips";
import { formatDate, isPast } from "@utils/date.utils";

export const columns = [
  {
    title: "Okres rozliczeniowy",
    render(__: any, _: any, bill: any) {
      return (
        <div style={{ whiteSpace: "break-spaces" }}>
          <span>{formatDate(bill.periodStart)}</span>
          <span> - </span>
          <span>{formatDate(bill.periodEnd)}</span>
        </div>
      );
    },
  },
  {
    title: "Cena",
    field: "price",
    width: 170,
    center: true,
    render(price: any, _: any, bill: any) {
      if (!price) {
        return <Chips color="primary">Okres darmowy</Chips>;
      }

      if (bill.paidAt) {
        return <Chips color="primary">{price} zł</Chips>;
      }

      return <Chips color="neutral">{price} zł</Chips>;
    },
  },
  {
    title: "Data opłacenia",
    render(__: any, _: any, bill: any) {
      if (!bill.price) {
        return <div>-</div>;
      }

      if (!bill.paidAt) {
        const isOngoingPeriodNotPaid = isPast(bill.periodStart);

        return (
          <div
            style={{ whiteSpace: "break-spaces" }}
            className={isOngoingPeriodNotPaid ? "red semi-bold" : ""}
          >
            brak płatności
          </div>
        );
      }

      return <div>{formatDate(bill.paidAt)}</div>;
    },
  },
];
