import { PaymentLogicType } from "@customTypes/PaymentLogicType";
import { StudentDtoType } from "@customTypes/StudentDtoType";
import { StudentType } from "@customTypes/StudentType";
import { StudentDtoService } from "@services/StudentDtoService";
import { omitEmptyStrings } from "@utils/utils";

import { createAxiosInstance } from "./_createAxiosInstance";

const api = createAxiosInstance("/api/student");

type CreatParams = {
  student: Partial<StudentDtoType>;
  sendInvitation: boolean;
};

type ReplaceParams = {
  student: Pick<StudentType, "_id" | "firstName" | "lastName" | "note">;
};

type DeleteByIdParams = {
  studentId: string;
};

type UpdatePaymentLogicParams = {
  studentId: string;
  paymentLogic: PaymentLogicType;
};

export class StudentApi {
  static async fetchAll() {
    const { data } = await api.get<StudentDtoType[]>("");

    return StudentDtoService.transformAll(data);
  }

  static async fetchById(studentId: string) {
    const { data } = await api.get<StudentDtoType>(`/${studentId}`);

    return StudentDtoService.transform(data);
  }

  static async create({ student, sendInvitation }: CreatParams) {
    return await api.post("", omitEmptyStrings(student), {
      params: { sendInvitation },
    });
  }

  static async replace({ student }: ReplaceParams) {
    return api.put("", omitEmptyStrings(student));
  }

  static async deleteById({ studentId }: DeleteByIdParams) {
    return api.delete(`/${studentId}`);
  }

  static async updatePaymentLogic({
    studentId,
    paymentLogic,
  }: UpdatePaymentLogicParams) {
    return api.put(`/${studentId}/payment-logic`, paymentLogic);
  }
}
