import { ParticipantType } from "@customTypes/ParticipantType";
import { ReactComponent as NewLessonImg } from "@img/new_lesson.svg";

import cs from "./StudentsSearch.module.scss";

type SearchPopoverContentProps = {
  students: ParticipantType[];
  onNewLesson: (item: ParticipantType) => void;
  onSelect: (item: ParticipantType) => void;
};

export const StudentsSearchContent = ({
  students,
  onNewLesson,
  onSelect,
}: SearchPopoverContentProps) => {
  return (
    <div className={cs.list}>
      {students.map((participant) => {
        const { details } = participant;

        return (
          <div
            key={participant._id}
            className={cs.item}
            onClick={() => onSelect(participant)}
          >
            <span>{details.name}</span>

            <div className={cs.actions}>
              <button
                className={cs.newLessonBtn}
                onClick={(e) => {
                  e.stopPropagation();

                  onNewLesson(participant);
                }}
              >
                <NewLessonImg width={11} />
              </button>
            </div>
          </div>
        );
      })}
    </div>
  );
};
