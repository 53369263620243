import { useMutation, useQueryClient } from "react-query";

import { RegularEventApi } from "../api/RegularEventApi";

export const useReplaceRegularEvent = () => {
  const queryClient = useQueryClient();

  // TODO: Needs optimalization. Doesn't make sense to fetch all calendar events
  //       if only one was updated. Maybe optimistic update is enought here
  //       and there's no need to invalidate queries
  return useMutation("students", RegularEventApi.replace, {
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["calendar"],
      });
    },
  });
};
