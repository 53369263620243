import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";

import { AuthApi } from "@api/AuthApi";
import { Button } from "@components/Button";
import { Input } from "@components/Input";

import { GoogleAuthBtn } from "../components/GoogleAuthBtn";
import { OAuthSeparator } from "../components/OAuthSeparator";
import { Page } from "../components/Page";

import cs from "./RegisterPage.module.scss";

export const RegisterPage = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [subjects, setSubjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const confirmPasswordElement = useRef<HTMLInputElement>(null);
  const emailElement = useRef<HTMLInputElement>(null);

  // TODO: Later
  // const { data: availableSubjects = [] } = useAvailableSubjects();

  return (
    <Page>
      <Page.Box>
        <header>
          <div className={cs.title}>Witaj</div>
          <div className={cs.subtitle}>Rejestrujesz się jako korepetytor</div>
        </header>

        <form onSubmit={handleSubmit}>
          <Input
            value={firstName}
            label="Imię"
            required
            autocomplete="given-name"
            name="fname"
            onChange={setFirstName}
          />
          <Input
            value={lastName}
            label="Nazwisko"
            required
            autocomplete="family-name"
            name="lname"
            onChange={setLastName}
          />
          <Input
            value={email}
            label="Adres e-mail"
            type="email"
            required
            ref={emailElement}
            autocomplete="email"
            name="email"
            onChange={setEmail}
          />
          {/*<Search*/}
          {/*  label="Nauczane przedmioty"*/}
          {/*  placeholder=""*/}
          {/*  items={availableSubjects}*/}
          {/*  selected={subjects}*/}
          {/*  multi*/}
          {/*  onChange={setSubjects}*/}
          {/*/>*/}
          <Input
            value={password}
            label="Hasło"
            type="password"
            required
            autocomplete="new-password"
            name="password"
            onChange={setPassword}
          />
          <Input
            value={passwordConfirm}
            label="Potwierdź hasło"
            type="password"
            required
            ref={confirmPasswordElement}
            autocomplete="new-password"
            name="confirm-password"
            onChange={setPasswordConfirm}
          />

          <div className="f-center mt24">
            <Button
              color="secondary"
              className={cs.submitBtn}
              htmlType="submit"
              isLoading={isLoading}
            >
              Utwórz konto
            </Button>
          </div>
        </form>

        <OAuthSeparator />

        <GoogleAuthBtn type="register" />

        <div className={cs.existingAccountText}>
          <span>Posiadasz już konto? </span>

          <Link to="/login" className="link">
            Zaloguj się
          </Link>
        </div>
      </Page.Box>
    </Page>
  );

  async function handleSubmit(e: React.MouseEvent<HTMLFormElement>) {
    e.preventDefault();

    if (!validatePassword()) {
      return;
    }

    try {
      setIsLoading(true);

      await AuthApi.register({
        firstName,
        lastName,
        email,
        password,
      });

      window.location.href = "/";
    } catch (error: any) {
      setIsLoading(false);

      if (error.message === "USER_ALREADY_EXISTS") {
        emailElement.current?.setCustomValidity(
          "Istnieje już użytkownik o tym adresie e-mail.",
        );
        emailElement.current?.reportValidity();
      }
    }
  }

  function validatePassword() {
    if (password !== passwordConfirm) {
      confirmPasswordElement.current?.setCustomValidity(
        "Hasła się nie zgadzają",
      );

      return false;
    }

    confirmPasswordElement.current?.setCustomValidity("");
    return true;
  }
};
