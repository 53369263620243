import React, { forwardRef } from "react";
import DatePickerBase, {
  registerLocale,
  setDefaultLocale,
} from "react-datepicker";

import pl from "date-fns/locale/pl";

import { Input } from "@components/Input";
import { ReactComponent as CalendarImg } from "@img/calendar_2.svg";
import { isSmallScreen } from "@utils/utils";

import "react-datepicker/dist/react-datepicker.css";
import "./DatePicker.global.scss";
import cs from "./DatePicker.module.scss";

registerLocale("pl", pl);
setDefaultLocale("pl");

const InputWrapper = forwardRef(
  ({ value, label, inputRef, required, onClick }: any, ref: any) => (
    <span ref={ref} className={cs.InputWrapper}>
      <span className={cs.img}>
        <CalendarImg width={16} height={15} onClick={onClick} />
      </span>

      <Input
        value={value}
        label={label}
        className={cs.input}
        required={required}
        onChange={() => {}}
        ref={inputRef}
        readonly
        onClick={onClick}
      />
    </span>
  ),
);

type DatePickerProps = {
  value?: Date | null;
  label?: string;
  mode?: "date" | "datetime" | "time";
  required?: boolean;
  timeIntervals?: number;
  isClearable?: boolean;
  asModal?: boolean;
  minTime?: Date;
  maxTime?: Date;
  onChange: (date: Date) => void;
  onCalendarClose?: () => void;
};

const DEFAULT_MIN_HOUR = new Date(new Date().setHours(6, 0, 0, 0));
const DEFAULT_MAX_HOUR = new Date(new Date().setHours(23, 0, 0, 0));

export const DatePicker = forwardRef(
  (
    {
      value,
      label,
      mode,
      required,
      timeIntervals,
      isClearable,
      asModal,
      minTime,
      maxTime,
      onChange,
      onCalendarClose,
    }: DatePickerProps,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    return (
      <DatePickerBase
        selected={value}
        customInput={<InputWrapper label={label} inputRef={ref} />}
        calendarClassName={cs.calendar}
        showTimeSelect={mode === "time" || mode === "datetime"}
        showTimeInput={mode === "datetime"}
        timeIntervals={timeIntervals || 15}
        timeCaption="Godzina"
        timeInputLabel="Kliknij aby wybrać godzinę:"
        showTimeSelectOnly={mode === "time"}
        dateFormat={mode === "datetime" ? "Pp" : mode === "time" ? "p" : "P"}
        required={required}
        portalId="root-portal"
        minTime={minTime || DEFAULT_MIN_HOUR}
        maxTime={maxTime || DEFAULT_MAX_HOUR}
        isClearable={isClearable}
        clearButtonTitle="Usuń"
        withPortal={asModal || isSmallScreen}
        onCalendarOpen={() => {
          if (mode === "time" && value) {
            setTimeout(() => {
              const selectedTimeEl = document.querySelector(
                ".react-datepicker__time-list-item--selected",
              );

              if (selectedTimeEl) {
                selectedTimeEl.scrollIntoView({
                  block: "nearest",
                  inline: "start",
                });
              }
            });
          }
        }}
        onChange={onChange}
        onCalendarClose={onCalendarClose}
      />
    );
  },
);
