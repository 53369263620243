import React from "react";

import { ParticipantType } from "../../types/ParticipantType";
import { Popover, PopoverPosition } from "../Popover";

import { SelectParticipantPopoverContent } from "./SelectParticipantPopoverContent";

type SelectParticipantPopoverProps = {
  participant: ParticipantType | null;
  popoverPosition?: PopoverPosition;
  children: React.ReactNode;
  onSelect: (selectedStudent: ParticipantType) => void;
};

export const SelectParticipantPopover = ({
  participant,
  popoverPosition,
  children,
  onSelect,
}: SelectParticipantPopoverProps) => {
  return (
    <Popover
      popover={
        <SelectParticipantPopoverContent
          participant={participant}
          onSelect={onSelect}
        />
      }
      position={popoverPosition}
    >
      {children}
    </Popover>
  );
};
