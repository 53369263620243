import { useEffect, useState } from "react";

import { AddStudentModal } from "src/components/AddStudentModal";

import { Button } from "@components/Button";
import { InvitationModal } from "@components/InvitationModal";
import { PageInfo } from "@components/PageInfo";
import { StudentCenterTabType } from "@components/StudentCenterModal/Tabs";
import { Table } from "@components/Table";
import { UpdateStudentModal } from "@components/UpdateStudentModal";
import { StudentType } from "@customTypes/StudentType";
import { useDeleteStudent } from "@hooks/useDeleteStudent";
import { useLastLessons } from "@hooks/useLastLessons";
import { useStudents } from "@hooks/useStudents";
import { useAppStore } from "@store";

import personImg from "./img/person.svg";
import { columns } from "./columns";

import cs from "./MyStudentsPage.module.scss";

const PAGE_DESC = `Każdy z Twoich uczniów może, choć nie musi, posiadać darmowe konto w aplikacji. Konto ucznia umożliwia mu dostęp do swojego kalendarza lekcji, otrzymywanie i dostarczanie zadań domowych, dostęp do historii spotkań oraz notatek, a także umożliwia proponowanie lekcji, jeśli korepetytor utworzy Dostępność w swoim kalendarzu.`;

const EMPTY_LIST: readonly any[] = [];

export const MyStudentsPage = () => {
  const [isNewStudentModalOpen, setIsNewStudentModalOpen] = useState(false);
  const [isEditStudentModalOpen, setIsEditStudentModalOpen] = useState(false);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [editedStudent, setEditedStudent] = useState<StudentType | null>(null);
  const [sorted, setSorted] = useState<readonly StudentType[]>([]);

  const { data = EMPTY_LIST } = useStudents();
  const { data: lastLessons = EMPTY_LIST, isLoading } = useLastLessons();

  const deleteStudent = useDeleteStudent();

  const [sort, sortByField] = Table.useSort();

  const openConfirmationModal = useAppStore(
    (state) => state.openConfirmationModal,
  );
  const openStudentCenterModal = useAppStore(
    (state) => state.openStudentCenterModal,
  );

  useEffect(() => {
    if (data.length === 0) {
      setSorted([]);
      return;
    }

    setSorted(handleSort(data, sort));
  }, [data, sort]);

  useEffect(() => {
    if (lastLessons.length === 0) return;
    if (data.length === 0) return;

    setSorted((prev) => {
      return prev.map((student) => {
        const lastLesson = lastLessons.find(
          (lesson) => lesson.participantId === student.participantId,
        );

        return {
          ...student,
          lastLesson: lastLesson?.lesson || null,
        };
      });
    });
  }, [sorted.length, lastLessons]);

  return (
    <main className={cs.page}>
      <header className={cs.header}>
        <PageInfo label="Jak działa konto ucznia?" desc={PAGE_DESC} />

        <Button color="primary" onClick={() => setIsNewStudentModalOpen(true)}>
          <img src={personImg} alt="" />

          <span>Dodaj ucznia</span>
        </Button>
      </header>

      <Table
        data={sorted}
        columns={columns({
          onEditStudent: handleEdit,
          onDeleteStudent: handleDelete,
          onInviteStudent: handleInvite,
          onOpenStudentCenter: handleOpenStudentCenter,
        })}
        sort={sort}
        empty={{
          title: "Lista Twoich uczniów jest jeszcze pusta.",
          sub: "Tutaj zobaczysz listę wszystkich dodanych uczniów wraz z ich danymi kontaktowymi.",
          extra: (
            <div>
              <Button
                className="mt24"
                color="primary"
                onClick={() => setIsNewStudentModalOpen(true)}
              >
                <img src={personImg} alt="" />

                <span>Dodaj pierwszego ucznia</span>
              </Button>
            </div>
          ),
        }}
        isLoading={isLoading}
        onSort={sortByField}
      />

      <AddStudentModal
        isOpen={isNewStudentModalOpen}
        onClose={() => setIsNewStudentModalOpen(false)}
      />

      <UpdateStudentModal
        isOpen={isEditStudentModalOpen}
        student={editedStudent}
        onClose={() => {
          setIsEditStudentModalOpen(false);
          setEditedStudent(null);
        }}
      />

      <InvitationModal
        isOpen={isInviteModalOpen}
        student={editedStudent}
        onClose={() => setIsInviteModalOpen(false)}
      />
    </main>
  );

  function handleOpenStudentCenter(
    selectedStudent: StudentType,
    tab?: StudentCenterTabType,
  ) {
    openStudentCenterModal(selectedStudent.participantId, tab);
  }

  function handleEdit(editedStudent: StudentType) {
    setEditedStudent(editedStudent);
    setIsEditStudentModalOpen(true);
  }

  async function handleDelete(studentId: string) {
    const isConfirmed = await openConfirmationModal({
      text: "Czy na pewno chcesz usunąć tego studenta? Tej akcji nie można cofnąć.",
      confirmText: "Usuń",
    });

    if (isConfirmed) {
      deleteStudent.mutate({ studentId });
    }
  }

  function handleInvite(editedStudent: StudentType) {
    setEditedStudent(editedStudent);
    setIsInviteModalOpen(true);
  }
};

function handleSort(data: readonly any[], sortArr: readonly string[]) {
  let sort = sortArr[0];

  if (!sort) {
    return data;
  }

  if (sort.includes("-")) {
    sort = sort.replace("-", "");

    return [...data].sort((student1, student2) =>
      student1[sort] > student2[sort] ? -1 : 1,
    );
  } else {
    return [...data].sort((student1, student2) =>
      student1[sort] > student2[sort] ? 1 : -1,
    );
  }
}
