import { ReactNode } from "react";

import classNames from "classnames";

import cs from "./Checkbox.module.scss";

type Props = {
  value?: any;
  checked: boolean;
  label?: ReactNode;
  style?: any;
  className?: string;
  disabled?: boolean;
  required?: boolean;
  size?: "default" | "small";
  onChange?: (checked: boolean, value: any) => void;
};

export const Checkbox = ({
  value,
  checked,
  label,
  style,
  className,
  disabled = false,
  required = false,
  size = "default",
  onChange,
}: Props) => {
  // const rootCs = classNames("Checkbox", cs.Checkbox, {
  //   [cs.default]: size === "default",
  //   [cs.small]: size === "small",
  // });
  const rootCs = classNames(cs.root, "Checkbox", className, {
    [cs.disabled]: disabled,
  });

  return (
    <label className={rootCs}>
      <input
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={(e) => {
          onChange?.(e.target.checked, value);
        }}
      />

      {label && <span>{label}</span>}
    </label>
  );
};
