import classNames from "classnames";
import groupBy from "lodash.groupby";

import { CalendarEventType } from "@constants/CalendarEventType";
import { isToday } from "@utils/date.utils";

import { MatrixDay } from "./MonthView";

import cs from "./MonthView.module.scss";

type MonthViewDayProps = {
  day: MatrixDay;
  events: any;
  onDayClicked: (day: Date) => void;
};

export const MonthViewDay = ({
  day,
  events,
  onDayClicked,
}: MonthViewDayProps) => {
  const dayCs = classNames(cs.day, {
    [cs.day__thisMonth]: day.type === "current",
    [cs.day__today]: isToday(day.iso),
  });

  const thisDayEvents = events[day.iso];

  if (!thisDayEvents) {
    return (
      <div
        key={day.iso}
        className={dayCs}
        onClick={() => onDayClicked(new Date(day.iso))}
      >
        <div>{day.date}</div>
      </div>
    );
  }

  const thisDayEventsGrouped = groupBy(thisDayEvents, "type");

  const lessonsCount = thisDayEventsGrouped[CalendarEventType.lesson]?.length;
  const timeSlotsCount =
    thisDayEventsGrouped[CalendarEventType.timeSlot]?.length;
  const regularEventsCount =
    thisDayEventsGrouped[CalendarEventType.regularEvent]?.length;

  const allThree = lessonsCount && timeSlotsCount && regularEventsCount;

  return (
    <div
      key={day.iso}
      className={dayCs}
      onClick={() => onDayClicked(new Date(day.iso))}
    >
      <div>{day.date}</div>

      <div className={cs.events} style={{ fontWeight: allThree ? 500 : 600 }}>
        {lessonsCount && <span className={cs.lesson}>{lessonsCount}</span>}
        {timeSlotsCount && (
          <span className={cs.timeSlot}>{timeSlotsCount}</span>
        )}
        {regularEventsCount && (
          <span className={cs.regularEvent}>{regularEventsCount}</span>
        )}
      </div>
    </div>
  );
};
