import classNames from "classnames";

import { Popover } from "../Popover";

import questionImg from "./img/question.svg";

import cs from "./InfoBox.module.scss";

type InfoBoxProps = { desc: string; className?: string };

export const InfoBox = ({ desc, className }: InfoBoxProps) => {
  const rootCs = classNames(cs.root, className);

  return (
    <Popover
      position={["bottom"]}
      popover={<div className={cs.desc}>{desc}</div>}
    >
      <span className={rootCs}>
        <img src={questionImg} alt="" width={13} />
      </span>
    </Popover>
  );
};
