import { useEffect, useRef, useState } from "react";

import { Button } from "@components/Button";
import { Input } from "@components/Input";
import { Modal } from "@components/Modal";
import { MaterialDtoType } from "@customTypes/MaterialDtoType";
import { useReplaceMaterial } from "@hooks/useReplaceMaterial";

import cs from "./AddFolderModal.module.scss";

type AddFolderModalProps = {
  isOpen: boolean;
  material: MaterialDtoType;
  onClose: () => void;
};

export const ChangeNameModal = ({
  isOpen,
  material,
  onClose,
}: AddFolderModalProps) => {
  const [name, setName] = useState("");

  const formElement = useRef<HTMLFormElement>(null);

  const replaceMaterial = useReplaceMaterial();

  useEffect(() => {
    if (!isOpen) return;

    setName(material.name);
  }, [isOpen, material]);

  return (
    <Modal
      title="Zmień nazwę"
      isOpen={isOpen}
      className={cs.modal}
      footer={
        <Button
          color="primary"
          isLoading={replaceMaterial.isLoading}
          onClick={handleConfirm}
        >
          Zapisz
        </Button>
      }
      onClose={onClose}
    >
      <form ref={formElement} className={cs.form}>
        <Input label="Nazwa" value={name} required onChange={setName} />
      </form>
    </Modal>
  );

  async function handleConfirm() {
    const isValid = formElement.current?.reportValidity();

    if (!isValid) return;

    await replaceMaterial.mutateAsync({
      material: { ...material, name },
    });

    onClose();
    clear();
  }

  function clear() {
    setName("");
  }
};
