export const paginationOptions = [
  {
    label: 15,
  },
  {
    label: 30,
  },
  {
    label: 50,
  },
];
