import { ContactType } from "@customTypes/ContactType";

import { createAxiosInstance } from "./_createAxiosInstance";

const api = createAxiosInstance("/api/contact");

type CreatParams = {
  contact: Partial<ContactType>;
};

type ReplaceParams = {
  contact: ContactType;
};

type DeleteByIdParams = {
  id: string;
};

export class ContactApi {
  static async create({ contact }: CreatParams) {
    return await api.post("", contact);
  }

  static async replace({ contact }: ReplaceParams) {
    return api.put("", contact);
  }

  static async deleteById({ id }: DeleteByIdParams) {
    return api.delete(`/${id}`);
  }
}
