import { Navigate, useLocation } from "react-router-dom";

import { useAppStore } from "@store";

type RequireAuthProps = {
  children: JSX.Element;
};

export const RequireAuth = ({ children }: RequireAuthProps) => {
  const currentUser = useAppStore((state) => state.currentUser);
  const location = useLocation();

  if (!currentUser) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (
    currentUser.isBlocked &&
    location.pathname + location.search !== "/settings?tab=subscription"
  ) {
    return <Navigate to="/settings?tab=subscription" replace />;
  }

  return children;
};
