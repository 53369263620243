import { LessonType } from "../../types/LessonType";
import { LessonDetails } from "../LessonDetails";

import cs from "./CalendarLessonEvent.module.scss";

type CalendarLessonEventProps = {
  lesson: LessonType;
};

export const CalendarLessonEvent = ({ lesson }: CalendarLessonEventProps) => {
  const height = calcBoxHeight(lesson.duration);
  const top = calcStartPosition(lesson.date);

  return (
    <LessonDetails lesson={lesson}>
      <div className={`CalendarLessonEvent ${cs.root}`} style={{ height, top }}>
        <div>
          <span className={cs.participants}>
            {lesson.participant.details.name}
          </span>

          <span className={cs.hours}>
            {lesson.startHourStr}-{lesson.endHourStr}
          </span>
        </div>

        <div className={cs.topic} title={lesson.topic}>
          {lesson.topic}
        </div>
      </div>
    </LessonDetails>
  );
};

export function calcBoxHeight(duration: number) {
  // NOTE: STEP 1: 1 min === 1 px
  const height = duration;

  // NOTE: STEP 2: Add borders width
  const howManyBorders = Math.floor(height / 60);

  return height + howManyBorders;
}

export function calcStartPosition(date: Date) {
  return date.getMinutes();
}
