import { useEffect } from "react";

import { Notification } from "@components/Notification";
import { Skeleton } from "@components/Skeleton";
import { useDeleteAllNotifications } from "@hooks/useDeleteAllNotifications";
import { useDeleteNotification } from "@hooks/useDeleteNotification";
import { useMarkManyNotificationsAsSeen } from "@hooks/useMarkManyNotificationsAsSeen";
import { useNotifications } from "@hooks/useNotifications";
import { ReactComponent as BrushActionImg } from "@img/brush_action.svg";

import parentCs from "./DashboardPage.module.scss";
import cs from "./Notifications.module.scss";

export function Notifications() {
  const { data, isLoading, unseenCount, hasMore, getNext } = useNotifications();
  const deleteAllNotifications = useDeleteAllNotifications();
  const deleteNotification = useDeleteNotification();
  const markManyNotificationsAsSeen = useMarkManyNotificationsAsSeen();

  useEffect(() => {
    if (data.length > 0) {
      // TODO: Could be optimized - save ones already sent
      markManyNotificationsAsSeen.mutate({
        todoIds: data.filter((n) => !n.seen).map((n) => n._id),
      });
    }
  }, [data.length]);

  return (
    <section className={cs.root}>
      <header className={parentCs.sectionHeader}>
        {/*<img src={expandImg} alt="" />*/}

        <span>Aktualności ({unseenCount})</span>

        {!isLoading && (
          <button
            className="btn-icon ml6"
            title="Wyczyść notyfikacje"
            onClick={() => deleteAllNotifications.mutate()}
          >
            <BrushActionImg width={24} />
          </button>
        )}
      </header>

      <div className={cs.list}>
        {isLoading && <Skeleton count={4} height={25} space={6} />}

        {!isLoading && data.length === 0 && (
          <div className={cs.noNotificationsInfo}>
            Brak powiadomień - jesteś na bieżąco!
          </div>
        )}

        {data.map((notification) => (
          <Notification
            key={notification._id}
            notification={notification}
            onDelete={deleteNotification.mutate}
          />
        ))}
      </div>

      {hasMore && (
        <div className="text-center">
          <button className={cs.showMoreBtn} onClick={getNext}>
            Pokaż więcej
          </button>
        </div>
      )}
    </section>
  );
}
