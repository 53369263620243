import React, { useRef, useState } from "react";

import { AccountApi } from "@api/AccountApi";
import { Button } from "@components/Button";
import { Input } from "@components/Input";
import { useUpdatePassword } from "@hooks/useUpdatePassword";
import { ToastService } from "@services/ToastService";
import { useAppStore } from "@store";

import cs from "@pages/Settings/Settings.module.scss";

export const TabSecurity = () => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isDeleteAccountLoading, setIsDeleteAccountLoading] = useState(false);

  const currentPasswordElement = useRef<HTMLInputElement>(null);
  const confirmPasswordElement = useRef<HTMLInputElement>(null);

  const openConfirmationModal = useAppStore(
    (state) => state.openConfirmationModal,
  );

  const updatePassword = useUpdatePassword();

  return (
    <section className={cs.box}>
      <form onSubmit={handleChangePassword}>
        <Input
          value={currentPassword}
          label="Aktualne hasło"
          required
          type="password"
          ref={currentPasswordElement}
          onChange={(val) => {
            setCurrentPassword(val);
            currentPasswordElement.current?.setCustomValidity("");
          }}
        />

        <Input
          value={newPassword}
          label="Nowe hasło"
          required
          type="password"
          onChange={setNewPassword}
        />

        <Input
          value={confirmPassword}
          label="Potwierdź nowe hasło"
          type="password"
          ref={confirmPasswordElement}
          onChange={(val) => {
            setConfirmPassword(val);
            confirmPasswordElement.current?.setCustomValidity("");
          }}
        />

        <div className="f-center mt32">
          <Button
            color="secondary"
            className={cs.submitBtn}
            htmlType="submit"
            isLoading={updatePassword.isLoading}
            style={{ width: "140px", justifyContent: "center" }}
          >
            Aktualizuj hasło
          </Button>
        </div>

        <div className="f-center mt24">
          <Button
            color="danger"
            className={cs.submitBtn}
            htmlType="button"
            isLoading={isDeleteAccountLoading}
            style={{ width: "140px", justifyContent: "center" }}
            onClick={handleDeleteAccount}
          >
            Usuń konto
          </Button>
        </div>
      </form>
    </section>
  );

  async function handleChangePassword(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (!validatePassword()) {
      return;
    }

    try {
      await updatePassword.mutateAsync({
        newPassword,
        currentPassword,
      });

      ToastService.success("Hasło zostało zmienione.");

      setConfirmPassword("");
      setNewPassword("");
      setCurrentPassword("");
    } catch (errorResponse: any) {
      const error = errorResponse?.error?.response?.data?.message || "";

      if (error === "CURRENT_PASSWORD_INCORRECT") {
        currentPasswordElement.current?.setCustomValidity(
          "Podane hasło nie jest prawidłowe",
        );
        currentPasswordElement.current?.reportValidity();
      }
    }
  }

  function validatePassword() {
    if (newPassword !== confirmPassword) {
      confirmPasswordElement.current?.setCustomValidity(
        "Hasła się nie zgadzają",
      );
      confirmPasswordElement.current?.reportValidity();

      return false;
    }

    confirmPasswordElement.current?.setCustomValidity("");
    return true;
  }

  async function handleDeleteAccount() {
    const isConfirmed = await openConfirmationModal({
      confirmText: "Usuń",
      text: (
        <div>
          <span>
            <span>
              Czy jesteś pewien/pewna, że chcesz usunąć swoje konto w aplikacji?
              Proces ten jest nieodwracalny i spowoduje trwałe usunięcie
              wszystkich Twoich danych.
            </span>
          </span>
        </div>
      ),
    });

    if (isConfirmed) {
      setIsDeleteAccountLoading(true);

      try {
        await AccountApi.deleteAccount();
      } catch {
        setIsDeleteAccountLoading(false);
      }

      ToastService.success("Konto zostało usunięte", { duration: 2000 });

      setTimeout(() => {
        window.location.href = "/login";
      }, 2000);
    }
  }
};
