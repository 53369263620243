import { useSearchParams } from "react-router-dom";

import { useAppStore } from "@store";
import { checkDemoUser } from "@utils/utils";

import cs from "./Settings.module.scss";

export type TabKeyType = "general" | "subscription" | "security";

type TabsProps = {
  activeTab: TabKeyType;
};

export const Tabs = ({ activeTab }: TabsProps) => {
  const [, setSearchParams] = useSearchParams();
  const currentUser = useAppStore((state) => state.currentUser);

  const isDemoUser = checkDemoUser(currentUser);

  return (
    <header className={cs.tabs}>
      <div className={getCs("general")} onClick={() => handleChange("general")}>
        Ustawienia
      </div>

      {!isDemoUser && (
        <>
          <div
            className={getCs("subscription")}
            onClick={() => handleChange("subscription")}
          >
            Subskrypcja
          </div>
          <div
            className={getCs("security")}
            onClick={() => handleChange("security")}
          >
            Bezpieczeństwo
          </div>
        </>
      )}
    </header>
  );

  function getCs(tabKey: TabKeyType) {
    if (activeTab === tabKey) return cs.tab__active;

    return cs.tab;
  }

  function handleChange(tab: TabKeyType) {
    if (currentUser?.isBlocked) return;

    setSearchParams({ tab });
  }
};
