import { useState } from "react";

import { NewTodo, Todo } from "@components/Todo";
import { ParticipantType } from "@customTypes/ParticipantType";
import { TodoType } from "@customTypes/TodoType";
import { useAddTodo } from "@hooks/useAddTodo";
import { useDeleteTodo } from "@hooks/useDeleteTodo";
import { useMarkTodoAsDone } from "@hooks/useMarkTodoAsDone";
import { useSaveTodo } from "@hooks/useSaveTodo";
import { useUndoneTodosByParticipant } from "@hooks/useUndoneTodosByParticipant";
import { useAppStore } from "@store";

import { ReactComponent as AddActionImg } from "./img/add_action.svg";

import parentCs from "./StudentCenterModal.module.scss";
import cs from "./Todos.module.scss";

// TODO: Show completed (https://www.figma.com/file/8Khg0Mr9wnrMFUMjURIpQy?node-id=7710:2516#307935951)

export function Todos({ participant }: { participant: ParticipantType }) {
  const [showAll, setShowAll] = useState(false);
  const [isAddNewActive, setIsAddNewActive] = useState(false);

  const { isLoading, data: undone = [] } = useUndoneTodosByParticipant(
    participant._id,
  );

  const deleteTodo = useDeleteTodo();
  const markAsDone = useMarkTodoAsDone();
  const addTodo = useAddTodo();
  const saveTodo = useSaveTodo();

  const openConfirmationModal = useAppStore(
    (state) => state.openConfirmationModal,
  );

  const displayed = showAll ? undone : undone.slice(0, 3);
  const anyHidden = undone.length > 3;

  return (
    <section className={cs.root}>
      <header className={parentCs.sectionHeader}>
        {/*<img src={expandImg} alt="" />*/}

        <span>Do zrobienia ({undone.length})</span>

        <button className="btn-icon" onClick={() => setIsAddNewActive(true)}>
          <AddActionImg width={24} />
        </button>
      </header>

      <div className={cs.list}>
        {isLoading && <div>Loading</div>}

        {!isLoading && undone.length === 0 && (
          <div className={cs.noTodosInfo}>Brawo, jesteś na bieżąco!</div>
        )}

        {displayed.map((todo) => (
          <Todo
            key={todo._id}
            todo={todo}
            personal
            onDelete={handleDelete}
            onDone={markAsDone.mutate}
            onSave={saveTodo.mutateAsync}
          />
        ))}

        {isAddNewActive && <NewTodo personal onSave={handleAddNewTodo} />}
      </div>

      {!showAll && anyHidden && (
        <div className="text-center">
          <button className={cs.showMoreBtn} onClick={() => setShowAll(true)}>
            Pokaż wszystkie
          </button>
        </div>
      )}
    </section>
  );

  function handleAddNewTodo(newTodo: Partial<TodoType>) {
    setIsAddNewActive(false);

    addTodo.mutate({ ...newTodo, participant });
  }

  async function handleDelete(todoId: string) {
    const isConfirmed = await openConfirmationModal({
      confirmText: "Usuń",
      text: "Czy na pewno chcesz usunąć to zadanie?",
    });

    if (isConfirmed) {
      await deleteTodo.mutateAsync(todoId);
    }
  }
}
