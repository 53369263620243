import { useQuery } from "react-query";

import { TodoApi } from "@api/TodoApi";
import { TodoType } from "@customTypes/TodoType";

export const useUndoneTodos = () => {
  return useQuery<TodoType[]>(["todos", "undone"], () =>
    TodoApi.fetchAllUndone(),
  );
};
