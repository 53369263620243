import React from "react";

import classNames from "classnames";

import cs from "./Pill.module.scss";

type PillProps = {
  value?: any;
  type?: "outline" | "normal" | "dashed";
  children: React.ReactNode;
  onClick?: (value: any) => void;
};

export const Pill = ({
  children,
  value,
  type = "normal",
  onClick,
}: PillProps) => {
  const rootCs = classNames(cs.root, "Pill", {
    clickable: Boolean(onClick),
    [cs[type]]: type !== "normal",
  });

  return (
    <div className={rootCs} onClick={() => onClick?.(value)}>
      {children}
    </div>
  );
};
