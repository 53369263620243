import { RegularEventDtoType } from "@customTypes/RegularEventDtoType";
import { RegularEventType } from "@customTypes/RegularEventType";
import { RegularEventDtoService } from "@services/RegularEventDtoService";

import { createAxiosInstance } from "./_createAxiosInstance";

const api = createAxiosInstance("/api/regular-event");

type CreateParams = {
  regularEvent: Omit<RegularEventDtoType, "_id">;
};

type DeleteByIdParams = {
  regularEventId: string;
};

type ReplaceParams = {
  regularEvent: RegularEventType;
};

export class RegularEventApi {
  static async create({ regularEvent }: CreateParams) {
    const { data } = await api.post<RegularEventDtoType>("", regularEvent);

    return RegularEventDtoService.transform(data);
  }

  static deleteById({ regularEventId }: DeleteByIdParams) {
    return api.delete("/" + regularEventId);
  }

  static replace({ regularEvent }: ReplaceParams) {
    return api.put("", regularEvent);
  }
}
