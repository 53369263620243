import { FocusEvent } from "react";

import { Input } from "@components/Input";
import { Select } from "@components/Select";
import { PaymentLogicOptions } from "@constants/PaymentLogicOptions";
import { PaymentLogicType } from "@customTypes/PaymentLogicType";
import { ArrearService } from "@services/ArrearService";
import { formatShortDate, TOMORROW } from "@utils/date.utils";

import cs from "./PaymentLogic.module.scss";

type PaymentLogicProps = {
  paymentLogic: PaymentLogicType;
  onChange: (value: PaymentLogicType) => void;
};

export const PaymentLogic = ({ paymentLogic, onChange }: PaymentLogicProps) => {
  const selectedOption = PaymentLogicOptions.find(
    (o) => o.value === paymentLogic.paymentLogicId,
  );

  return (
    <div className={cs.root}>
      <Select
        value={selectedOption?.label}
        label="Termin płatności"
        options={PaymentLogicOptions}
        className={cs.type}
        onChange={handleTypeChanged}
      />

      {isDaysOptionAvailable(paymentLogic.paymentLogicId) && (
        <Input
          value={paymentLogic.days || ""}
          label="Ilośc dni"
          type="number"
          min={1}
          max={14}
          className={cs.days}
          onChange={handleDaysChanged}
          onBlur={preventFromInvalidDaysValue}
        />
      )}

      {paymentLogic.paymentLogicId !== 0 && (
        <div>
          <div className="label mb2">Przykład</div>
          <div className={cs.example}>
            Dla lekcji która odbyłaby się w dniu jutrzejszym (
            {formatShortDate(TOMORROW)}) termin płatności zostałby wyznaczony na
            dzień{" "}
            <strong className="semi-bold">{formatPaymentDeadline()}.</strong>
          </div>
        </div>
      )}
    </div>
  );

  function handleTypeChanged(option: (typeof PaymentLogicOptions)[0]) {
    let days = paymentLogic.days;

    if (isDaysOptionAvailable(option.value)) {
      days = days || 1;
    } else {
      days = null;
    }

    onChange({ paymentLogicId: option.value, days });
  }

  function handleDaysChanged(daysStr: string) {
    let days = Number.parseInt(daysStr, 10);

    if (days > 14) days = 14;

    onChange({ ...paymentLogic, days: days });
  }

  function preventFromInvalidDaysValue(e: FocusEvent<HTMLInputElement>) {
    const days = Number.parseInt(e.target.value, 10);

    if (isNaN(days) || days < 1) {
      onChange({ ...paymentLogic, days: 1 });
    }
  }

  function isDaysOptionAvailable(paymentLogicId: number) {
    return [3, 4].includes(paymentLogicId);
  }

  function formatPaymentDeadline() {
    const deadline = ArrearService.calcPaymentDeadline(paymentLogic, TOMORROW);

    if (deadline) {
      return formatShortDate(deadline);
    } else {
      return "";
    }
  }
};
