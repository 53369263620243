// @ts-nocheck

import classNames from "classnames";

import { Checkbox } from "@components/Checkbox";
import { MaterialType } from "@customTypes/MaterialType";
import { ReactComponent as DocsImg } from "@img/doc.svg";
import { ReactComponent as FileImg } from "@img/file.svg";
import { ReactComponent as ImageImg } from "@img/image.svg";
import { ReactComponent as LinkImg } from "@img/link_icon.svg";
import pdfIcon from "@img/pdf_icon.svg";
import { formatDate } from "@utils/date.utils";
import { downloadFile, openLink } from "@utils/utils";

import cs from "./File.module.scss";

type FileProps = {
  checked: boolean;
  material: MaterialType;
  view: "list" | "folders";
  condensed?: boolean;
  preventDownload?: boolean;
  onSelect: (folder: MaterialType | null) => void;
  onToggle: (material: MaterialType, checked: boolean) => void;
};

export const File = ({
  checked,
  material,
  view,
  condensed = false,
  preventDownload,
  onSelect,
  onToggle,
}: FileProps) => {
  const rootCs = classNames(cs.root, {
    [cs.condensed]: condensed,
  });

  return (
    <div
      className={rootCs}
      title={material.name}
      onClick={() => onToggle(material, !checked)}
    >
      <Checkbox checked={checked} className={cs.checkbox} />

      <span className={cs.colName}>
        {view === "list" && (
          <span className={cs.recursiveNavigation}>
            {material.ancestors.map((ancestor) => (
              <>
                <span
                  className={`${cs.navigationChips_navigate} RecursiveNavigation_name`}
                  onClick={() => onSelect(ancestor._id)}
                >
                  {ancestor.name}
                </span>
                <span>{" > "}</span>
              </>
            ))}
          </span>
        )}

        <span
          className={cs.name}
          onClick={(e) => {
            if (preventDownload) return;

            e.stopPropagation();

            if (material.type !== "link") {
              downloadFile(material.link);
            } else {
              openLink(material.link);
            }
          }}
        >
          {material.name}
        </span>
      </span>

      {/* TODO: Later */}
      {/*{!condensed && <span>15.11.2022</span>}*/}
      {!condensed && (
        <span className="hide-mobile">
          {formatDate(material.createdAt, "numeric")}
        </span>
      )}

      {material.type === "link" && <LinkImg width={16} title="Link" />}
      {material.type === "pdf" && (
        <img src={pdfIcon} alt="" title="PDF" height={18} />
      )}
      {material.type === "image" && <ImageImg width={16} title="Obraz" />}
      {material.type === "doc" && <DocsImg width={16} title="Dokument" />}
      {material.type === "other" && <FileImg width={13} />}
    </div>
  );
};
