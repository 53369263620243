import { ReactNode } from "react";

import { Popover } from "../Popover";

import questionImg from "./img/question.svg";

import cs from "./PageInfo.module.scss";

type PageInfoProps = {
  label: string;
  desc: ReactNode;
};

export const PageInfo = ({ label, desc }: PageInfoProps) => (
  <Popover
    position={["right", "bottom"]}
    popover={<div className={cs.desc}>{desc}</div>}
  >
    <span className={cs.root}>
      <img src={questionImg} alt="" />

      <span>{label}</span>
    </span>
  </Popover>
);
