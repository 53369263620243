import introJs from "intro.js";

import { getState } from "@store";
import { diffMinutes } from "@utils/date.utils";
import { isSmallScreen } from "@utils/utils";

export class UserTourService {
  private commonOptions = {
    disableInteraction: true,
    exitOnOverlayClick: false,
    showBullets: false,
    showProgress: false,
    doneLabel: "Koniec",
    nextLabel: "Dalej",
    prevLabel: "Wróć",
  };

  start(kind: "first-visit") {
    switch (kind) {
      case "first-visit":
        this.firstVisit();
        break;
    }
  }

  checkIfShowIntroTour() {
    // NOTE: Looks bad on mobile. Don't show on small screens.
    if (isSmallScreen) return;

    const currentUser = getState().currentUser;

    if (!currentUser) return;

    const wasIntroTourClosedOnThisUserAgent =
      localStorage.getItem("intro_user_tour");

    if (wasIntroTourClosedOnThisUserAgent) return false;

    // TODO: Temp solution
    const isAccountOlderThanHour =
      diffMinutes(Date.now(), currentUser.createdAt) >= 60;

    if (isAccountOlderThanHour) {
      localStorage.setItem("intro_user_tour", "true");
    }

    return !isAccountOlderThanHour;
  }

  private firstVisit() {
    introJs()
      .setOptions({
        ...this.commonOptions,
        steps: [
          {
            title: "Witamy w up4edu",
            intro:
              "To krótkie wprowadzenie pomoże Ci postawić pierwsze kroki w aplikacji.",
          },
          {
            title: "Dashboard",
            element: document.querySelector(".dashboard-main"),
            intro:
              "Wszystko, czego potrzebujesz, aby sprawnie zorganizować swój dzień, zebrane w jednym miejscu.",
            scrollTo: "tooltip",
          },
          {
            title: "Kalendarz",
            element: document.querySelector(".calendar-section"),
            intro:
              "Twój plan zajęć na dziś. Z tego miejsca możesz np. dodać lub odwołać spotkanie. Widok tygodniowy znajdziesz w sekcji Kalendarz w menu głównym.",
            scrollTo: "tooltip",
          },
          {
            title: "Utwórz",
            element: document.querySelector(".calendar-cell-clickable"),
            intro:
              "Kliknij na pusty wiersz, aby utworzyć lekcję, dostępność lub wydarzenie prywatne.",
          },
          {
            title: "Kalendarz",
            element: document.querySelector(".CalendarLessonEvent") || null,
            intro: "Kliknij aby wyświetlić szczegóły.",
          },
          {
            title: "Centrum ucznia",
            element: document.querySelector(".StudentsSearch"),
            intro:
              "Wyszukaj ucznia, aby wyświetlić jego dane i przebieg nauki.",
          },
          {
            title: "Samouczek",
            element: document.querySelector(".launch-user-tour"),
            intro: "Kliknij tutaj, aby wyświetlić ten samouczek ponownie.",
          },
        ].filter(
          (step) =>
            step.element !== null &&
            (!step.element ||
              getComputedStyle(step.element).display !== "none"),
        ) as any,
      })
      .onexit(function () {
        localStorage.setItem("intro_user_tour", "true");
      })
      .start();
  }
}
