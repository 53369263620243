import { useEffect, useRef, useState } from "react";

import classNames from "classnames";

import { Input } from "@components/Input";
import { Popover } from "@components/Popover";
import { usePlans } from "@hooks/usePlans";

import searchImg from "./img/search.svg";
import { PlansSearchContent } from "./PlansSearchContent";

import cs from "./PlansSearch.module.scss";

type PlansSearchProps = {
  placeholder?: string;
  className?: string;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  inputValue?: string;
  value?: any;
  onChange: (selected: any[] | any) => void;
  onInputChange?: (value: string) => void;
};

const sort = ["name"];
export const PlansSearch = ({
  placeholder = "Wyszukaj kurs",
  className,
  label,
  disabled,
  required,
  inputValue,
  value,
  onChange,
  onInputChange,
}: PlansSearchProps) => {
  const [filterValue, setFilterValue] = useState("");

  const popoverElement = useRef<any>(null);

  const { data = [] } = usePlans(sort);

  useEffect(() => {
    setFilterValue(value?.name ?? "");
  }, [value]);

  const filteredResults = data.filter((item) =>
    item.name.toLowerCase().includes(filterValue.toLowerCase()),
  );

  const rootCs = classNames(cs.root, className, {
    [cs.withLabel]: Boolean(label),
  });

  return (
    <Popover
      popover={
        <PlansSearchContent plans={filteredResults} onSelect={handleSelected} />
      }
      position={["bottom", "top"]}
      square
      adjustWidth
      className={cs.popover}
      ref={popoverElement}
    >
      <div
        className={rootCs}
        onClickCapture={(e) => {
          if (disabled) {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
      >
        <img src={searchImg} alt="" width={16} className={cs.icon} />

        <Input
          value={inputValue || filterValue}
          placeholder={placeholder}
          className={cs.input}
          label={label}
          disabled={disabled}
          required={required}
          onChange={handleInputChanged}
        />
      </div>
    </Popover>
  );

  function handleSelected(item: any) {
    onChange(item);

    popoverElement.current?.close();
  }

  function handleInputChanged(newValue: string) {
    setFilterValue(newValue);
    onInputChange?.(newValue);
  }
};
