import { LessonDtoType } from "@customTypes/LessonDtoType";
import { ParticipantDtoType } from "@customTypes/ParticipantDtoType";
import { LessonDtoService } from "@services/LessonDtoService";
import { ParticipantDtoService } from "@services/ParticipantDtoService";

import { createAxiosInstance } from "./_createAxiosInstance";

const api = createAxiosInstance("/api/participant");

export class ParticipantApi {
  static async fetchAll() {
    const { data } = await api.get<ParticipantDtoType[]>("");

    return ParticipantDtoService.transformAll(data);
  }

  static async fetchById(participantId: string) {
    const { data } = await api.get<ParticipantDtoType>(`/${participantId}`);

    return ParticipantDtoService.transform(data);
  }

  static async fetchLastLessons() {
    const { data } = await api.get<
      {
        participantId: string;
        lesson: LessonDtoType | null;
      }[]
    >("/last-lesson");

    return data.map((row) => {
      if (!row.lesson) return row;

      return {
        ...row,
        lesson: LessonDtoService.transform(row.lesson),
      };
    });
  }

  static async fetchLastNextLessonsByParticipant(participantId: string) {
    const { data } = await api.get<{
      participantId: string;
      lastLesson: LessonDtoType | null;
      nextLesson: LessonDtoType | null;
    }>(`/${participantId}/last-next-lessons`);

    return {
      ...data,
      lastLesson: data.lastLesson
        ? LessonDtoService.transform(data.lastLesson)
        : null,
      nextLesson: data.nextLesson
        ? LessonDtoService.transform(data.nextLesson)
        : null,
    };
  }
}
