import { useState } from "react";

import { Button } from "@components/Button";
import { PageInfo } from "@components/PageInfo";
import { Table } from "@components/Table";
import { UpsertTemplateModal } from "@components/UpsertTemplateModal";
import { TemplateType } from "@customTypes/TemplateType";
import { useCopyTemplate } from "@hooks/useCopyTemplate";
import { useDeleteTemplate } from "@hooks/useDeleteTemplate";
import { useTemplates } from "@hooks/useTemplates";
import { useAppStore } from "@store";

import personImg from "../MyStudentsPage/img/person.svg";

import { columns } from "./columns";
import { SidePanel } from "./SidePanel";

import cs from "./TemplatesListPage.module.scss";

const PAGE_DESC = `Dzięki szablonom w łatwy sposób możesz tworzyć powtarzające się lekcje. Szablon lekcji posiada częściowo uzupełnione pola tworzenia lekcji np. temat, materiały czy zadania domowe.`;

export const TemplatesListPage = () => {
  const [activeTemplate, setActiveTemplate] = useState<TemplateType | null>(
    null,
  );
  const [isNewTemplateModalOpen, setIsNewTemplateModalOpen] = useState(false);
  const [isEditTemplateModalOpen, setIsEditTemplateModalOpen] = useState(false);

  const [sort, handleSort] = Table.useSort();

  const { data = [], isLoading } = useTemplates(sort);

  const deleteTemplate = useDeleteTemplate();
  const copyTemplate = useCopyTemplate();

  const openConfirmationModal = useAppStore(
    (state) => state.openConfirmationModal,
  );

  return (
    <main className={cs.page}>
      <header className={cs.header}>
        <PageInfo label="Czym są Szablony lekcji?" desc={PAGE_DESC} />

        <Button color="primary" onClick={() => setIsNewTemplateModalOpen(true)}>
          <img src={personImg} alt="" />

          <span>Dodaj szablon lekcji</span>
        </Button>
      </header>

      <section className={cs.table}>
        <Table
          data={data}
          columns={columns}
          sort={sort}
          empty={{
            title: "Lista szablonów jest jeszcze pusta.",
            sub: "Dzięki szablonom w łatwy sposób możesz tworzyć powtarzające się lekcje.",
            extra: (
              <div>
                <Button
                  className="mt24"
                  color="primary"
                  onClick={() => setIsNewTemplateModalOpen(true)}
                >
                  <img src={personImg} alt="" />

                  <span>Dodaj pierwszy szablon</span>
                </Button>
              </div>
            ),
          }}
          isLoading={isLoading}
          onRowClick={setActiveTemplate}
          onSort={handleSort}
        />
      </section>

      <SidePanel
        activeTemplate={activeTemplate}
        onClose={() => setActiveTemplate(null)}
        onCopy={handleCloneTemplate}
        onDelete={handleDelete}
        onEdit={() => {
          setIsEditTemplateModalOpen(true);
        }}
      />

      <UpsertTemplateModal
        isOpen={isNewTemplateModalOpen}
        onClose={() => setIsNewTemplateModalOpen(false)}
      />

      <UpsertTemplateModal
        isOpen={isEditTemplateModalOpen}
        template={activeTemplate}
        onClose={() => setIsEditTemplateModalOpen(false)}
        onAfterUpsert={setActiveTemplate}
      />
    </main>
  );

  async function handleCloneTemplate(templateToCopy: TemplateType) {
    const clonedTemplate = await copyTemplate.mutateAsync({
      template: templateToCopy,
    });

    setActiveTemplate(clonedTemplate);
  }

  async function handleDelete(templateId: string) {
    const isConfirmed = await openConfirmationModal({
      confirmText: "Usuń",
      text: "Czy na pewno chcesz usunąć ten szablon?",
    });

    if (isConfirmed) {
      await deleteTemplate.mutateAsync({ templateId });
      setActiveTemplate(null);
    }
  }
};
