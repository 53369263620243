import { Table } from "@components/Table";
import { useBills } from "@hooks/useBills";
import { Period } from "@pages/Settings/tabs/TabBilling_Period";
import { isFuture } from "@utils/date.utils";

import { columns } from "./bills-columns";

import cs from "./TabBilling.module.scss";

export const TabBilling = () => {
  const { data: bills = [], isLoading } = useBills();

  const currentBilling = bills.find((bill: any) => !isFuture(bill.periodStart));
  const nextBilling = bills.find((bill: any) => isFuture(bill.periodStart));

  return (
    <div>
      <section className={cs.packages}>
        <Period bill={currentBilling} type="current" />
        {nextBilling && <Period bill={nextBilling} type="next" />}
      </section>

      <div style={{ maxWidth: 645, marginTop: 42 }}>
        <Table data={bills} columns={columns} isLoading={isLoading} />
      </div>
    </div>
  );
};
