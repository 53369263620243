import { useMutation, useQueryClient } from "react-query";

import { LessonApi } from "@api/LessonApi";

export const useCreateLessonsFromTemplates = () => {
  const queryClient = useQueryClient();

  return useMutation("lessons", LessonApi.createFromTemplates, {
    onSuccess: async () => {
      return queryClient.invalidateQueries({
        queryKey: ["lessons"],
        refetchInactive: true,
      });
    },
  });
};
