import { useMutation, useQueryClient } from "react-query";

import { TodoApi } from "@api/TodoApi";
import { TodoType } from "@customTypes/TodoType";
import { genRandomId } from "@utils/utils";

export const useAddTodo = () => {
  const queryClient = useQueryClient();

  return useMutation("todos", TodoApi.create, {
    onMutate: async (newTodo) => {
      await queryClient.cancelQueries(["todos"]);

      const cachedValue = queryClient.getQueriesData<TodoType[]>("todos") || [];

      const tempId = genRandomId();
      const savedTodo = { ...newTodo, _id: tempId, done: false };

      queryClient.setQueryData<any>(["todos", "undone"], (previous = []) => [
        savedTodo,
        ...previous,
      ]);

      queryClient.setQueryData<any>(
        ["todos", "undone", "participant", newTodo.participant?._id],
        (previous = []) => [savedTodo, ...previous],
      );

      return { cachedValue, tempId };
    },
    onError: (err, newTodo, context) => {
      queryClient.setQueriesData(["todos"], context?.cachedValue);
    },
    onSuccess(newTodo, options, context) {
      queryClient.setQueriesData<TodoType[]>(["todos"], (previous = []) =>
        previous.map((todo) =>
          todo._id === context?.tempId ? { ...todo, _id: newTodo._id } : todo,
        ),
      );
    },
  });
};
