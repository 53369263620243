import { useEffect } from "react";

import { AuthApi } from "@api/AuthApi";
import { DemoApi } from "@api/DemoApi";
import { Spinner } from "@components/Spinner";

import cs from "./DemoPage.module.scss";

export const DemoPage = () => {
  useEffect(() => {
    (async () => {
      const data = await DemoApi.create();

      if (data?.email) {
        await AuthApi.logIn({
          email: data.email,
          password: "123456",
        });

        window.location.href = "/";
      }
    })();
  }, []);

  return (
    <div className={cs.root}>
      <Spinner loading className="fullViewSpinner" size={54}>
        <div className={cs.text}>
          Tworzymy dla Ciebie demo aplikacji! Prosimy o chwilę cierpliwości.
        </div>
      </Spinner>
    </div>
  );
};
