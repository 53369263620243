import { useState } from "react";

import { TabFinances } from "./TabFinances/TabFinances";
import { TabLessons } from "./TabLessons";

import cs from "./LessonTabs.module.scss";

export const LessonTabs = () => {
  const [activeTab, setActiveTab] = useState<"lessons" | "finances">("lessons");

  return (
    <section className={cs.root}>
      <header className={cs.tabs}>
        <div
          className={activeTab === "lessons" ? cs.tab__active : cs.tab}
          onClick={() => setActiveTab("lessons")}
        >
          Lekcje
        </div>
        <div
          className={activeTab === "finances" ? cs.tab__active : cs.tab}
          onClick={() => setActiveTab("finances")}
        >
          Finanse
        </div>
      </header>

      <div className={cs.lessonSubLists}>
        {activeTab === "lessons" && <TabLessons />}
        {activeTab === "finances" && <TabFinances />}
      </div>
    </section>
  );
};
