import classNames from "classnames";

import cs from "./Node.module.scss";

export const Node = ({
  type,
}: {
  type?: "current" | "with_date" | "no_date";
}) => {
  const rootsCs = classNames(cs.node, {
    [cs.node__current]: type === "current",
    [cs.node__with_date]: type === "with_date",
    [cs.node__no_date]: type === "no_date",
  });

  return <div className={rootsCs}></div>;
};
