import { TodoType } from "@customTypes/TodoType";
import { TodoDtoService } from "@services/TodoDtoService";

import { createAxiosInstance } from "./_createAxiosInstance";

const api = createAxiosInstance("/api/todo");

export class TodoApi {
  static async fetchAllUndone(participantId?: string) {
    const { data } = await api.get("", {
      params: { participant: participantId, undone: true },
    });

    return TodoDtoService.transformAll(data);
  }

  static async deleteById(todoId: string) {
    return api.delete(`/${todoId}`);
  }

  static replace(todo: TodoType) {
    return api.put("", todo);
  }

  static async markAsDone(todo: TodoType) {
    return TodoApi.replace({ ...todo, done: true });
  }

  static async create(newTodo: Partial<TodoType>) {
    const { data } = await api.post("/", newTodo);

    return TodoDtoService.transform(data);
  }
}
