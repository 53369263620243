import { useState } from "react";

import { useDeleteRegularEvent } from "../../hooks/useDeleteRegularEvent";
import { ReactComponent as PencilImg } from "../../img/pencil2.svg";
import { ReactComponent as TrashImg } from "../../img/trash_2.svg";
import { useAppStore } from "../../store/store";
import { RegularEventType } from "../../types/RegularEventType";
import { formatDate } from "../../utils/date.utils";
import { Modal } from "../Modal";
import { UpsertRegularEventModal } from "../UpsertRegularEventModal";

import cs from "./RegularEventDetailsModal.module.scss";

type RegularEventDetailsModalProps = {
  isOpen: boolean;
  regularEvent: RegularEventType;
  onClose: () => void;
};

export const RegularEventDetailsModal = ({
  regularEvent,
  isOpen,
  onClose,
}: RegularEventDetailsModalProps) => {
  const [isEditRegularEventModalOpen, setIsEditRegularEventModalOpen] =
    useState(false);

  const deleteRegularEvent = useDeleteRegularEvent();

  const openConfirmationModal = useAppStore(
    (state) => state.openConfirmationModal,
  );

  const footer = (
    <div className={cs.footer}>
      <button className="btn-icon" onClick={handleEdit}>
        <PencilImg height={20} />
      </button>

      <button className="btn-icon" onClick={handleDelete}>
        <TrashImg height={20} />
      </button>
    </div>
  );

  return (
    <Modal
      title="Wydarzenie"
      isOpen={isOpen}
      footer={footer}
      isLoading={deleteRegularEvent.isLoading}
      onClose={handleClose}
    >
      <div className={cs.root}>
        <div className={cs.label}>Data:</div>
        <div>{formatDate(regularEvent.date)}</div>

        <div className={cs.label}>Czas:</div>
        <div>
          {regularEvent.startHourStr}-{regularEvent.endHourStr}
        </div>

        <div className={cs.label}>Opis:</div>
        <div>{regularEvent.desc}</div>
      </div>

      <UpsertRegularEventModal
        isOpen={isEditRegularEventModalOpen}
        regularEvent={regularEvent}
        onClose={() => setIsEditRegularEventModalOpen(false)}
      />
    </Modal>
  );

  function handleClose() {
    onClose();
    setIsEditRegularEventModalOpen(false);
  }

  async function handleDelete() {
    const isConfirmed = await openConfirmationModal({
      confirmText: "Usuń",
      text: `Czy na pewno chcesz usunąć to wydarzenie?`,
    });

    if (isConfirmed) {
      await deleteRegularEvent.mutateAsync({
        regularEventId: regularEvent._id,
      });

      onClose();
    }
  }

  async function handleEdit() {
    setIsEditRegularEventModalOpen(true);
  }
};
