import { useEffect, useState } from "react";

import { Button } from "@components/Button";
import { PageInfo } from "@components/PageInfo";
import { Table } from "@components/Table";
import { UpsertPlanModal } from "@components/UpsertPlanModal";
import { PlanType } from "@customTypes/PlanType";
import { useDeletePlan } from "@hooks/useDeletePlan";
import { useParticipants } from "@hooks/useParticipants";
import { usePlans } from "@hooks/usePlans";
import { useReplacePlan } from "@hooks/useReplacePlan";
import { ReactComponent as PlusImg } from "@img/plus.svg";
import { useAppStore } from "@store";

import { columns } from "./columns";
import { SidePanel } from "./SidePanel";

import cs from "./PlansListPage.module.scss";

export const PlansListPage = () => {
  const [isNewPlanModalOpen, setIsNewPlanModalOpen] = useState(false);
  const [isEditPlanModalOpen, setIsEditPlanModalOpen] = useState(false);
  const [activePlan, setActivePlan] = useState<PlanType | null>(null);

  const [sort, handleSort] = Table.useSort();

  const { data = [], isLoading } = usePlans(sort);
  const { students } = useParticipants();

  const replacePlan = useReplacePlan();
  const deletePlan = useDeletePlan();

  useEffect(() => {
    // NOTE: Check if active lesson hasn't changed after data was updated
    if (activePlan) {
      const updatedActiveLesson = data.find(
        (plan) => plan._id === activePlan._id,
      );

      setActivePlan(updatedActiveLesson || null);
    }
  }, [data, activePlan]);

  const openStudentCenterModal = useAppStore(
    (state) => state.openStudentCenterModal,
  );

  const openConfirmationModal = useAppStore(
    (state) => state.openConfirmationModal,
  );

  const PAGE_DESC = (
    <div>
      Kurs jest zbiorem lekcji, który możesz przypisać do ucznia. Dzięki temu
      nie musisz w kółko tworzyć tych samych lekcji. Aby przypisać kurs wejdź w
      <span
        className="link"
        onClick={() => {
          const anyStudent = students.at(0);
          if (anyStudent) {
            openStudentCenterModal(anyStudent._id);

            document.body.click();
          }
        }}
      >
        {" "}
        Centrum ucznia
      </span>
      . W dolnej części sekcji
      <span className="semi-bold italic"> Plan nauki </span> znajduje się opcja
      <span className="semi-bold italic"> Przypisz kurs</span>. Lekcje pojawią
      się w kalendarzu Twoim oraz ucznia po uzupełnieniu daty i godziny lekcji.
    </div>
  );

  return (
    <main className={cs.page}>
      <header className={cs.header}>
        <PageInfo label="Czym są kursy?" desc={PAGE_DESC} />

        <Button color="primary" onClick={() => setIsNewPlanModalOpen(true)}>
          <PlusImg width={12} />

          <span>Utwórz kurs</span>
        </Button>
      </header>

      <section className={cs.table}>
        <Table
          data={data}
          sort={sort}
          columns={columns}
          empty={{
            title: "Lista kursów jest jeszcze pusta.",
            sub: `Kurs jest zbiorem lekcji, który możesz przypisać do ucznia. Dzięki temu nie musisz w kółko tworzyć tych samych lekcji. Po przypisaniu kursu wszystkie lekcje, bez przypisanej daty, pojawią się w profilu ucznia w Centrum Ucznia. Zaimportowane lekcje możesz dowolnie edytować, usuwać i zmieniać ich kolejność. Lekcje pojawią się w kalendarzu Twoim oraz ucznia po uzupełnieniu daty i godziny lekcji.`,
            extra: (
              <div>
                <Button
                  className="mt24"
                  color="primary"
                  onClick={() => setIsNewPlanModalOpen(true)}
                >
                  <PlusImg width={12} />

                  <span>Utwórz pierwszy kurs</span>
                </Button>
              </div>
            ),
          }}
          isLoading={isLoading}
          onRowClick={setActivePlan}
          onSort={handleSort}
        />
      </section>

      <SidePanel
        activePlan={activePlan}
        onEdit={() => setIsEditPlanModalOpen(true)}
        onTemplateChange={(updatedPlan) => {
          setActivePlan(updatedPlan);
          replacePlan.mutate({ updatedPlan });
        }}
        onDelete={handleDelete}
        onClose={() => setActivePlan(null)}
      />

      <UpsertPlanModal
        isOpen={isNewPlanModalOpen}
        onClose={() => setIsNewPlanModalOpen(false)}
      />

      <UpsertPlanModal
        isOpen={isEditPlanModalOpen}
        plan={activePlan}
        onAfterUpsert={setActivePlan}
        onClose={() => setIsEditPlanModalOpen(false)}
      />
    </main>
  );

  async function handleDelete() {
    const isConfirmed = await openConfirmationModal({
      confirmText: "Usuń",
      text: `Czy na pewno chcesz usunąć ten kurs?`,
    });

    if (isConfirmed && activePlan) {
      await deletePlan.mutateAsync({ planId: activePlan?._id });

      setActivePlan(null);
    }
  }
};
