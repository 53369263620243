import { useQuery } from "react-query";

import { ParticipantApi } from "@api/ParticipantApi";

// INFO: Separate endpoint for ease of caching. Notice first query key - "lessons"
export const useLastLessons = () => {
  return useQuery(
    ["lessons", "last-lessons"],
    () => {
      return ParticipantApi.fetchLastLessons();
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      keepPreviousData: true,
    },
  );
};
