import { PlanType } from "@customTypes/PlanType";

import { createAxiosInstance } from "./_createAxiosInstance";

const api = createAxiosInstance("/api/plan");

export class PlansApi {
  static async fetchAll(sort: string[]) {
    const { data } = await api.get<PlanType[]>("", {
      params: { sort },
    });

    return data;
  }

  static async create(plan: Pick<PlanType, "name" | "note" | "templates">) {
    const { data } = await api.post("", plan);

    return data;
  }

  static async deleteById({ planId }: { planId: string }) {
    return api.delete(`/${planId}`);
  }

  static async replace({ updatedPlan }: { updatedPlan: PlanType }) {
    return api.put("", updatedPlan);
  }
}
