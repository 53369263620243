import { CalendarEventType } from "../constants/CalendarEventType";
import { formatDate } from "../utils/date.utils";

export class CalendarEvent {
  public startDate: string;
  public startHour: number;
  public duration: number;
  public type: CalendarEventType;
  public details: any;

  constructor(
    date: Date,
    duration: number,
    type: CalendarEventType,
    details: any,
  ) {
    this.startDate = formatDate(date, "iso");
    this.startHour = date.getHours();
    this.duration = duration;
    this.type = type;
    this.details = details;
  }
}
