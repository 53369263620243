import classNames from "classnames";

import { LessonPaymentStatus } from "../../constants/LessonPaymentStatus";
import { ReactComponent as DollarImg } from "../../img/dollar.svg";
import { ArrearType } from "../../types/ArrearType";

import cs from "./PaymentStatus.module.scss";

type PaymentStatusProps = {
  arrears: ArrearType[];
  className?: string;
  size?: "default" | "small";
};

export const PaymentStatus = ({
  arrears,
  size = "default",
  className,
}: PaymentStatusProps) => {
  let rootCs = classNames(cs.root, cs[size], className);

  const { status, isAfterDeadline } = arrears[0];

  switch (status) {
    case LessonPaymentStatus.PAID: {
      rootCs = classNames(rootCs, cs.paid);

      return (
        <div className={rootCs} title="Uczeń oznaczył lekcję jako opłaconą">
          {size === "default" && <span>Opłacono</span>}

          {size === "small" && <DollarImg />}
        </div>
      );
    }

    case LessonPaymentStatus.CLOSED: {
      rootCs = classNames(rootCs, cs.closed);

      return (
        <div className={rootCs} title="Oznaczyłeś lekcję jako rozliczoną">
          {size === "default" && <span>Rozliczono</span>}

          {size === "small" && <DollarImg />}
        </div>
      );
    }
    case LessonPaymentStatus.AWAITS: {
      rootCs = classNames(
        rootCs,
        isAfterDeadline ? cs.afterDeadline : cs.awaits,
      );

      const tooltip = isAfterDeadline
        ? "Upłynął termin płatności"
        : "Oczekuje na płatność";

      const label = isAfterDeadline ? "Po terminie" : "Oczekuje";

      return (
        <div className={rootCs} title={tooltip}>
          {size === "default" && <span>{label}</span>}

          {size === "small" && <DollarImg />}
        </div>
      );
    }
    default:
      throw new Error("Payment status unrecognized: ", status);
  }
};
