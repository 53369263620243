import { useQuery, useQueryClient } from "react-query";

import { ParticipantApi } from "@api/ParticipantApi";
import { ParticipantType } from "@customTypes/ParticipantType";

export const useParticipant = (participantId: string) => {
  const queryClient = useQueryClient();

  return useQuery(
    ["participants", "participant", participantId],
    () => {
      if (!participantId) return Promise.resolve(null);

      return ParticipantApi.fetchById(participantId);
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: Boolean(participantId),
      placeholderData: () => {
        return queryClient
          .getQueryData<ParticipantType[]>(["participants"])
          ?.find(({ _id }) => _id === participantId);
      },
    },
  );
};
