import { useState } from "react";

import {
  calcBoxHeight,
  calcStartPosition,
} from "@components/CalendarLessonEvent/CalendarLessonEvent";
import { Chips } from "@components/Chips";
import { TimeSlotDetailsModal } from "@components/TimeSlotDetailsModal";
import { TimeSlotType } from "@customTypes/TimeSlotType";

import cs from "./CalendarTimeSlotEvent.module.scss";

type CalendarTimeSlotEventProps = {
  timeSlot: TimeSlotType;
};

export const CalendarTimeSlotEvent = ({
  timeSlot,
}: CalendarTimeSlotEventProps) => {
  const [areDetailsOpened, setAreDetailsOpened] = useState(false);

  const height = calcBoxHeight(timeSlot.duration);
  const top = calcStartPosition(timeSlot.date);

  return (
    <>
      <div
        className={cs.root}
        style={{ height, top }}
        onClick={() => setAreDetailsOpened(true)}
      >
        <div>
          <span className={cs.title}>DOSTĘPNOŚĆ</span>
          <span className={cs.hours}>
            {timeSlot.startHourStr}-{timeSlot.endHourStr}
          </span>
        </div>

        <div className={cs.footer}>
          <span>Ilość aplikacji:</span>
          <Chips color="neutral" className={cs.counter}>
            {timeSlot.applications.length}
          </Chips>
        </div>
      </div>

      <TimeSlotDetailsModal
        timeSlot={timeSlot}
        isOpen={areDetailsOpened}
        onClose={() => setAreDetailsOpened(false)}
      />
    </>
  );
};
