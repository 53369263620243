import { useEffect, useState } from "react";

import { AssignPlanModal } from "@components/AssignPlanModal";
import { Spinner } from "@components/Spinner";
import { LessonType } from "@customTypes/LessonType";
import { ParticipantType } from "@customTypes/ParticipantType";
import { useDeleteLesson } from "@hooks/useDeleteLesson";
import { useStudyProgress } from "@hooks/useStudyProgress";
import { useUpdateLesson } from "@hooks/useUpdateLesson";
import { useAppStore } from "@store";

import { Lesson } from "./Lesson";
import { NodeAssignPlan } from "./NodeAssignPlan";
import { NodeConnection } from "./NodeConnection";
import { NodeNewLesson } from "./NodeNewLesson";
import { NodeShowMore } from "./NodeShowMore";

import cs from "./StudyProgress.module.scss";

type StudyProgressProps = {
  participant: ParticipantType;
};

export const StudyProgress = ({ participant }: StudyProgressProps) => {
  const [futureCount, setFutureCount] = useState(5);
  const [pastCount, setPastCount] = useState(2);
  const [allPastLessonLoaded, setAllPastLessonLoaded] = useState(false);
  const [allFutureLessonsFetched, setAllFutureLessonsFetched] = useState(false);
  const [isAssignPlanModalOpen, setIsAssignPlanModalOpen] = useState(false);

  const {
    data = [],
    isLoading,
    isFetching,
  } = useStudyProgress({
    participantId: participant._id,
    futureCount,
    pastCount,
  });

  const deleteLesson = useDeleteLesson();
  const updateLesson = useUpdateLesson();

  const openUpsertLessonModal = useAppStore(
    (state) => state.openUpsertLessonModal,
  );
  const openConfirmationModal = useAppStore(
    (state) => state.openConfirmationModal,
  );

  useEffect(() => {
    if (isLoading || isFetching) return;

    const pastLessonsCount = data.filter((l) => l.isPast).length;
    const allPastLessonLoaded = pastCount > pastLessonsCount;

    const futureLessonsCount = data.filter((l) => !l.isPast).length;
    const allFutureLessonsFetched = futureCount > futureLessonsCount;

    setAllPastLessonLoaded(allPastLessonLoaded);
    setAllFutureLessonsFetched(allFutureLessonsFetched);
  }, [data, isLoading, isFetching]);

  const nextLesson = data.find((l) => l.date && !l.isPast);

  return (
    <>
      <Spinner
        loading={isLoading || isFetching}
        size={52}
        className={cs.spinner}
      >
        <div className={cs.root}>
          <div className={cs.sectionTitle}>Przebieg nauki</div>

          <div className={cs.tree}>
            {!allPastLessonLoaded && (
              <>
                <NodeShowMore
                  type="prev"
                  onClick={() => setPastCount((prev) => prev + 4)}
                />

                <NodeConnection length={30} color="#dcdfe4" />
              </>
            )}

            {data.map((lesson, index) => {
              const lessonAfter = data[index + 1];

              let nodeConnection = <NodeConnection />;

              if (!lessonAfter) {
                nodeConnection = <NodeConnection color="#dcdfe4" length={30} />;
              } else if (!lesson.isPast && !lessonAfter.isPast) {
                nodeConnection = <NodeConnection color="#60adc6" />;
              }

              return (
                <>
                  <Lesson
                    lesson={lesson}
                    isNext={lesson === nextLesson}
                    onEdit={handleEdit}
                    onDelete={handleDelete}
                    onDateSet={handleDateSet}
                  />
                  {nodeConnection}
                </>
              );
            })}

            {!allFutureLessonsFetched && (
              <>
                <NodeShowMore
                  type="next"
                  onClick={() => setFutureCount((prev) => prev + 8)}
                />
                <NodeConnection color="#dcdfe4" length={20} />
              </>
            )}

            <NodeNewLesson participant={participant} />
            <NodeConnection color="#dcdfe4" length={15} />

            <NodeAssignPlan onClick={() => setIsAssignPlanModalOpen(true)} />
          </div>
        </div>
      </Spinner>

      <AssignPlanModal
        isOpen={isAssignPlanModalOpen}
        participantId={participant._id}
        onClose={() => setIsAssignPlanModalOpen(false)}
      />
    </>
  );

  function handleEdit(lesson: LessonType) {
    openUpsertLessonModal(lesson);
  }

  async function handleDelete(lesson: LessonType) {
    const verb = lesson?.isPast ? "usunąć" : "odwołać";

    const isConfirmed = await openConfirmationModal({
      confirmText: "Usuń",
      text: `Czy na pewno chcesz ${verb} tę lekcję?`,
    });

    if (isConfirmed && lesson) {
      await deleteLesson.mutateAsync({ lessonId: lesson._id });
    }
  }

  async function handleDateSet(lesson: LessonType, newDate: Date) {
    await updateLesson.mutateAsync({
      lessonId: lesson._id,
      updatedFields: {
        date: newDate,
      },
    });
  }
};
