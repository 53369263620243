import { useEffect, useRef, useState } from "react";

import { ParticipantType } from "../../types/ParticipantType";
import { TodoType } from "../../types/TodoType";
import { Button } from "../Button";

import { ReactComponent as TodoIconImg } from "./img/todo_icon.svg";
import { SelectParticipantBtn } from "./SelectParticipantBtn";

import cs from "./Todo.module.scss";

type EditedTodoProps = {
  todo: TodoType;
  personal?: boolean;
  onSave: (updatedTodo: TodoType) => void;
};

export const EditedTodo = ({ todo, personal, onSave }: EditedTodoProps) => {
  const [value, setValue] = useState<string>("");
  const [participant, setParticipant] = useState<ParticipantType | null>(null);

  const inputEl = useRef<any>();

  useEffect(() => {
    if (todo) {
      setValue(todo.value);
      setParticipant(todo.participant);
    }
  }, [todo]);

  return (
    <div className={cs.root}>
      <div className="f-center">
        <TodoIconImg width={16} />
      </div>

      <div className={cs.value}>
        <input
          value={value}
          className={cs.input}
          placeholder="Wpisz co jest do zrobienia"
          title={value}
          ref={inputEl}
          onChange={(e) => setValue(e.target.value)}
        />
      </div>

      <div className={cs.mutableActions}>
        {!personal && (
          <SelectParticipantBtn
            participant={participant}
            onChange={setParticipant}
          />
        )}

        <Button
          color="primary"
          type="outlined"
          size="small"
          className={cs.saveBtn}
          onClick={() => {
            if (value) {
              onSave({
                ...todo,
                value,
                participant,
              });
            } else {
              inputEl.current.setCustomValidity("Uzupełnij treść");
              inputEl.current.reportValidity();
            }
          }}
        >
          ZAPISZ
        </Button>
      </div>
    </div>
  );
};
