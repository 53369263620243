import { Checkbox } from "@components/Checkbox";
import { useStudents } from "@hooks/useStudents";

import cs from "./FilterStudents.module.scss";

type FilterStudentsProps = {
  students: Array<string>;
  onChange: (filterKey: string, value: Array<string>) => void;
};

export const FilterStudents = ({ students, onChange }: FilterStudentsProps) => {
  students = students ?? [];

  const { data = [] } = useStudents();

  const sortedData = [...data].sort((student1, student2) =>
    student1.name > student2.name ? 1 : -1,
  );

  return (
    <div className={`FilterStudents ${cs.root}`}>
      <div className={`FilterStudents_title ${cs.title}`}>Uczniowie</div>

      <div className={`FilterStudents_list ${cs.list}`}>
        {sortedData.length === 0 && (
          <div style={{ fontStyle: "italic" }}>Brak uczniów...</div>
        )}

        {sortedData.map((student) => (
          <div key={student._id} className={cs.list_item}>
            <Checkbox
              value={student._id}
              checked={students.includes(student._id)}
              label={student.name}
              onChange={handleChanged}
            />
          </div>
        ))}
      </div>
    </div>
  );

  function handleChanged(checked: boolean, studentId: string) {
    onChange(
      "students",
      checked
        ? [...students, studentId]
        : students.filter((s) => s !== studentId),
    );
  }
};
