// @ts-nocheck
import { useEffect, useState } from "react";

import { WorkingHoursModal } from "@components/WorkingHoursModal";
import { CalendarEvent } from "@customTypes/CalendarEvent";
import { useCalendarEvents } from "@hooks/useCalendarEvents";
import { useCurrentUser } from "@hooks/useCurrentUser";
import { ReactComponent as SettingsImg } from "@img/settings_new.svg";
import { UserTourService } from "@services/UserTourService";
import {
  addDays,
  endOf,
  formatDate,
  formatWeekday,
  isCurrentHour,
  startOf,
  subtractDays,
} from "@utils/date.utils";

import arrowBackImg from "./img/arrow_back.svg";
import arrowForwardImg from "./img/arrow_forward.svg";
import { AddEvent } from "./AddEvent";
import { Event } from "./Event";
import { TouchedCellProvider } from "./TouchedCell";

import cs from "./CalendarSingleDay.module.scss";

const TODAY = new Date();

function getFreshCalendarHours(date: Date, startHour: number, endHour: number) {
  return new Array(endHour - startHour + 1).fill(1).map((el, index) => {
    const hour = index + startHour;
    const dateCopy = new Date(date);

    return {
      hour,
      date: new Date(dateCopy.setHours(hour, 0, 0, 0)),
    };
  });
}

export const CalendarSingleDay = ({ selectedDay, onSelectedDayChange }) => {
  const { currentUser } = useCurrentUser();

  const [calendarHours, setCalendarHours] = useState(() => {
    return getFreshCalendarHours(
      selectedDay,
      currentUser?.settingWorkStartHour || 6,
      currentUser?.settingWorkEndHour || 23,
    );
  });
  const [isWorkingHoursModalOpen, setIsWorkingHoursModalOpen] = useState(false);

  const { data = null } = useCalendarEvents(
    startOf(selectedDay),
    endOf(selectedDay),
  );

  useEffect(() => {
    if (!data || !selectedDay) return;

    const selectedDayAsIso = formatDate(selectedDay, "iso");

    const selectedDayEvents = data[selectedDayAsIso];

    if (!selectedDayEvents?.length) {
      const freshCopy = getFreshCalendarHours(
        selectedDay,
        currentUser.settingWorkStartHour,
        currentUser.settingWorkEndHour,
      );
      setCalendarHours(freshCopy);
      return;
    }

    // TODO: Use immer
    const calendarHoursCopy = getFreshCalendarHours(
      selectedDay,
      currentUser.settingWorkStartHour,
      currentUser.settingWorkEndHour,
    );

    selectedDayEvents.forEach((event: any) => {
      const relatedHour = calendarHoursCopy.find(
        (calendarHour) => event.startHour === calendarHour.hour,
      ) as any;

      if (!relatedHour) return;

      // TODO:
      relatedHour.data = [...(relatedHour.data || []), event];
    });

    setCalendarHours(calendarHoursCopy);
  }, [data, selectedDay, currentUser]);

  useUserTour(data);

  return (
    <TouchedCellProvider>
      <div className={cs.root}>
        <div className={cs.header}>
          <span className="v-center">
            <span className={cs.header__title}>Kalendarz</span>

            <button
              className="btn-icon ml8"
              onClick={() => setIsWorkingHoursModalOpen(true)}
            >
              <SettingsImg width={16} />
            </button>
          </span>

          <span className={cs.header__date}>
            <p>{formatWeekday(selectedDay, "long")},</p>
            <p>{formatDate(selectedDay, "long")}</p>
          </span>
        </div>

        <div className={cs.subheader}>
          <button className="btn-icon" onClick={handleGoPrev}>
            <img src={arrowBackImg} alt="" />
          </button>

          <button
            className="btn-icon"
            onClick={() => onSelectedDayChange(TODAY)}
          >
            Dzisiaj
          </button>

          <button className="btn-icon" onClick={handleGoNext}>
            <img src={arrowForwardImg} alt="" />
          </button>
        </div>

        <div className={cs.days}>
          {calendarHours.map((calendarHour: any, index) => {
            let className = cs.hour;

            if (isCurrentHour(calendarHour.hour, calendarHour.date)) {
              className += " " + cs.hour__current;
            }

            return (
              <div
                key={calendarHour.hour}
                className={cs.day}
                style={{ zIndex: 30 - index }}
              >
                <div className={cs.day__hour}>
                  <span className={className}>{calendarHour.hour}</span>
                </div>

                <div className={cs.day__content}>
                  {calendarHour.data?.map((event: CalendarEvent) => (
                    <Event key={event.details._id} event={event} />
                  ))}

                  <AddEvent calendarHour={calendarHour} />
                </div>
              </div>
            );
          })}
        </div>

        <WorkingHoursModal
          isOpen={isWorkingHoursModalOpen}
          onClose={() => setIsWorkingHoursModalOpen(false)}
        />
      </div>
    </TouchedCellProvider>
  );

  function handleGoPrev() {
    onSelectedDayChange(subtractDays(selectedDay, 1));
  }

  function handleGoNext() {
    onSelectedDayChange(addDays(selectedDay, 1));
  }
};

const useUserTour = (data) => {
  useEffect(() => {
    if (!data) return;

    const userTourService = new UserTourService();

    if (userTourService.checkIfShowIntroTour()) {
      setTimeout(() => {
        userTourService.start("first-visit");
      }, 1000);
    }
  }, [data]);
};
