import { useRef, useState } from "react";

import { ParticipantType } from "../../types/ParticipantType";
import { TodoType } from "../../types/TodoType";
import { Button } from "../Button";

import { ReactComponent as TodoIconImg } from "./img/todo_icon.svg";
import { SelectParticipantBtn } from "./SelectParticipantBtn";

import cs from "./Todo.module.scss";

type NewTodoProps = {
  personal?: boolean;
  onSave: (updatedTodo: Partial<TodoType>) => void;
};

export const NewTodo = ({ personal, onSave }: NewTodoProps) => {
  const [value, setValue] = useState<string>("");
  const [participant, setParticipant] = useState<ParticipantType | null>(null);

  const inputEl = useRef<any>();

  return (
    <div className={cs.root}>
      <div className="f-center">
        <TodoIconImg width={16} />
      </div>

      <div className={cs.value}>
        <input
          value={value}
          className={cs.input}
          placeholder="Wpisz co jest do zrobienia"
          title={value}
          ref={inputEl}
          onKeyDown={(e) => e.key === "Enter" && submit()}
          onChange={(e) => {
            inputEl.current.setCustomValidity("");
            setValue(e.target.value);
          }}
        />
      </div>

      <div className={cs.mutableActions}>
        {!personal && (
          <SelectParticipantBtn
            participant={participant}
            onChange={setParticipant}
          />
        )}

        <Button
          color="primary"
          type="outlined"
          size="small"
          className={cs.saveBtn}
          onClick={submit}
        >
          ZAPISZ
        </Button>
      </div>
    </div>
  );

  function submit() {
    if (value) {
      onSave({
        value,
        participant,
      });
    } else {
      inputEl.current.setCustomValidity("Uzupełnij treść");
      inputEl.current.reportValidity();
    }
  }
};
