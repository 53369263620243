import { defaultCalendarView } from "@constants/CalendarViewOptions";
import { DefaultTutorPaymentLogic } from "@constants/DefaultTutorPaymentLogic";
import { CurrentUserType } from "@customTypes/CurrentUserType";

export class CurrentUserService {
  static transform(currentUser: CurrentUserType) {
    return {
      ...currentUser,
      paymentLogic: currentUser.paymentLogic || DefaultTutorPaymentLogic,
      settingWorkStartHour: currentUser.settingWorkStartHour || 6,
      settingWorkEndHour: currentUser.settingWorkEndHour || 23,
      settingCalendarDefaultView:
        currentUser.settingCalendarDefaultView || defaultCalendarView,
    };
  }
}
