import { TimeSlotDtoType } from "@customTypes/TimeSlotDtoType";

import { createAxiosInstance } from "./_createAxiosInstance";

const api = createAxiosInstance("/api/time-slot");

type CreateParams = {
  timeSlot: Omit<TimeSlotDtoType, "_id" | "applications">;
  notify?: boolean;
};

type DeleteByIdParams = {
  timeSlotId: string;
};

export class TimeSlotApi {
  static async create({ timeSlot, notify }: CreateParams) {
    return api.post<TimeSlotDtoType>("", timeSlot, {
      params: {
        notify,
      },
    });
  }

  static deleteById({ timeSlotId }: DeleteByIdParams) {
    return api.delete("/" + timeSlotId);
  }
}
