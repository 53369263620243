import { handleApiError } from "@api/_handle-api-error";
import { MaterialDtoType } from "@customTypes/MaterialDtoType";
import { MaterialType } from "@customTypes/MaterialType";
import { MaterialDtoService } from "@services/MaterialDtoService";

import { createAxiosInstance } from "./_createAxiosInstance";

const api = createAxiosInstance("/api/material");

type ReplaceParams = {
  material: MaterialDtoType;
};

type DeleteByIdParams = {
  materialIds: string[];
};

type CreateFromFileParams = {
  file: File;
  parentId: string | null;
};

type CreateFolderParams = {
  name: string;
  parentId: string | null;
};

type CreateLinkParams = {
  name: string;
  link: string;
  parentId: string | null;
};

export class MaterialApi {
  static async fetchAll() {
    const { data } = await api.get<MaterialType[]>("");

    return MaterialDtoService.transformAll(data);
  }

  static async createFromFile({ file, parentId }: CreateFromFileParams) {
    const form = new FormData();

    form.append("file", file);

    if (parentId) {
      form.append("parentId", parentId);
    }

    try {
      await api.post("/file", form);
    } catch (e) {
      return handleApiError(e);
    }
  }

  static async createFolder({ name, parentId }: CreateFolderParams) {
    return api.post("/folder", {
      name,
      parentId,
    });
  }

  static async createLink({ name, link, parentId }: CreateLinkParams) {
    return api.post("/link", {
      name,
      link,
      parentId,
    });
  }

  static async replace({ material }: ReplaceParams) {
    return api.put("", material);
  }

  static async deleteById({ materialIds }: DeleteByIdParams) {
    return api.delete(`/`, {
      params: { materialIds },
    });
  }
}
