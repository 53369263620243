import { createContext, ReactNode, useContext, useState } from "react";

const TouchedCellContext = createContext({});

export const TouchedCellProvider = ({ children }: { children: ReactNode }) => {
  const [touchedCell, setTouchedCell] = useState(null);

  return (
    <TouchedCellContext.Provider value={{ touchedCell, setTouchedCell }}>
      {children}
    </TouchedCellContext.Provider>
  );
};

export const useTouchedCell = () => {
  const { touchedCell, setTouchedCell } = useContext(TouchedCellContext);

  return [touchedCell, setTouchedCell];
};
