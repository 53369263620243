import { useRef, useState } from "react";

import classNames from "classnames";

import { Input } from "@components/Input";
import { Popover } from "@components/Popover";
import { ParticipantType } from "@customTypes/ParticipantType";
import { useParticipants } from "@hooks/useParticipants";
import { useAppStore } from "@store";

import searchImg from "./img/search.svg";
import { StudentsSearchContent } from "./StudentsSearchContent";

import cs from "./StudentsSearch.module.scss";

type StudentsSearchProps = {
  className?: string;
};

export const StudentsSearch = ({ className }: StudentsSearchProps) => {
  const [filterValue, setFilterValue] = useState("");

  const popoverElement = useRef<any>(null);

  const { students } = useParticipants();

  const openStudentCenterModal = useAppStore(
    (state) => state.openStudentCenterModal,
  );

  const openUpsertLessonModal = useAppStore(
    (state) => state.openUpsertLessonModal,
  );

  const filteredResults = students.filter((participant) =>
    participant.details.name.toLowerCase().includes(filterValue.toLowerCase()),
  );

  const rootCs = classNames(
    "StudentsSearch",
    cs.root,
    "hide-desktop-down",
    className,
  );

  return (
    <Popover
      popover={
        <StudentsSearchContent
          students={filteredResults}
          onSelect={handleSelected}
          onNewLesson={handleNewLesson}
        />
      }
      position={["bottom", "top"]}
      square
      adjustWidth
      className={cs.popover}
      ref={popoverElement}
    >
      <div className={rootCs}>
        <img src={searchImg} alt="" width={16} className={cs.icon} />

        <Input
          value={filterValue}
          placeholder="Znajdź ucznia"
          className={cs.input}
          onChange={handleInputChanged}
        />
      </div>
    </Popover>
  );

  function handleSelected(participant: ParticipantType) {
    openStudentCenterModal(participant._id);

    popoverElement.current?.close();
    setFilterValue("");
  }

  function handleNewLesson(participant: ParticipantType) {
    popoverElement.current?.close();

    openUpsertLessonModal({ participant });
  }

  function handleInputChanged(newValue: string) {
    setFilterValue(newValue);
  }
};
