import { useEffect, useRef, useState } from "react";

import { Button } from "@components/Button";
import { DatePicker } from "@components/DatePicker";
import { Modal } from "@components/Modal";
import { Textarea } from "@components/Textarea";
import { RegularEventType } from "@customTypes/RegularEventType";
import { useAddRegularEvent } from "@hooks/useAddRegularEvent";
import { useReplaceRegularEvent } from "@hooks/useReplaceRegularEvent";
import { addMinutes, diffMinutes } from "@utils/date.utils";

import cs from "./UpsertRegularEventModal.module.scss";

type UpsertRegularEventModalProps = {
  isOpen: boolean;
  regularEvent?: Partial<RegularEventType> | null;
  onClose: () => void;
};

export const UpsertRegularEventModal = ({
  isOpen,
  regularEvent,
  onClose,
}: UpsertRegularEventModalProps) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [desc, setDesc] = useState<string>("");
  const [endHour, setEndHour] = useState<Date | null>(null);

  const formElement = useRef<HTMLFormElement>(null);
  const endHourElement = useRef<HTMLInputElement>(null);

  const addRegularEvent = useAddRegularEvent();
  const replaceRegularEvent = useReplaceRegularEvent();

  useEffect(() => {
    if (isOpen && regularEvent) {
      regularEvent.date && setStartDate(regularEvent.date);
      regularEvent.desc && setDesc(regularEvent.desc);
      regularEvent.endDate && setEndHour(regularEvent.endDate);

      if (regularEvent.date && !regularEvent.endDate) {
        setEndHour(addMinutes(regularEvent.date, 60));
      }
    }
  }, [regularEvent, isOpen]);

  const mode = regularEvent?._id ? "edit" : "new";

  return (
    <Modal
      title={mode === "new" ? "Dodaj wydarzenie" : "Edytuj wydarzenie"}
      isOpen={isOpen}
      className={cs.modal}
      helpTooltip="Czym jest Wydarzenie prywatne?"
      help={`Wydarzenie pomaga Ci pamiętać o swojej niedostępności w danym dniu i godzinie z powodów prywatnych np. zaplanowanej wizyty u lekarza. Tworząc wydarzenie prywatne masz pewność, że w tym samym czasie nie zaplanujesz lekcji z uczniem.`}
      footer={
        <Button
          color="primary"
          isLoading={addRegularEvent.isLoading || replaceRegularEvent.isLoading}
          onClick={handleConfirm}
        >
          {mode === "new" ? "Dodaj" : "Zapisz"}
        </Button>
      }
      clickOutsideToClose={false}
      onClose={onClose}
    >
      <form ref={formElement} className={cs.form} tabIndex={0}>
        <DatePicker
          label="Data i godzina rozpoczęcia"
          mode="datetime"
          value={startDate}
          required
          onChange={handleStartDateSelected}
        />

        <DatePicker
          label="Godzina zakończenia"
          mode="time"
          value={endHour}
          required
          ref={endHourElement}
          onChange={setEndHour}
        />

        <Textarea label="Opis" value={desc} required onChange={setDesc} />
      </form>
    </Modal>
  );

  function handleStartDateSelected(startDate: Date) {
    setStartDate(startDate);
    setEndHour(addMinutes(startDate, 60));
  }

  async function handleConfirm() {
    const duration = diffMinutes(endHour as Date, startDate as Date);

    if (!(duration > 0)) {
      endHourElement.current?.setCustomValidity(
        "Godzina zakończenia musi być większa od godziny rozpoczęcia",
      );
    } else {
      endHourElement.current?.setCustomValidity("");
    }

    const isValid = formElement.current?.reportValidity();

    if (!isValid) return;

    if (mode === "new") {
      await addRegularEvent.mutateAsync({
        regularEvent: {
          date: startDate as Date,
          duration,
          desc,
        },
      });
    } else if (regularEvent?._id) {
      await replaceRegularEvent.mutateAsync({
        regularEvent: {
          ...regularEvent,
          date: startDate as Date,
          duration,
          desc,
        } as RegularEventType,
      });
    }

    onClose();
    clear();
  }

  function clear() {
    setStartDate(null);
    setEndHour(null);
    setDesc("");
  }
};
