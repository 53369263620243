import { StandaloneArrearDtoType } from "../types/StandaloneArrearDtoType";
import { StandaloneArrearType } from "../types/StandaloneArrearType";

import { ArrearDtoService } from "./ArrearDtoService";
import { LessonDtoService } from "./LessonDtoService";
import { StudentDtoService } from "./StudentDtoService";

export class StandaloneArrearDtoService {
  static transformAll(arrearDtos: StandaloneArrearDtoType[]) {
    return arrearDtos.map(StandaloneArrearDtoService.transform);
  }

  static transform(arrearDto: StandaloneArrearDtoType): StandaloneArrearType {
    const copy = { ...arrearDto };

    copy.students = copy.students.map((student) =>
      StudentDtoService.transform(student),
    );

    // NOTE: Related lesson dto
    copy.lesson = LessonDtoService.transform(copy.lesson);

    // NOTE: Arrear itself
    return ArrearDtoService.transform(
      copy,
      copy.lesson,
    ) as StandaloneArrearType;
  }
}
