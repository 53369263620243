import { useRef } from "react";

import { StudentCenterTabType } from "@components/StudentCenterModal/Tabs";

import { useAppStore } from "../../store/store";
import { ParticipantType } from "../../types/ParticipantType";
import { StudentType } from "../../types/StudentType";
import { Popover } from "../Popover";

import { StudentPopoverContent } from "./StudentPopoverContent";

type UserNameProps = {
  participant: ParticipantType;
};

export const StudentPopover = ({ participant }: UserNameProps) => {
  const popover = useRef<any>(null);

  const openUpsertLessonModal = useAppStore(
    (state) => state.openUpsertLessonModal,
  );
  const openStudentCenterModal = useAppStore(
    (state) => state.openStudentCenterModal,
  );

  const student = participant.details as StudentType;

  return (
    <>
      <Popover
        position={["bottom", "top", "right", "left"]}
        popover={
          <StudentPopoverContent
            participant={participant}
            onNewLesson={handleNewLesson}
            onOpenStudentCenter={handleOpenStudentCenter}
          />
        }
        ref={popover}
      >
        <span className="link StudentPopover_name">{student.name}</span>
      </Popover>
    </>
  );

  function handleNewLesson() {
    popover.current?.close();

    openUpsertLessonModal({ participant });
  }

  function handleOpenStudentCenter(tab?: StudentCenterTabType) {
    popover.current?.close();

    openStudentCenterModal(participant._id, tab);
  }
};
