import { useState } from "react";
import { Link } from "react-router-dom";

import { AddStudentModal } from "src/components/AddStudentModal";

import { Button } from "@components/Button";
import { CalendarSingleDay } from "@components/CalendarSingleDay";
import { Dropdown } from "@components/Dropdown";
import { UpsertRegularEventModal } from "@components/UpsertRegularEventModal";
import { UpsertTimeSlotModal } from "@components/UpsertTimeSlotModal";
import { ReactComponent as CalendarImg } from "@img/calendar.svg";
import { ReactComponent as ThreeDotsImg } from "@img/three_dots_horizontal.svg";
import { useAppStore } from "@store";

import { ReactComponent as GoEndImg } from "./img/go_end.svg";
import { Greeting } from "./Greeting";
import { LessonTabs } from "./LessonTabs";
import { Notifications } from "./Notifications";
import { Todos } from "./Todos";

import cs from "./DashboardPage.module.scss";

const TODAY = new Date();

export const DashboardPage = () => {
  const [isNewStudentModalOpen, setIsNewStudentModalOpen] = useState(false);
  const [isNewRegularEventModalOpen, setIsNewRegularEventModalOpen] =
    useState(false);
  const [isAddTimeSlotModalOpen, setIsAddTimeSlotModalOpen] = useState(false);
  const [selectedDay, setSelectedDay] = useState<Date>(TODAY);

  const openUpsertLessonModal = useAppStore(
    (state) => state.openUpsertLessonModal,
  );

  return (
    <main className={cs.page}>
      <section className={`dashboard-main ${cs.mainSection}`}>
        <header className={cs.header}>
          <Greeting />

          <div className={cs.header_actions}>
            <Button color="primary" onClick={() => openUpsertLessonModal()}>
              <CalendarImg width={14} height={13} />

              <span>Dodaj lekcję</span>
            </Button>

            <Dropdown
              options={[
                <div onClick={() => setIsNewStudentModalOpen(true)}>
                  Dodaj ucznia
                </div>,
                <div onClick={() => setIsAddTimeSlotModalOpen(true)}>
                  Dodaj dostępność
                </div>,
                <div onClick={() => setIsNewRegularEventModalOpen(true)}>
                  Dodaj wydarzenie
                </div>,
              ]}
            >
              <button className="btn-icon" style={{ height: 20 }}>
                <ThreeDotsImg width={17} />
              </button>
            </Dropdown>
          </div>
        </header>

        <section className={cs.scrollToCalendarSection}>
          <Link to="/calendar" className={cs.goToCalendar}>
            <span>Przejdź do kalendarza</span>

            <GoEndImg width={11} />
          </Link>

          <Link to="/finances" className={cs.goToFinances}>
            <GoEndImg width={11} />

            <span>Przejdź do finansów</span>
          </Link>
        </section>

        <Notifications />

        <Todos />

        <LessonTabs />
      </section>

      <section className={`calendar-section ${cs.calendarSection}`}>
        <CalendarSingleDay
          selectedDay={selectedDay}
          onSelectedDayChange={setSelectedDay}
        />
      </section>

      <UpsertRegularEventModal
        isOpen={isNewRegularEventModalOpen}
        onClose={() => setIsNewRegularEventModalOpen(false)}
      />

      <AddStudentModal
        isOpen={isNewStudentModalOpen}
        onClose={() => setIsNewStudentModalOpen(false)}
      />

      <UpsertTimeSlotModal
        isOpen={isAddTimeSlotModalOpen}
        onClose={() => setIsAddTimeSlotModalOpen(false)}
      />
    </main>
  );
};
