import { ReactComponent as GoogleIcon } from "./img/google_icon.svg";

import cs from "./GoogleAuthBtn.module.scss";

type GoogleAuthBtnProps = {
  type: "login" | "register";
};

export const GoogleAuthBtn = ({ type }: GoogleAuthBtnProps) => {
  return (
    <a href="/api/auth/login/google" className={cs.root}>
      <GoogleIcon width={16} />

      <span>
        {type === "login"
          ? "Zaloguj się kontem Google"
          : "Zarejestruj się używając Google"}
      </span>
    </a>
  );
};
