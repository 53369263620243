import { useEffect, useState } from "react";

import { LessonsSubList } from "@components/LessonsSubList";
import { PaginationType } from "@customTypes/PaginationType";
import { useLessons } from "@hooks/useLessons";
import { TODAY_END, TODAY_START } from "@utils/date.utils";

const PAGINATION: PaginationType = { page: 1, pageSize: 50 };

export const TabLessons = () => {
  const [expanded, setExpanded] = useState("");

  const { data: today = [] } = useLessons(
    { dateRange: [TODAY_START, TODAY_END] },
    [],
    PAGINATION,
  );

  // TODO: Later
  // const { data: incomplete = [] } = useLessons({ status: "incomplete" }, []);

  const { data: updatedHomework = [] } = useLessons(
    { status: "homework-updated" },
    ["-homework_updated_at"],
    PAGINATION,
  );

  useEffect(() => {
    if (today.length > 0) {
      setExpanded("today");
      return;
    }
    if (updatedHomework.length > 0) {
      setExpanded("updatedHomework");
      return;
    }

    setExpanded("");
  }, [today, updatedHomework]);

  return (
    <>
      <LessonsSubList
        title="Dzisiaj"
        expanded={expanded === "today"}
        items={today}
        type="lesson"
      />

      {/*<LessonsSubList*/}
      {/*  title="Oznaczone jako wymagające uzupełnienia"*/}
      {/*  items={incomplete}*/}
      {/*  type="lesson"*/}
      {/*/>*/}

      <LessonsSubList
        title="Uczeń zaktualizował zad. domowe"
        expanded={expanded === "updatedHomework"}
        items={updatedHomework}
        type="lesson"
      />
    </>
  );
};
