import { LessonPaymentStatus } from "@constants/LessonPaymentStatus";
import { StandaloneArrearType } from "@customTypes/StandaloneArrearType";
import { ReactComponent as CancelImg } from "@img/cancel.svg";
import { ReactComponent as CheckImg } from "@img/check.svg";

export function PaymentAction({
  arrear,
  onStatusChange,
}: {
  arrear: StandaloneArrearType;
  onStatusChange: (arrearId: string, status: LessonPaymentStatus) => void;
}) {
  if (arrear.status === LessonPaymentStatus.CLOSED) {
    return (
      <button
        className="btn-icon"
        title="Oznacz jako oczekującą płatności"
        onClick={() => onStatusChange(arrear._id, LessonPaymentStatus.AWAITS)}
      >
        <CancelImg width={16} />
      </button>
    );
  }

  return (
    <button
      className="btn-icon"
      title="Oznacz jako rozliczoną"
      onClick={() => onStatusChange(arrear._id, LessonPaymentStatus.CLOSED)}
    >
      <CheckImg width={18} />
    </button>
  );
}
