import { ReactComponent as ShowMoreImg } from "@img/show_more.svg";

import cs from "./StudyProgress.module.scss";

export const NodeShowMore = ({
  type,
  onClick,
}: {
  type?: "prev" | "next";
  onClick: () => void;
}) => (
  <div className={cs.row}>
    <div />

    <div className={cs.nodeShowMore} onClick={onClick}>
      <ShowMoreImg height={18} width={6} />
    </div>

    <div className={cs.actionText} onClick={onClick}>
      <span>Pokaż</span>
      <span>{type === "prev" ? " wcześniejsze " : " późniejsze "}</span>
    </div>
  </div>
);
