import { AssignedTemplatesManager } from "@components/AssignedTemplatesManager";
import { Button } from "@components/Button";
import { Modal } from "@components/Modal";
import { PlanType } from "@customTypes/PlanType";
import { formatDate } from "@utils/date.utils";

import cs from "./PlansListPage.module.scss";

type DetailsModalProps = {
  isOpen: boolean;
  activePlan: PlanType | null;
  onTemplateChange: (updatedPlan: PlanType) => void;
  onEdit: () => void;
  onDelete: () => void;
  onClose: () => void;
};

export const DetailsModal = ({
  isOpen,
  activePlan,
  onEdit,
  onTemplateChange,
  onDelete,
  onClose,
}: DetailsModalProps) => {
  if (!activePlan) return null;

  return (
    <Modal
      title="Kurs"
      isOpen={isOpen}
      className={cs.modal}
      footer={
        <Button color="primary" onClick={onClose}>
          Zamknij
        </Button>
      }
      onClose={onClose}
    >
      <div className={cs.detailsModalBody} tabIndex={0}>
        <div className={cs.sidePanelSection__main}>
          <div className="medium">Nazwa:</div>
          <div className="medium">{activePlan.name}</div>

          <div className="medium">Utworzono:</div>
          <div>{formatDate(activePlan.createdAt)}</div>

          <div className="medium">Notatka:</div>
          <div>{activePlan.note || "-"}</div>
        </div>

        <div>
          <AssignedTemplatesManager
            templates={activePlan.templates}
            onChange={(updatedTemplates) => {
              onTemplateChange({
                ...activePlan,
                templates: updatedTemplates,
              });
            }}
          />
        </div>
      </div>
    </Modal>
  );
};
