import { createAxiosInstance } from "./_createAxiosInstance";

const api = createAxiosInstance("/api/invitation");

type CreatParams = {
  studentId: string;
  email: string;
};

export class InvitationApi {
  static async create({ email, studentId }: CreatParams) {
    return api.post("", { email, studentId });
  }
}
