import { useEffect, useRef, useState } from "react";

import { StudentApi } from "@api/StudentApi";
import { Button } from "@components/Button";
import { InvitationModal } from "@components/InvitationModal";
import { Modal } from "@components/Modal";
import { PaymentLogic } from "@components/PaymentLogic";
import { Popover } from "@components/Popover";
import TabContact from "@components/StudentCenterModal/TabContact/TabContact";
import { ParticipantType } from "@customTypes/ParticipantType";
import { PaymentLogicType } from "@customTypes/PaymentLogicType";
import { StudentType } from "@customTypes/StudentType";
import { useLastNextLessonsByParticipant } from "@hooks/useLastNextLessonsByParticipant";
import { useParticipant } from "@hooks/useParticipant";
import { ReactComponent as CalendarImg } from "@img/calendar.svg";
import { ReactComponent as MessageImg } from "@img/message.svg";
import { ReactComponent as NoAccountImg } from "@img/no_account.svg";
import { ParticipantService } from "@services/ParticipantService";
import { ToastService } from "@services/ToastService";
import { useAppStore } from "@store";
import {
  addMinutes,
  formatHour,
  formatShortDate,
  formatWeekday,
} from "@utils/date.utils";

import { StudyProgress } from "./StudyProgress/StudyProgress";
import { Actions } from "./Actions";
import { LessonTabs } from "./LessonTabs";
import { NoAccountPopoverContent } from "./NoAccountPopoverContent";
import { Notifications } from "./Notifications";
import { StudentCenterTabType, Tabs } from "./Tabs";
import { Todos } from "./Todos";

import cs from "./StudentCenterModal.module.scss";

type StudentCenterModalProps = {
  isOpen: boolean;
  participantId: string;
  tab?: StudentCenterTabType;
  onClose: () => void;
};

export const StudentCenterModal = ({
  isOpen,
  participantId,
  tab,
  onClose,
}: StudentCenterModalProps) => {
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState<StudentCenterTabType>("general");
  const [paymentLogic, setPaymentLogic] = useState<any>({});

  const noAccountPopover = useRef<any>();
  const setPaymentLogicTimer = useRef<any>(null);

  const { data: participant } = useParticipant(participantId);
  const { data: lessons } = useLastNextLessonsByParticipant(participantId);
  const openUpsertLessonModal = useAppStore(
    (state) => state.openUpsertLessonModal,
  );

  useEffect(() => {
    if (!isOpen || !participant?.details.paymentLogic) return;

    setPaymentLogic(participant.details.paymentLogic);
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && tab) {
      setActiveTab(tab);
    }
  }, [tab, isOpen]);

  if (!participant) return null;

  const student = participant.details as StudentType;
  const mainEmail = student.mainContact?.email;
  const mainPhone = student.mainContact?.phone;

  return (
    <Modal
      title="CENTRUM UCZNIA"
      isOpen={isOpen}
      footer={null}
      className={cs.modal}
      onClose={handleClose}
    >
      <div className={cs.root}>
        <div className={cs.header}>
          <div className={cs.header_left}>
            <div className={cs.studentName}>
              <span>{student.name}</span>

              {!student.hasAccount && (
                <Popover
                  popover={<NoAccountPopoverContent onInvite={handleInvite} />}
                  position={["bottom"]}
                  className={cs.noAccountPopover}
                  ref={noAccountPopover}
                >
                  <button className="btn-icon">
                    <NoAccountImg width={18} />
                  </button>
                </Popover>
              )}

              {student.note && (
                <Popover
                  popover={<div>{student.note}</div>}
                  position={["bottom"]}
                  className={cs.descPopover}
                >
                  <button className="btn-icon">
                    <MessageImg width={18} />
                  </button>
                </Popover>
              )}
            </div>

            <div className={cs.header_actions}>
              <Button
                color="primary"
                onClick={() => openUpsertLessonModal({ participant })}
              >
                <CalendarImg width={14} height={13} />

                <span>Dodaj lekcję</span>
              </Button>

              <Actions student={student} />
            </div>
          </div>

          <div className={cs.header_right}>
            <div className={cs.header_date}>
              <div className={cs.header_dateLabel}>NAJBLIŻSZA LEKCJA</div>
              <div>{formatPrevNextLessonsDate(lessons?.nextLesson)}</div>
            </div>

            <div className={cs.header_date}>
              <div className={cs.header_dateLabel}>POPRZEDNIA LEKCJA</div>
              <div>{formatPrevNextLessonsDate(lessons?.lastLesson)}</div>
            </div>

            <div className={cs.header_contact}>
              {mainEmail && (
                <a href={`mailto:${mainEmail}`} className="link">
                  {mainEmail}
                </a>
              )}

              {mainPhone && (
                <a href={`tel:${mainPhone}`} className="link">
                  {mainPhone}
                </a>
              )}
            </div>
          </div>
        </div>

        <div className={cs.main}>
          <div className={cs.left}>
            <Tabs activeTab={activeTab} onChange={setActiveTab} />

            {activeTab === "general" && (
              <>
                <Notifications participantId={participant._id} />

                <Todos participant={participant} />

                <LessonTabs
                  studentId={student._id}
                  participantId={participant._id}
                />
              </>
            )}

            {activeTab === "contact" && (
              <TabContact studentId={student._id} contact={student.contact} />
            )}

            {activeTab === "settings" && (
              <>
                <section style={{ width: "100%", maxWidth: "100%" }}>
                  <header className={cs.sectionHeader}>
                    <span>Sposób rozliczania</span>
                  </header>

                  <PaymentLogic
                    paymentLogic={paymentLogic}
                    onChange={handlePaymentLogicChanged}
                  />
                </section>
              </>
            )}
          </div>

          <div className={cs.right}>
            <StudyProgress participant={participant} />
          </div>
        </div>
      </div>

      <InvitationModal
        isOpen={isInviteModalOpen}
        student={student}
        onClose={() => setIsInviteModalOpen(false)}
      />
    </Modal>
  );

  function formatPrevNextLessonsDate(lesson: StudentType["lastLesson"]) {
    if (!lesson) return "-";

    const { date, duration } = lesson;

    const dateStr = formatShortDate(date);
    const weekDayStr = formatWeekday(date, "short").replace(".", "");
    const hour = formatHour(date);

    const endDate = addMinutes(date, duration);
    const endHourStr = formatHour(endDate);

    return (
      <div>
        <p>
          {dateStr} ({weekDayStr})
        </p>
        <p>
          {hour}-{endHourStr}
        </p>
      </div>
    );
  }

  function handleInvite() {
    noAccountPopover.current.close();

    setIsInviteModalOpen(true);
  }

  async function handlePaymentLogicChanged(paymentLogic: PaymentLogicType) {
    setPaymentLogic(paymentLogic);

    if (setPaymentLogicTimer.current) {
      clearTimeout(setPaymentLogicTimer.current);
    }

    setPaymentLogicTimer.current = setTimeout(async () => {
      await StudentApi.updatePaymentLogic({
        studentId: student._id,
        paymentLogic,
      });

      ToastService.success("Zmiany zostały zapisane.");

      const updatedParticipant = {
        ...participant,
        details: { ...student, paymentLogic },
      } as ParticipantType;

      ParticipantService.updateCache(updatedParticipant);
    }, 1500);
  }

  function handleClose() {
    onClose();
    setActiveTab("general");
  }
};
