import { useMutation, useQueryClient } from "react-query";

import { MaterialApi } from "@api/MaterialApi";

export const useReplaceMaterial = () => {
  const queryClient = useQueryClient();

  return useMutation("materials", MaterialApi.replace, {
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["materials"],
        refetchInactive: true,
      });
    },
  });
};
