import { createAxiosInstance } from "./_createAxiosInstance";

const api = createAxiosInstance("/api/demo");

let called = false;

export class DemoApi {
  static async create() {
    if (called) return Promise.resolve({});

    called = true;

    const response = await api.post("");

    return response.data;
  }
}
