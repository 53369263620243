import { Checkbox } from "../Checkbox";

import cs from "./Search.module.scss";

type SearchPopoverContentProps = {
  items: any[];
  selected?: any[];
  labelField: string;
  valueField: string;
  multi?: boolean;
  onToggle: (checked: boolean, itemValue: string) => void;
  onSelect: (item: any) => void;
};

export const SearchPopoverContent = ({
  items,
  selected,
  valueField,
  labelField,
  multi,
  onSelect,
  onToggle,
}: SearchPopoverContentProps) => {
  return (
    <div className={cs.list}>
      {items.map((item) => (
        <div key={item[valueField]} className={cs.listItem}>
          {multi ? (
            <Checkbox
              checked={(selected || []).includes(item[valueField])}
              label={item[labelField]}
              value={item[valueField]}
              onChange={onToggle}
            />
          ) : (
            <div style={{ width: "100%" }} onClick={() => onSelect(item)}>
              {item[labelField]}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
