import { useMutation, useQueryClient } from "react-query";

import { StudentApi } from "@api/StudentApi";

export const useReplaceStudent = () => {
  const queryClient = useQueryClient();

  // TODO: Needs optimalization. Doesn't make sense to fetch all students
  //       if only one was updated. Maybe optimistic update is enought here
  //       and there's no need to invalidate queries
  return useMutation("students", StudentApi.replace, {
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["students"],
        refetchInactive: true,
      });

      await queryClient.invalidateQueries({
        queryKey: ["participants"],
        refetchInactive: true,
      });
    },
  });
};
