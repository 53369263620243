import { useEffect, useRef, useState } from "react";

import { Button } from "@components/Button";
import { Checkbox } from "@components/Checkbox";
import { DatePicker } from "@components/DatePicker";
import { InfoBox } from "@components/InfoBox";
import { Modal } from "@components/Modal";
import { TimeSlotType } from "@customTypes/TimeSlotType";
import { useAddTimeSlot } from "@hooks/useAddTimeSlot";
import { addMinutes, diffMinutes } from "@utils/date.utils";

import cs from "./UpsertTimeSlotModal.module.scss";

type UpsertRegularEventModalProps = {
  isOpen: boolean;
  timeSlot?: Partial<TimeSlotType>;
  onClose: () => void;
};

export const UpsertTimeSlotModal = ({
  isOpen,
  timeSlot,
  onClose,
}: UpsertRegularEventModalProps) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endHour, setEndHour] = useState<Date | null>(null);
  const [notify, setNotify] = useState(false);

  const formElement = useRef<HTMLFormElement>(null);
  const endHourElement = useRef<HTMLInputElement>(null);

  const addTimeSlot = useAddTimeSlot();

  useEffect(() => {
    if (isOpen && timeSlot) {
      timeSlot.date && setStartDate(timeSlot.date);

      if (timeSlot.date) {
        setEndHour(addMinutes(timeSlot.date, 60));
      }
    }
  }, [timeSlot, isOpen]);

  return (
    <Modal
      title="Dodaj dostępność"
      isOpen={isOpen}
      className={cs.modal}
      helpTooltip="Czym jest Dostępność?"
      help={`Utwórz Dostępność, aby poinformować swoich uczniów o wolnym czasie w Twoim kalendarzu. Zaznacz "Wyślij powiadomienie o dostępności", a każdy z Twoich uczniów otrzyma notyfikację, że Dostępność została utworzona w Twoim kalendarzu. Uczniowie mogą odpowiadać na Dostępność, proponując lekcje. Uwaga: funkcja dostępna tylko dla uczniów, którzy posiadają konto w aplikacji.`}
      footer={
        <Button
          color="primary"
          isLoading={addTimeSlot.isLoading}
          onClick={handleConfirm}
        >
          Dodaj
        </Button>
      }
      clickOutsideToClose={false}
      onClose={onClose}
    >
      <form ref={formElement} className={cs.form} tabIndex={0}>
        <DatePicker
          label="Data i godzina rozpoczęcia"
          mode="datetime"
          value={startDate}
          required
          onChange={handleStartDateSelected}
        />

        <DatePicker
          label="Godzina zakończenia"
          mode="time"
          value={endHour}
          required
          ref={endHourElement}
          onChange={setEndHour}
        />

        <div className={cs.checkboxRow}>
          <Checkbox
            checked={notify}
            label="Wyślij powiadomienie o dostępności"
            onChange={setNotify}
          />

          <InfoBox desc="Do Twoich uczniów, którzy posiadają konto w aplikacji, zostanie wysłane powiadomienie mailowe." />
        </div>
      </form>
    </Modal>
  );

  function handleStartDateSelected(startDate: Date) {
    setStartDate(startDate);
    setEndHour(addMinutes(startDate, 60));
  }

  async function handleConfirm() {
    const duration = diffMinutes(endHour as Date, startDate as Date);

    if (!(duration > 0)) {
      endHourElement.current?.setCustomValidity(
        "Godzina zakończenia musi być większa od godziny rozpoczęcia",
      );
    } else {
      endHourElement.current?.setCustomValidity("");
    }

    const isValid = formElement.current?.reportValidity();

    if (!isValid) return;

    await addTimeSlot.mutateAsync({
      timeSlot: {
        date: startDate as Date,
        duration,
      },
      notify,
    });

    onClose();
    clear();
  }

  function clear() {
    setStartDate(null);
    setEndHour(null);
    setNotify(false);
  }
};
