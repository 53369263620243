// @ts-nocheck
import { Button } from "@components/Button";
import { Calendar } from "@components/Calendar";
import { ReactComponent as RemoveImg } from "@img/remove.svg";

import { FilterStudents } from "./FilterStudents";

import cs from "./LessonsListPage.module.scss";

export const Filters = ({ filters, onChange, onClear }) => {
  return (
    <section className={cs.filters}>
      <div className="mb24">
        <div className={cs.filters_title}>Data lekcji</div>
        <Calendar
          selectRange
          value={filters.dateRange}
          onChange={handleDatesChanged}
        />
      </div>

      <div>
        <FilterStudents
          selectedParticipantIds={filters.participants}
          onChange={onChange}
        />
      </div>

      <div className="f-center mt35">
        <Button
          color="dark"
          type="outlined"
          className={cs.filters_clearBtn}
          onClick={onClear}
        >
          <RemoveImg width={11} height={11} />
          <span>Wyczyść filtry</span>
        </Button>
      </div>
    </section>
  );

  function handleDatesChanged(dateRange: [Date, Date]) {
    onChange("dateRange", dateRange);
  }
};
