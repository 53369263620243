import { useEffect, useState } from "react";

import { useImmer } from "use-immer";

import { Button } from "@components/Button";
import { Table } from "@components/Table";
import { UpsertLessonModal } from "@components/UpsertLessonModal";
import { LessonType } from "@customTypes/LessonType";
import { useDeleteLesson } from "@hooks/useDeleteLesson";
import { useLessons } from "@hooks/useLessons";
import { ReactComponent as CalendarImg } from "@img/calendar.svg";
import { ReactComponent as FiltersImg } from "@img/filters.svg";
import { useAppStore } from "@store";

import { columns } from "./columns";
import { Filters } from "./Filters";
import { FiltersModal } from "./FiltersModal";
import { SidePanel } from "./SidePanel";

import cs from "./LessonsListPage.module.scss";

const FILTERS_INIT = {
  dateRange: [null, null],
  participants: [],
};

export const LessonsListPage = () => {
  const [isNewLessonModalOpen, setIsNewLessonModalOpen] = useState(false);
  const [isEditLessonModalOpen, setIsEditLessonModalOpen] = useState(false);
  const [activeLesson, setActiveLesson] = useState<LessonType | null>(null);
  const [filters, setFilters] = useImmer<any>(FILTERS_INIT);
  const [filtersModalOpen, setFiltersModalOpen] = useState(false);

  const [sort, handleSort] = Table.useSort();
  const [pagination, setPagination] = Table.usePagination();

  const { data = [], isLoading } = useLessons(
    filters,
    Array.from(sort),
    pagination,
  );

  const deleteLesson = useDeleteLesson();

  const openConfirmationModal = useAppStore(
    (state) => state.openConfirmationModal,
  );

  useEffect(() => {
    // NOTE: Check if active lesson hasn't changed after data was updated
    if (activeLesson) {
      const updatedActiveLesson = data.find(
        (lesson) => lesson._id === activeLesson._id,
      );

      setActiveLesson(updatedActiveLesson || null);
    }
  }, [data, activeLesson]);

  return (
    <main className={cs.page}>
      <header className={cs.header}>
        <div className={cs.openFiltersBtn}>
          <Button
            color="neutral"
            type="outlined"
            className="hide-desktop-up mr-auto"
            onClick={() => setFiltersModalOpen(true)}
          >
            <FiltersImg width={12} />
            <span>Filtruj</span>
          </Button>
        </div>

        <Button color="primary" onClick={() => setIsNewLessonModalOpen(true)}>
          <CalendarImg width={14} height={13} />

          <span>Dodaj lekcję</span>
        </Button>
      </header>

      {!activeLesson && (
        <Filters
          filters={filters}
          onChange={handleFilterChanged}
          onClear={handleClearFilters}
        />
      )}

      <section className={cs.table}>
        <Table
          data={data}
          columns={columns}
          sort={sort}
          pagination={pagination}
          empty={{
            title: "Lista spotkań jest jeszcze pusta.",
            extra: (
              <div>
                <Button
                  color="primary"
                  className="mt24"
                  onClick={() => setIsNewLessonModalOpen(true)}
                >
                  <CalendarImg width={14} height={13} />

                  <span>Dodaj pierwszą lekcję</span>
                </Button>
              </div>
            ),
          }}
          isLoading={isLoading}
          onSort={handleSort}
          onRowClick={setActiveLesson}
          onPaginationChange={setPagination}
        />
      </section>

      <SidePanel
        activeLesson={activeLesson}
        onNext={handleNext}
        onPrev={handlePrev}
        onClose={() => setActiveLesson(null)}
        onEdit={() => setIsEditLessonModalOpen(true)}
        onDelete={handleDelete}
      />

      <UpsertLessonModal
        isOpen={isNewLessonModalOpen}
        onClose={() => setIsNewLessonModalOpen(false)}
      />

      <UpsertLessonModal
        isOpen={isEditLessonModalOpen}
        lesson={activeLesson}
        onClose={() => setIsEditLessonModalOpen(false)}
      />

      <FiltersModal
        isOpen={filtersModalOpen}
        filters={filters}
        onChange={handleFilterChanged}
        onClear={handleClearFilters}
        onClose={() => setFiltersModalOpen(false)}
      />
    </main>
  );

  async function handleDelete() {
    const verb = activeLesson?.isPast ? "usunąć" : "odwołać";

    const isConfirmed = await openConfirmationModal({
      confirmText: "Usuń",
      text: `Czy na pewno chcesz ${verb} tę lekcję?`,
    });

    if (isConfirmed && activeLesson) {
      await deleteLesson.mutateAsync({ lessonId: activeLesson._id });
      setActiveLesson(null);
    }
  }

  function handleFilterChanged(filterKey: string, value: any) {
    setFilters((prev: any) => void (prev[filterKey] = value));
    resetPagination();
  }

  function handleClearFilters() {
    setFilters(FILTERS_INIT);
    resetPagination();
  }

  function handleNext() {
    // eslint-disable-next-line eqeqeq
    const currIndex = data.findIndex((el) => el == activeLesson);
    let nextLesson = data[currIndex + 1];

    if (!nextLesson) {
      nextLesson = data[0];
    }

    setActiveLesson(nextLesson);
  }

  function handlePrev() {
    // eslint-disable-next-line eqeqeq
    const currIndex = data.findIndex((el) => el == activeLesson);
    let prevLesson = data[currIndex - 1];

    if (!prevLesson) {
      prevLesson = data.at(-1) as LessonType;
    }

    setActiveLesson(prevLesson);
  }

  function resetPagination() {
    setPagination({ page: 1, pageSize: pagination.pageSize });
  }
};
