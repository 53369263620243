import { useCurrentUser } from "@hooks/useCurrentUser";

import { ReactComponent as ExclamationSvg } from "./img/exclamation.svg";

import cs from "./AccountBlockedBanner.module.scss";

export const AccountBlockedBanner = () => {
  const { currentUser } = useCurrentUser();

  if (!currentUser?.isBlocked) return null;

  return (
    <div className={cs.root}>
      <ExclamationSvg />

      <div>
        Konto zablokowane - opłać subskrypcję, aby odblokować dostęp do
        aplikacji.
      </div>
    </div>
  );
};
