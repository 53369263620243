import { useMutation, useQueryClient } from "react-query";

import { LessonApi } from "@api/LessonApi";

export const useUpdateLesson = () => {
  const queryClient = useQueryClient();

  return useMutation("lessons", LessonApi.update, {
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["lessons"],
        refetchInactive: true,
      });

      await queryClient.invalidateQueries({
        queryKey: ["calendar"],
        refetchInactive: true,
      });

      await queryClient.invalidateQueries({
        queryKey: ["standalone-arrears"],
        refetchInactive: false,
      });
    },
  });
};
