import { createAxiosInstance } from "./_createAxiosInstance";

const api = createAxiosInstance("/api/billing");

export class BillingsApi {
  static async fetchAllBills() {
    const { data } = await api.get("/bills");

    return data;
  }

  static async getCurrentBillingPackage() {
    const { data } = await api.get("/package");

    return data;
  }

  static async createCheckoutSession(billId: string) {
    const { data } = await api.post("/payment/" + billId);

    return data;
  }
}
