import { StudentType } from "../../types/StudentType";

import cs from "./Todo.module.scss";

type StudentInitialsProps = {
  student: StudentType;
};

export const StudentInitials = ({ student }: StudentInitialsProps) => {
  const { firstName, lastName, name } = student;

  return (
    <div className={cs.initials} title={name}>
      {firstName[0]}
      {lastName[0]}
    </div>
  );
};
