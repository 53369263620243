import { useMutation, useQueryClient } from "react-query";

import { NotificationApi } from "../api/NotificationApi";

export const useDeleteAllNotifications = (participantId?: string) => {
  const queryClient = useQueryClient();

  return useMutation(() => NotificationApi.deleteAll(participantId), {
    onSuccess: () => {
      return queryClient.invalidateQueries("notifications");
    },
  });
};
