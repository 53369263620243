import { useEffect, useRef, useState } from "react";

import { Button } from "@components/Button";
import { DatePicker } from "@components/DatePicker";
import { Modal } from "@components/Modal";
import { Select } from "@components/Select";
import {
  calendarViewOptions,
  CalendarViewOptionType,
} from "@constants/CalendarViewOptions";
import { useCurrentUser } from "@hooks/useCurrentUser";
import { useReplaceCurrentUser } from "@hooks/useReplaceCurrentUser";

import cs from "./CalendarSettingsModal.module.scss";

type InvitationModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const CalendarSettingsModal = ({
  isOpen,
  onClose,
}: InvitationModalProps) => {
  const [start, setStart] = useState<Date | null>(null);
  const [end, setEnd] = useState<Date | null>(null);
  const [defaultView, setDefaultView] = useState<CalendarViewOptionType>();

  const formElement = useRef<HTMLFormElement>(null);
  const endTimeInput = useRef<any>(null);

  const { currentUser } = useCurrentUser();
  const replaceCurrentUser = useReplaceCurrentUser();

  useEffect(() => {
    if (isOpen && currentUser) {
      const workStartHour = currentUser.settingWorkStartHour;
      const workEndHour = currentUser.settingWorkEndHour;

      setStart(initHour(workStartHour));
      setEnd(initHour(workEndHour));
      setDefaultView(
        calendarViewOptions.find(
          (option) => option.value === currentUser.settingCalendarDefaultView,
        ),
      );
    }
  }, [isOpen, currentUser]);

  return (
    <Modal
      title="Ustawienia"
      isOpen={isOpen}
      className={cs.modal}
      footer={
        <Button
          color="primary"
          isLoading={replaceCurrentUser.isLoading}
          onClick={handleConfirm}
        >
          Zapisz
        </Button>
      }
      onClose={onClose}
    >
      <form ref={formElement}>
        <div className={cs.defaultViewSection}>
          <Select
            options={calendarViewOptions}
            value={defaultView?.label}
            label="Widok domyślny"
            required
            onChange={(option: CalendarViewOptionType) =>
              setDefaultView(option)
            }
          />
        </div>

        <DatePicker
          value={start}
          mode="time"
          label="Godzina rozpoczęcia"
          timeIntervals={60}
          required
          onChange={(value) => {
            setStart(value);
            endTimeInput.current.setCustomValidity("");
          }}
        />

        <div className="mt8">
          <DatePicker
            value={end}
            mode="time"
            label="Godzina zakończenia"
            timeIntervals={60}
            required
            ref={endTimeInput}
            onChange={(value) => {
              setEnd(value);
              endTimeInput.current.setCustomValidity("");
            }}
          />
        </div>
      </form>
    </Modal>
  );

  async function handleConfirm() {
    if (start && end) {
      const startNb = start.valueOf();
      const endNb = end.valueOf();

      if (endNb <= startNb) {
        endTimeInput.current.setCustomValidity(
          "Godzina zakończenia nie może być mniejsza niż godzina rozpoczęcia.",
        );
        endTimeInput.current.reportValidity();

        return;
      }
    }

    const isValid = formElement.current?.reportValidity();

    if (!isValid) return;

    await replaceCurrentUser.mutateAsync({
      currentUser: {
        ...currentUser,
        settingWorkStartHour: start?.getHours(),
        settingWorkEndHour: end?.getHours(),
        settingCalendarDefaultView: defaultView?.value,
      },
    });

    onClose();
  }
};

function initHour(hour: number) {
  return new Date(new Date().setHours(hour, 0, 0, 0));
}
