import { LessonType } from "../../types/LessonType";
import { formatShortDate, formatWeekday } from "../../utils/date.utils";
import { LessonBulb } from "../LessonBulb";
import { LessonDetails } from "../LessonDetails";

import calendarImg from "./img/calendar.svg";
import { Homeworks } from "./Homeworks";
import { IncompleteIndicator } from "./IncompleteIndicator";

import cs from "./LessonBox.module.scss";

type LessonBoxProps = {
  lesson: LessonType;
};

export const LessonBox = ({ lesson }: LessonBoxProps) => {
  const date = formatShortDate(lesson.date);
  const weekday = formatWeekday(lesson.date, "long");

  return (
    <LessonDetails lesson={lesson}>
      <div className={cs.root}>
        <img src={calendarImg} alt="" />

        <div>
          <div className={cs.topInfo}>
            <LessonBulb past={lesson.isPast} size="small" />

            <span>
              {date} ({weekday}),
            </span>

            <span>
              {lesson.startHourStr}-{lesson.endHourStr};
            </span>

            <span className={cs.students}>
              {lesson.participant.details.name}
            </span>
          </div>

          <div className={cs.topicContainer}>
            <span className={cs.topic} title={lesson.topic}>
              {lesson.topic}
            </span>

            {lesson.incomplete && <IncompleteIndicator />}

            {lesson.completedHomeworks?.length > 0 && (
              <Homeworks homeworks={lesson.completedHomeworks} />
            )}
          </div>
        </div>
      </div>
    </LessonDetails>
  );
};
