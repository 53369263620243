import { ContactType } from "@customTypes/ContactType";
import { ReactComponent as PencilImg } from "@img/pencil2.svg";
import { ReactComponent as TrashImg } from "@img/trash_2.svg";

type ColumnType = {
  onEditContact: (contact: ContactType) => void;
  onDeleteContact: (contactId: string) => void;
};

export const columns = ({ onEditContact, onDeleteContact }: ColumnType) => [
  {
    title: "Kontakt",
    field: "contact",
    render(_: any, __: any, contact: ContactType) {
      return (
        <div>
          {contact.email && (
            <div>
              <a href={`mailto:${contact.email}`} className="link regular">
                {contact.email}
              </a>
            </div>
          )}

          {contact.phone && (
            <div>
              <a href={`tel:${contact.phone}`} className="link regular">
                {contact.phone}
              </a>
            </div>
          )}
        </div>
      );
    },
  },
  {
    title: "Notatka",
    field: "note",
    hideWidth: 600,
  },
  {
    title: "Główny",
    field: "isMain",
    type: "boolean",
    center: true,
    width: 90,
    hideWidth: 500,
  },
  {
    title: "",
    field: "",
    type: "boolean",
    width: 70,
    center: true,
    render(value: any, col: any, contact: ContactType) {
      return (
        <>
          <button
            className="btn-icon mr8"
            onClick={() => onEditContact(contact)}
          >
            <PencilImg width={14} />
          </button>

          <button
            className="btn-icon"
            onClick={() => onDeleteContact(contact._id)}
          >
            <TrashImg width={12} />
          </button>
        </>
      );
    },
  },
];
