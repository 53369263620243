import { LessonDetails } from "@components/LessonDetails";
import { PaymentStatus } from "@components/PaymentStatus";
import { LessonPaymentStatus } from "@constants/LessonPaymentStatus";
import { StandaloneArrearType } from "@customTypes/StandaloneArrearType";
import { ReactComponent as OpenImg } from "@img/open.svg";
import { LessonDate } from "@pages/LessonsListPage/columns";
import {
  diff,
  formatDate,
  formatHour,
  formatShortDate,
  formatWeekday,
  toRelative,
} from "@utils/date.utils";

import { ReactComponent as CancelImg } from "./img/cancel.svg";
import { ReactComponent as CheckImg } from "./img/check.svg";

import cs from "./FinancesListPage.module.scss";

type PropsType = {
  onStatusChange: (arrearId: string, status: LessonPaymentStatus) => void;
};

export const columns = ({ onStatusChange }: PropsType) => [
  // TODO: LATER
  // {
  //   field: "_id",
  //   title: <Checkbox checked={false} />,
  //   width: 39,
  //   render(field: string) {
  //     return <Checkbox checked={false} />;
  //   },
  // },
  {
    title: "Data lekcji",
    field: "date",
    sortable: true,
    width: 180,
    render(field: any, col: any, row: StandaloneArrearType) {
      return (
        <LessonDetails lesson={row.lesson}>
          <span
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              gap: "12px",
            }}
            title="Otwórz lekcję"
          >
            <OpenImg width={12} style={{ flexShrink: 0 }} />

            <LessonDate lesson={row.lesson} />
          </span>
        </LessonDetails>
      );
    },
  },
  {
    title: "Uczeń",
    field: "students",
    width: 180,
    render(field: StandaloneArrearType["students"]) {
      const [firstStudent] = field;
      return <span>{firstStudent.shortName}</span>;
    },
  },
  {
    title: "Status",
    field: "status",
    help: `Status "Oczekuje" oznacza, że lekcja nie została jeszcze opłacona. Gdy termin płatności mija, a lekcja pozostaje nieopłacona, status zmienia się na "Po terminie". "Opłacono" to informacja dla Ciebie, że uczeń (posiadający konto w aplikacji) oznaczył płatność jako wykonaną. Sprawdź swoje konto bankowe - jeśli płatność została dokonana, potwierdź tę akcję w aplikacji, a status zmieni się na "Rozliczono".`,
    width: 140,
    render(
      field: StandaloneArrearType["status"],
      col: any,
      row: StandaloneArrearType,
    ) {
      return <PaymentStatus arrears={[row]} />;
    },
  },
  {
    title: "Termin",
    width: 140,
    field: "paymentDeadline",
    sortable: true,
    render(field: any, col: any, row: StandaloneArrearType) {
      const deadline = row.lesson.paymentDeadline;
      const rootCs = row.isAfterDeadline ? "red" : "";

      if (!deadline) {
        return <span>-</span>;
      }

      const fullDate = formatDate(deadline, "full");
      const daysAfterDeadline = diff(new Date(), deadline);

      // NOTE: Four days or more to deadline
      if (daysAfterDeadline < -4 || daysAfterDeadline > 4) {
        const date = formatShortDate(deadline);
        const weekday = formatWeekday(deadline);

        return (
          <span className={rootCs} title={fullDate} style={{ cursor: "help" }}>
            {date}, {weekday}
          </span>
        );
      }

      const relativeTime = toRelative(deadline);

      return (
        <span
          className={rootCs}
          title={fullDate}
          style={{ cursor: "help", whiteSpace: "normal" }}
        >
          {relativeTime}
        </span>
      );
    },
  },
  {
    title: "Cena",
    width: 120,
    render(field: any, col: any, row: StandaloneArrearType) {
      return <span>{row.lesson.price || "-"} zł</span>;
    },
  },
  {
    title: "Aktualizacja",
    field: "updatedAt",
    width: 140,
    hideWidth: 500,
    help: (
      <div>
        <span>
          Aktualne informacje o płatnościach pomagają Ci kontrolować swoje
          finanse. Każda nowo utworzona lekcja domyślnie otrzymuje status
          nieopłaconej. Zaznacz{" "}
        </span>
        <CheckImg width={16} />
        <span>
          , aby oznaczyć, że lekcja została opłacona przez ucznia. Tą czynność
          możesz cofnąć za pomocą{" "}
        </span>
        <CancelImg width={12} />
        <span>. Status płatności możesz modyfikować dowolną ilość razy.</span>
      </div>
    ),
    sortable: true,
    render(field: StandaloneArrearType["updatedAt"]) {
      if (!field) {
        return <span>-</span>;
      }

      const fullDate = formatDate(field, "full");
      const hour = formatHour(field);
      const tooltip = fullDate + ", " + hour;

      const daysFromNow = diff(new Date(), field);

      if (daysFromNow > 4) {
        const date = formatShortDate(field);
        const weekday = formatWeekday(field);

        return (
          <span className={cs.paymentModifiedAtCol} title={tooltip}>
            <p>
              {date}, {weekday}
            </p>
            <p>{hour}</p>
          </span>
        );
      }

      const relativeTime = toRelative(field);

      return (
        <span title={tooltip} style={{ cursor: "help" }}>
          {relativeTime}
        </span>
      );
    },
  },
  {
    title: "",
    field: "status",
    width: 100,
    center: true,
    render(
      field: StandaloneArrearType["status"],
      col: any,
      row: StandaloneArrearType,
    ) {
      if (field === LessonPaymentStatus.CLOSED) {
        return (
          <button
            className="btn-icon"
            title="Oznacz jako oczekującą płatności"
            onClick={() => onStatusChange(row._id, LessonPaymentStatus.AWAITS)}
          >
            <CancelImg width={16} />
          </button>
        );
      }

      return (
        <button
          className="btn-icon"
          title="Oznacz jako rozliczoną"
          onClick={() => onStatusChange(row._id, LessonPaymentStatus.CLOSED)}
        >
          <CheckImg width={18} />
        </button>
      );
    },
  },
];
