import { useMutation, useQueryClient } from "react-query";

import { RegularEventApi } from "../api/RegularEventApi";

export const useAddRegularEvent = () => {
  const queryClient = useQueryClient();

  return useMutation("regular-events", RegularEventApi.create, {
    onSuccess() {
      return queryClient.invalidateQueries({
        queryKey: ["calendar"],
        refetchInactive: true,
      });
    },
  });
};
