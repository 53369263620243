import { useRef } from "react";

import { Button } from "@components/Button";
import { useTouchedCell } from "@components/CalendarSingleDay/TouchedCell";
import { Popover } from "@components/Popover";
import { useAppStore } from "@store";
import { formatDate } from "@utils/date.utils";

import cs from "./WeekView.module.scss";

export const AddEvent = ({
  calendarHour,
  onNewTimeSlot,
  onNewRegularEvent,
}) => {
  const [touchedCell, setTouchedCell] = useTouchedCell();

  const actionsPopoverEl = useRef<any>();

  const openUpsertLessonModal = useAppStore(
    (state) => state.openUpsertLessonModal,
  );

  const isTouched = touchedCell === calendarHour;

  return (
    <>
      <Popover
        popover={
          <div>
            <Button
              type="outlined"
              color="primary"
              className={cs.action}
              onClick={() => {
                actionsPopoverEl.current.close();
                openUpsertLessonModal({ date: calendarHour.date });
              }}
            >
              + Lekcję
            </Button>
            <Button
              type="outlined"
              color="secondary"
              className={cs.action}
              onClick={() => {
                actionsPopoverEl.current.close();
                onNewTimeSlot(calendarHour.date);
              }}
            >
              + Dostępność
            </Button>
            <Button
              type="outlined"
              color="dark"
              className={cs.action}
              onClick={() => {
                actionsPopoverEl.current.close();
                onNewRegularEvent(calendarHour.date);
              }}
            >
              + Wydarzenie
            </Button>
          </div>
        }
        position={["bottom"]}
        adjustWidth
        square
        className={cs.actionsPopover}
        ref={actionsPopoverEl}
      >
        <div
          className={cs.clickableBg}
          title={`${calendarHour.hour}:00, ${formatDate(
            calendarHour.date,
            "full",
          )}`}
        >
          <div
            style={{ width: "100%", height: "100%" }}
            className={isTouched ? cs.touched : ""}
            onTouchEnd={(e) => {
              if (!isTouched) {
                e.stopPropagation();
                e.preventDefault();

                setTouchedCell(calendarHour);
                document.body.click();
              } else {
                setTouchedCell(null);
              }
            }}
          >
            {isTouched && (
              <div>
                <div className="text-center" style={{ fontSize: 20 }}>
                  +
                </div>
                <div className="text-center" style={{ fontSize: 14 }}>
                  {calendarHour.hour}
                  {":00"}
                </div>
              </div>
            )}
          </div>
        </div>
      </Popover>
    </>
  );
};
