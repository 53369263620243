// @ts-nocheck

import cloneDeep from "lodash.clonedeep";

import { MaterialDtoType } from "../types/MaterialDtoType";
import { MaterialType } from "../types/MaterialType";

export class MaterialDtoService {
  static transformAll(materialDtos: MaterialDtoType[]): MaterialType[] {
    const copy = cloneDeep(materialDtos);

    copy.forEach((material: any) => {
      if (material.type === "folder") {
        attachChildren(material, copy);
      }

      const ancestors = [];

      attachAncestors(material, copy, ancestors);

      material.ancestors = ancestors;
    });

    return copy as MaterialType[];
  }
}

function attachChildren(material, allMaterials) {
  material.immediateChildren = allMaterials
    .filter((child) => child.parentId === material._id)
    .map(toSimpleMaterialObject);

  material.immediateChildren.forEach((child) => {
    attachChildren(child, allMaterials);
  });
}

function attachAncestors(material, allMaterials, ancestors) {
  if (material.parentId) {
    const parent = allMaterials.find(({ _id }) => _id === material.parentId);

    ancestors.unshift(toSimpleMaterialObject(parent));

    attachAncestors(parent, allMaterials, ancestors);
  }
}

function toSimpleMaterialObject(material) {
  return {
    _id: material._id,
    name: material.name,
    type: material.type,
  };
}
