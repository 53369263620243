import { NotificationKind } from "@constants/NotificationKind";
import { NotificationDtoType } from "@customTypes/NotificationDtoType";
import { NotificationType } from "@customTypes/NotificationType";
import { LessonDtoService } from "@services/LessonDtoService";
import { ParticipantDtoService } from "@services/ParticipantDtoService";
import { TimeSlotDtoService } from "@services/TimeSlotDtoService";
import { getState } from "@store";

export class NotificationDtoService {
  static transformAll(notifications: NotificationDtoType[]) {
    return notifications.map((notification) =>
      NotificationDtoService.transform(notification),
    );
  }

  static transform(notification: NotificationDtoType): NotificationType {
    const participants = getState().participants;

    if (
      NotificationKind.LessonCancelled === notification.type ||
      NotificationKind.LessonPaid === notification.type ||
      NotificationKind.HomeworkUpdated === notification.type
    ) {
      notification.data.event = LessonDtoService.transform(
        notification.data.event,
      );
      notification.data.participant = ParticipantDtoService.transform(
        notification.data.participant,
      );
    } else if (notification.type === NotificationKind.NewTimeSlotApplication) {
      notification.data.event = TimeSlotDtoService.transform(
        notification.data.event,
      );
      notification.data.participant = ParticipantDtoService.transform(
        notification.data.participant,
      );
    }

    return {
      ...notification,
      participant:
        participants.find(({ _id }) => _id === notification.participantId) ||
        null,
    };
  }
}
