import { StandaloneArrearType } from "@customTypes/StandaloneArrearType";
import {
  diff,
  formatDate,
  formatShortDate,
  formatWeekday,
  toRelative,
} from "@utils/date.utils";

export function PaymentDeadline({ arrear }: { arrear: StandaloneArrearType }) {
  const deadline = arrear.lesson.paymentDeadline;
  const rootCs = arrear.isAfterDeadline ? "red" : "";

  if (!deadline) {
    return <div>-</div>;
  }

  const fullDate = formatDate(deadline, "full");
  const daysAfterDeadline = diff(new Date(), deadline);

  // NOTE: Four days or more to deadline
  if (daysAfterDeadline < -4 || daysAfterDeadline > 4) {
    const date = formatShortDate(deadline);
    const weekday = formatWeekday(deadline);

    return (
      <div
        className={rootCs}
        title={fullDate}
        style={{ cursor: "help", fontWeight: "500" }}
      >
        {date}, {weekday}
      </div>
    );
  }

  const relativeTime = toRelative(deadline);

  return (
    <div
      className={rootCs}
      title={fullDate}
      style={{ cursor: "help", whiteSpace: "normal", fontWeight: "500" }}
    >
      {relativeTime}
    </div>
  );
}
