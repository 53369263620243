import { ParticipantType } from "../../types/ParticipantType";
import { StudentType } from "../../types/StudentType";
import { SelectParticipantPopover } from "../SelectParticipantPopover";

import { ReactComponent as NoUserImg } from "./img/no_user.svg";
import { StudentInitials } from "./StudentInitials";

import cs from "./Todo.module.scss";

type SelectStudentBtnProps = {
  participant: ParticipantType | null;
  onChange: (selected: ParticipantType) => void;
};

export const SelectParticipantBtn = ({
  participant,
  onChange,
}: SelectStudentBtnProps) => (
  <SelectParticipantPopover participant={participant} onSelect={onChange}>
    <button className={cs.selectStudentBtn}>
      {participant ? (
        <StudentInitials student={participant.details as StudentType} />
      ) : (
        <NoUserImg width={26} />
      )}
    </button>
  </SelectParticipantPopover>
);
