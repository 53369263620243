import { useEffect, useState } from "react";

import { Button } from "@components/Button";
import { Checkbox } from "@components/Checkbox";
import { Input } from "@components/Input";
import { Modal } from "@components/Modal";
import { Textarea } from "@components/Textarea";
import { ContactType } from "@customTypes/ContactType";
import { useAddContact } from "@hooks/useAddContact";
import { useReplaceContact } from "@hooks/useReplaceContact";
import { ToastService } from "@services/ToastService";

import cs from "./UpsertContactModal.module.scss";

type UpsertContactModalProps = {
  isOpen: boolean;
  contact: Partial<ContactType>;
  onClose: () => void;
};

type Form = Partial<Pick<ContactType, "email" | "isMain" | "note" | "phone">>;

export const UpsertContactModal = ({
  isOpen,
  contact,
  onClose,
}: UpsertContactModalProps) => {
  const [form, setForm] = useState<Form | null>(null);

  const addContact = useAddContact();
  const replaceContact = useReplaceContact();

  useEffect(() => {
    if (isOpen && contact) {
      setForm({ ...contact });
    }
  }, [isOpen]);

  const mode = contact?._id ? "edit" : "new";

  if (!form) return null;

  return (
    <Modal
      title={mode === "new" ? "Dodaj kontakt" : "Edytuj kontakt"}
      isOpen={isOpen}
      className={cs.modal}
      footer={
        <Button
          color="primary"
          isLoading={addContact.isLoading || replaceContact.isLoading}
          onClick={handleConfirm}
        >
          {mode === "new" ? "Dodaj" : "Zapisz"}
        </Button>
      }
      clickOutsideToClose={false}
      onClose={onClose}
    >
      <form className={cs.form} tabIndex={0}>
        <Input
          label="E-mail"
          value={form.email || ""}
          type="email"
          onChange={(value: string) => handleChange("email", value)}
        />

        <Input
          label="Telefon"
          value={form.phone || ""}
          name="phone"
          onChange={(value: string) => handleChange("phone", value)}
        />

        <Textarea
          label="Notatka"
          value={form.note || ""}
          onChange={(value: string) => handleChange("note", value)}
        />

        <div className={cs.invitationRow}>
          <Checkbox
            checked={form.isMain || false}
            label="Główny kontakt do ucznia"
            onChange={(checked: boolean) => handleChange("isMain", checked)}
          />
        </div>
      </form>
    </Modal>
  );

  function handleChange(property: keyof ContactType, value: string | boolean) {
    setForm({ ...form, [property]: value });
  }

  async function handleConfirm() {
    const isValid = form?.email || form?.phone;

    if (!isValid) return;

    if (mode === "new") {
      await addContact.mutateAsync({
        contact: form!,
      });
    } else if (contact?._id) {
      await replaceContact.mutateAsync({
        contact: {
          ...contact,
          ...form,
        } as ContactType,
      });
    }

    ToastService.success("Kontakt został dodany.");

    onClose();
    setTimeout(clear, 1000);
  }

  function clear() {
    setForm(null);
  }
};
