import { useQuery } from "react-query";

import { StudentApi } from "@api/StudentApi";

export const useStudents = () => {
  return useQuery("students", StudentApi.fetchAll, {
    staleTime: Infinity,
    cacheTime: Infinity,
  });
};
