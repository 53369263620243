import { ElevatedIconBtn } from "@components/ElevatedIconBtn";
import { MaterialPill } from "@components/MaterialPill";
import { SidePanel as SidePanelBase } from "@components/SidePanel";
import { TemplateType } from "@customTypes/TemplateType";
import { formatDate } from "@utils/date.utils";
import { isSmallScreen } from "@utils/utils";

import { DetailsModal } from "./DetailsModal";

import cs from "./TemplatesListPage.module.scss";

export const SidePanel = ({
  activeTemplate,
  onEdit,
  onClose,
  onCopy,
  onDelete,
}: {
  activeTemplate: TemplateType | null;
  onClose: () => void;
  onEdit: () => void;
  onCopy: (template: TemplateType) => void;
  onDelete: (templateId: string) => void;
}) => {
  if (!activeTemplate) return null;

  if (isSmallScreen) {
    return (
      <DetailsModal
        isOpen
        activeTemplate={activeTemplate}
        onEdit={onEdit}
        onDelete={onDelete}
        onClose={onClose}
        onCopy={onCopy}
      />
    );
  }

  return (
    <SidePanelBase
      actions={
        <>
          <ElevatedIconBtn icon="edit" title="Edytuj" onClick={onEdit} />
          <ElevatedIconBtn
            icon="clone"
            title="Kopiuj"
            onClick={() => onCopy(activeTemplate)}
          />
          <ElevatedIconBtn
            icon="trash"
            title="Usuń"
            onClick={() => onDelete(activeTemplate?._id)}
          />
        </>
      }
      main={
        <>
          <div className={cs.sidePanelSection__main}>
            <div className="medium">Temat lekcji:</div>
            <div className="medium">{activeTemplate.topic}</div>

            <div className="medium">Utworzono:</div>
            <div>{formatDate(activeTemplate.createdAt)}</div>
          </div>

          <div className={cs.sidePanelSection}>
            <div className="medium">Materiały:</div>
            <div className={cs.materials}>
              {activeTemplate.materials.length === 0 && "-"}

              {activeTemplate.materials.map((material) => (
                <MaterialPill key={material._id} material={material} />
              ))}
            </div>

            <div className="medium">Opis lekcji:</div>
            <div
              dangerouslySetInnerHTML={{
                __html: activeTemplate.noteForStudent || "-",
              }}
            />
          </div>
        </>
      }
      onClose={onClose}
    />
  );
};
