import { Button } from "@components/Button";
import { Modal } from "@components/Modal";

import { Filters } from "./Filters";

import cs from "./FinancesListPage.module.scss";

export const FiltersModal = ({
  isOpen,
  filters,
  onChange,
  onClear,
  onClose,
}) => {
  return (
    <Modal
      title="Filtruj"
      isOpen={isOpen}
      className={cs.FiltersModal}
      footer={
        <Button color="primary" onClick={onClose}>
          Zamknij
        </Button>
      }
      onClose={onClose}
    >
      <Filters filters={filters} onChange={onChange} onClear={onClear} />
    </Modal>
  );
};
