import ReactQuill from "react-quill";

import { modules } from "./config";

import "react-quill/dist/quill.snow.css";
import "./RichTextEditor.scss";

type RichTextEditorProps = {
  value: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  onChange: (updatedValue: string) => void;
};

export const RichTextEditor = ({
  value,
  label,
  placeholder,
  required,
  onChange,
}: RichTextEditorProps) => {
  return (
    <div>
      {label && <div className="label mb4">{label}</div>}

      <ReactQuill
        modules={modules}
        theme="snow"
        value={value}
        onChange={onChange}
      />
    </div>
  );
};
