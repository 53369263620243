import { useQuery } from "react-query";

import { AuthApi } from "@api/AuthApi";
import * as Sentry from "@sentry/react";
import { useAppStore } from "@store";

export const useCurrentUser = () => {
  const setCurrentUser = useAppStore((state) => state.setCurrentUser);

  const { data, isLoading } = useQuery("user", AuthApi.fetchCurrentUser, {
    staleTime: Infinity,
    cacheTime: Infinity,
    onSuccess(data) {
      Sentry.setUser({ id: data._id });
      setCurrentUser(data || null);
    },
    onError() {
      Sentry.setUser(null);
      setCurrentUser(null);
    },
  });

  return {
    currentUser: data,
    isLoading,
  };
};
