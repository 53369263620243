import { useState } from "react";

import cs from "./Textarea.module.scss";

type TextareaProps = {
  value: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  onChange: (updatedValue: string) => void;
};

export const Textarea = ({
  value,
  label,
  placeholder,
  required,
  onChange,
}: TextareaProps) => {
  const [randomId] = useState(genRandomId());

  return (
    <span className={cs.root}>
      <label htmlFor={randomId} className={cs.label}>
        {label}
      </label>

      <textarea
        id={randomId}
        value={value}
        className={cs.input}
        placeholder={placeholder}
        title={value}
        rows={3}
        required={required}
        onChange={(e) => onChange(e.target.value)}
      />
    </span>
  );
};

function genRandomId() {
  return "textarea_" + Math.random().toFixed(6);
}
