import { useState } from "react";

import {
  calcBoxHeight,
  calcStartPosition,
} from "@components/CalendarLessonEvent/CalendarLessonEvent";
import { RegularEventDetailsModal } from "@components/RegularEventDetailsModal";
import { RegularEventType } from "@customTypes/RegularEventType";

import cs from "./CalendarRegularEvent.module.scss";

type CalendarRegularEventProps = {
  regularEvent: RegularEventType;
};

export const CalendarRegularEvent = ({
  regularEvent,
}: CalendarRegularEventProps) => {
  const [areDetailsOpened, setAreDetailsOpened] = useState(false);

  const height = calcBoxHeight(regularEvent.duration);
  const top = calcStartPosition(regularEvent.date);

  return (
    <>
      <div
        className={cs.root}
        style={{ height, top }}
        onClick={() => setAreDetailsOpened(true)}
      >
        <div className={cs.desc}>{regularEvent.desc}</div>

        <span className={cs.hours}>
          {regularEvent.startHourStr}-{regularEvent.endHourStr}
        </span>
      </div>

      <RegularEventDetailsModal
        isOpen={areDetailsOpened}
        regularEvent={regularEvent}
        onClose={() => setAreDetailsOpened(false)}
      />
    </>
  );
};
