import React from "react";
import SkeletonBase from "react-loading-skeleton";

import "react-loading-skeleton/dist/skeleton.css";
import cs from "./Skeleton.module.scss";

type SkeletonProps = React.ComponentProps<typeof SkeletonBase> & {
  space?: number;
};

export const Skeleton = (props: SkeletonProps) => (
  <SkeletonBase
    {...props}
    style={{ marginBottom: props.space || 0 }}
    className={cs.item}
  />
);
