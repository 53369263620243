import { Dropdown } from "@components/Dropdown";
import { LessonDetails } from "@components/LessonDetails";
import { StudentCenterTabType } from "@components/StudentCenterModal/Tabs";
import messageImg from "@components/Table/img/message.svg";
import phoneImg from "@components/Table/img/phone.svg";
import { StudentDtoType } from "@customTypes/StudentDtoType";
import { StudentType } from "@customTypes/StudentType";
import { formatDate } from "@utils/date.utils";

import moreImg from "./img/more.svg";

import cs from "./MyStudentsPage.module.scss";

type ColumnType = {
  onEditStudent: (student: StudentType) => void;
  onDeleteStudent: (studentId: string) => void;
  onInviteStudent: (student: StudentType) => void;
  onOpenStudentCenter: (
    student: StudentType,
    tab?: StudentCenterTabType,
  ) => void;
};

export const columns = ({
  onEditStudent,
  onDeleteStudent,
  onInviteStudent,
  onOpenStudentCenter,
}: ColumnType) => [
  {
    title: "Imię i nazwisko",
    field: "name",
    sortable: true,
    render(value: string, col: any, student: StudentType) {
      return (
        <button
          className={cs.studentNameCol}
          onClick={() => onOpenStudentCenter(student)}
        >
          {value}
        </button>
      );
    },
  },
  {
    title: "Kontakt",
    field: "contact",
    hideWidth: 500,
    render(value: StudentType["contact"], col: any, student: StudentType) {
      const noOfContacts = value.length;

      if (noOfContacts === 0) return null;

      const mainEmail = student.mainContact?.email;
      const mainPhone = student.mainContact?.phone;

      return (
        <div>
          <div className={cs.contact}>
            {mainEmail && (
              <>
                <img src={messageImg} alt="" />

                <a href={`mailto:${mainEmail}`} className="link regular">
                  {mainEmail}
                </a>
              </>
            )}
          </div>

          <div className={cs.contact}>
            {mainPhone && (
              <>
                <img src={phoneImg} alt="" />

                <a href={`tel:${mainPhone}`} className="link regular">
                  {mainPhone}
                </a>
              </>
            )}
          </div>

          {student.contact.length > 1 && (
            <div style={{ paddingLeft: 29 }}>
              <button
                className={cs.showMoreBtn}
                onClick={() => onOpenStudentCenter(student, "contact")}
              >
                Pokaż wszystkie (+{student.contact.length - 1})
              </button>
            </div>
          )}
        </div>
      );
    },
  },
  {
    title: "Ostatnia lekcja",
    field: "lastLesson",
    sortable: true,
    hideWidth: 800,
    render(value: StudentDtoType["lastLesson"]) {
      if (!value) return "-";

      return (
        <LessonDetails lesson={value}>
          <span className="link" style={{ fontWeight: 400 }}>
            {formatDate(value.date)}
          </span>
        </LessonDetails>
      );
    },
  },
  {
    title: "Konto w aplikacji",
    field: "hasAccount",
    type: "boolean",
    width: 144,
    hideWidth: 600,
  },
  {
    title: "",
    field: "",
    type: "boolean",
    width: 100,
    center: true,
    render(value: any, col: any, student: StudentType) {
      const dropdownOptions = [
        <div key="Centrum ucznia" onClick={() => onOpenStudentCenter(student)}>
          Centrum ucznia
        </div>,
        <div key="Edytuj dane" onClick={() => onEditStudent(student)}>
          Edytuj dane
        </div>,
        <div key="Usuń ucznia" onClick={() => onDeleteStudent(student._id)}>
          Usuń ucznia
        </div>,
      ];

      dropdownOptions.push(
        <div key="invitation" onClick={() => onInviteStudent(student)}>
          {student.hasAccount ? "Ponów zaproszenie" : "Wyślij zaproszenie"}
        </div>,
      );

      return (
        <Dropdown title="Akcje:" options={dropdownOptions}>
          <button className="btn-icon" style={{ verticalAlign: "middle" }}>
            <img src={moreImg} alt="" />
          </button>
        </Dropdown>
      );
    },
  },
];
