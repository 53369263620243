import { ReactComponent as HouseIcon } from "../../img/house_icon_new.svg";
import { ReactComponent as RemoveIcon } from "../../img/remove.svg";
import { AssignedMaterialType } from "../../types/AssignedMaterialType";
import { MaterialPill } from "../MaterialPill";

import cs from "./AssignedMaterialsManager.module.scss";

type MaterialProps = {
  material: AssignedMaterialType;
  onToggleHomework: (materialId: string) => void;
  onDelete: (materialId: string) => void;
};

export const Material = ({
  material,
  onToggleHomework,
  onDelete,
}: MaterialProps) => (
  <div className={cs.material}>
    <div>
      <MaterialPill material={material} config={{ hideHomeworkInfo: true }} />
    </div>

    <button
      className="btn-icon"
      style={{
        color: material.isHomework ? "inherit" : "rgba(41, 45, 52, 0.3)",
      }}
      title={
        material.isHomework ? "Zadanie domowe" : "Oznacz jako zadanie domowe"
      }
      type="button"
      onClick={() => onToggleHomework(material._id)}
    >
      <HouseIcon width={14} />
    </button>

    <button
      className="btn-icon"
      type="button"
      onClick={() => onDelete(material._id)}
    >
      <RemoveIcon width={12} height={12} />
    </button>
  </div>
);
