import { NotificationDtoType } from "@customTypes/NotificationDtoType";
import { NotificationDtoService } from "@services/NotificationDtoService";

import { createAxiosInstance } from "./_createAxiosInstance";

const api = createAxiosInstance("/api/notification");

export class NotificationApi {
  static async fetchAll(retrieveCount?: number, participantId?: string) {
    type responseType = {
      data: NotificationDtoType[];
      hasMore: boolean;
    };

    const { data } = await api.get<responseType>("", {
      params: {
        count: retrieveCount,
        participant: participantId,
      },
    });

    return {
      data: NotificationDtoService.transformAll(data.data),
      hasMore: data.hasMore,
    };
  }

  static async deleteAll(participantId?: string) {
    const { data } = await api.delete("", {
      params: {
        participant: participantId,
      },
    });

    return data;
  }

  static async deleteById(id: string) {
    const { data } = await api.delete("/" + id);

    return data;
  }

  static async markManyAsSeen({ todoIds }: { todoIds: string[] }) {
    return api.post("/mark-as-seen", { todoIds });
  }
}
