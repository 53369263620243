import { Notification } from "@components/Notification";
import { useDeleteAllNotifications } from "@hooks/useDeleteAllNotifications";
import { useDeleteNotification } from "@hooks/useDeleteNotification";
import { useNotificationsByParticipant } from "@hooks/useNotificationsByParticipant";
import { ReactComponent as BrushActionImg } from "@img/brush_action.svg";

import cs from "./Notifications.module.scss";
import parentCs from "./StudentCenterModal.module.scss";

type NotificationsProps = {
  participantId: string;
};

export function Notifications({ participantId }: NotificationsProps) {
  const { data, isLoading, unseenCount, hasMore, getNext } =
    useNotificationsByParticipant(participantId);

  const deleteAllNotifications = useDeleteAllNotifications(participantId);
  const deleteNotification = useDeleteNotification();

  return (
    <section className={cs.root}>
      <header className={parentCs.sectionHeader}>
        {/*<img src={expandImg} alt="" />*/}

        <span>Aktualności ({unseenCount})</span>

        {!isLoading && (
          <button
            className="btn-icon ml6"
            title="Wyczyść notyfikacje"
            onClick={() => deleteAllNotifications.mutate()}
          >
            <BrushActionImg width={24} />
          </button>
        )}
      </header>

      <div className={cs.list}>
        {isLoading && <div>Loading</div>}

        {!isLoading && data.length === 0 && (
          <div className={cs.noNotificationsInfo}>
            Brak powiadomień - jesteś na bieżąco!
          </div>
        )}

        {data.map((notification) => (
          <Notification
            key={notification._id}
            notification={notification}
            personal
            onDelete={deleteNotification.mutate}
          />
        ))}
      </div>

      {hasMore && (
        <div className="text-center">
          <button className={cs.showMoreBtn} onClick={getNext}>
            Pokaż więcej
          </button>
        </div>
      )}
    </section>
  );
}
