import CalendarBase from "react-calendar";

import arrowLeftImg from "./img/arrow_left.svg";
import arrowRightImg from "./img/arrow_right.svg";

import cs from "./Calendar.module.scss";

type CalendarProps = {
  value: Date | [Date | null, Date | null] | null;
  selectRange?: boolean;
  onChange: (selected: CalendarProps["value"]) => void;
};

export const Calendar = ({ value, selectRange, onChange }: CalendarProps) => {
  return (
    <div className={cs.root}>
      <CalendarBase
        prevLabel={<img src={arrowLeftImg} alt="" width={7} />}
        nextLabel={<img src={arrowRightImg} alt="" width={7} />}
        value={value}
        selectRange={selectRange}
        onChange={onChange}
      />
    </div>
  );
};
