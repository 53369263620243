import { Checkbox } from "@components/Checkbox";
import { useParticipants } from "@hooks/useParticipants";

import cs from "./FilterStudents.module.scss";

type FilterStudentsProps = {
  selectedParticipantIds: Array<string>;
  onChange: (filterKey: string, value: Array<string>) => void;
};

export const FilterStudents = ({
  selectedParticipantIds,
  onChange,
}: FilterStudentsProps) => {
  selectedParticipantIds = selectedParticipantIds ?? [];

  const { students } = useParticipants();

  const studentsSorted = students.sort((p1, p2) =>
    p1.details.name > p2.details.name ? 1 : -1,
  );

  return (
    <div className={`FilterStudents ${cs.root}`}>
      <div className={`FilterStudents_title ${cs.title}`}>Uczniowie</div>

      <div className={`FilterStudents_list ${cs.list}`}>
        {studentsSorted.length === 0 && (
          <div style={{ fontStyle: "italic" }}>Brak uczniów...</div>
        )}

        {studentsSorted.map((participant) => (
          <div key={participant._id} className={cs.list_item}>
            <Checkbox
              value={participant._id}
              checked={selectedParticipantIds.includes(participant._id)}
              label={participant.details.name}
              onChange={handleChanged}
            />
          </div>
        ))}
      </div>
    </div>
  );

  function handleChanged(checked: boolean, participantId: string) {
    onChange(
      "participants",
      checked
        ? [...selectedParticipantIds, participantId]
        : selectedParticipantIds.filter((s) => s !== participantId),
    );
  }
};
