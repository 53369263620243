import { useState } from "react";

import { Button } from "@components/Button";
import { Chips } from "@components/Chips";
import { TemplatePicker } from "@components/TemplatePicker";
import { UpsertTemplateModal } from "@components/UpsertTemplateModal";
import { TemplateType } from "@customTypes/TemplateType";
import { ReactComponent as PlusImg } from "@img/plus.svg";
import { deleteById, findById } from "@utils/utils";

import { Template } from "./Template";

import cs from "./AssignedTemplatesManager.module.scss";

type AssignedTemplatesManagerProps = {
  templates: TemplateType[];
  onChange: (updatedTemplates: TemplateType[]) => void;
};

export const AssignedTemplatesManager = ({
  templates,
  onChange,
}: AssignedTemplatesManagerProps) => {
  const [isAddNewTemplateModalOpen, setIsAddNewTemplateModalOpen] =
    useState(false);
  const [isEditTemplateModalOpen, setIsEditTemplateModalOpen] = useState(false);
  const [editedTemplate, setEditedTemplate] = useState<TemplateType | null>(
    null,
  );

  return (
    <div>
      <div className="v-center">
        <span className={cs.header}>Dodanych szablonów:</span>
        <Chips color="neutral" className={cs.templatesCounter}>
          {templates.length}
        </Chips>
      </div>

      <div className={cs.picker}>
        <TemplatePicker
          placeholder="Wybierz z istniejących"
          selected={templates}
          onChange={onChange}
        />

        <Button
          color="primary"
          onClick={() => setIsAddNewTemplateModalOpen(true)}
        >
          <PlusImg width={12} />

          <span>Utwórz nowy</span>
        </Button>
      </div>

      <div className={cs.subHeader}>Przypisane szablony lekcji:</div>

      <div className={cs.list}>
        {templates.length === 0 && (
          <div className={cs.noTemplatesInfo}>
            Nie przypisano jeszcze żadnego szablonu...
          </div>
        )}

        {templates.map((template, index) => (
          <Template
            key={template._id}
            template={template}
            counter={index + 1}
            onEdit={handleTemplateEdit}
            onDetach={handleTemplateDetached}
            onMoveUp={handleMoveUp}
            onMoveDown={handleMoveDown}
          />
        ))}
      </div>

      <UpsertTemplateModal
        isOpen={isAddNewTemplateModalOpen}
        onAfterUpsert={handleNewTemplateAdded}
        onClose={() => setIsAddNewTemplateModalOpen(false)}
      />

      <UpsertTemplateModal
        isOpen={isEditTemplateModalOpen}
        template={editedTemplate}
        onClose={() => {
          setIsEditTemplateModalOpen(false);
          setEditedTemplate(null);
        }}
      />
    </div>
  );

  function handleNewTemplateAdded(newTemplate: TemplateType) {
    onChange([...templates, newTemplate]);
  }

  function handleTemplateEdit(template: TemplateType) {
    setEditedTemplate(template);
    setIsEditTemplateModalOpen(true);
  }

  function handleTemplateDetached(templateId: string) {
    onChange(deleteById(templateId, templates));
  }

  function handleMoveUp(templateId: string) {
    const template = findById(templateId, templates);
    const index = templates.findIndex(
      (template) => template._id === templateId,
    );

    if (index > 0 && template) {
      const updatedTemplates = deleteById(templateId, templates);
      updatedTemplates.splice(index - 1, 0, template);

      onChange(updatedTemplates);
    }
  }

  function handleMoveDown(templateId: string) {
    const template = findById(templateId, templates);
    const index = templates.findIndex(
      (template) => template._id === templateId,
    );

    if (index < templates.length - 1 && template) {
      const updatedTemplates = deleteById(templateId, templates);
      updatedTemplates.splice(index + 1, 0, template);

      onChange(updatedTemplates);
    }
  }
};
