import classNames from "classnames";

import { MaterialType } from "../../types/MaterialType";

import { ReactComponent as HomeImg } from "./img/house.svg";
import { FolderListItem } from "./FolderListItem";

import cs from "./FoldersList.module.scss";

type FoldersListProps = {
  folders: MaterialType[];
  className?: string;
  onSelect: (folderId: string | null) => void;
};

export const FoldersList = ({
  folders,
  className,
  onSelect,
}: FoldersListProps) => {
  const rootCs = classNames(cs.root, className, "Materials_FoldersList");

  const rootLevel = folders.filter((folder) => !folder.parentId);

  return (
    <aside className={rootCs}>
      <div className={cs.all}>
        <div className={cs.itemName} onClick={() => onSelect(null)}>
          <HomeImg height={14} />

          <span>Wszystkie</span>
        </div>
      </div>

      <div className={cs.list}>
        {rootLevel.map((folder) => (
          <FolderListItem
            key={folder._id}
            folder={folder}
            onSelect={onSelect}
          />
        ))}
      </div>
    </aside>
  );
};
