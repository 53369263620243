import { Chips } from "@components/Chips";
import { PlanType } from "@customTypes/PlanType";

import cs from "./PlansListPage.module.scss";

export const columns = [
  {
    title: "Nazwa",
    field: "name",
    sortable: true,
  },
  {
    title: "Szablony",
    center: true,
    render(field: any, col: any, row: PlanType) {
      return (
        <Chips color="neutral" className={cs.templatesCounter}>
          {row.templates.length}
        </Chips>
      );
    },
  },
  {
    title: "Data utworzenia",
    field: "createdAt",
    type: "date",
    typeArgs: {
      dateStyle: "numeric",
    },
    sortable: true,
    hideWidth: 500,
  },
];
