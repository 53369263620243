import { AddFileMaterialsBtn } from "@pages/MaterialsPage";

import cs from "./MaterialsModal.module.scss";

export const EmptyList = () => (
  <div className={cs.empty}>
    <div className={cs.empty_title}>
      Twoja biblioteka materiałów jest jeszcze pusta.
    </div>
    <div className={cs.empty_addFirst}>
      <AddFileMaterialsBtn
        currentFolder={null}
        label="Dodaj pierwsze materiały"
        color="secondary"
      />
    </div>
  </div>
);
