import { ReactComponent as PlanImg } from "@img/plan.svg";

import cs from "./StudyProgress.module.scss";

export const NodeAssignPlan = ({ onClick }: { onClick: () => void }) => (
  <div className={cs.row}>
    <div />

    <div className={cs.nodeShowMore} onClick={onClick}>
      <PlanImg width={16} />
    </div>

    <div className={cs.actionText} onClick={onClick}>
      Przypisz kurs
    </div>
  </div>
);
