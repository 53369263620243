import React, { useState } from "react";

import { Button } from "@components/Button";
import { Table } from "@components/Table";
import { UpsertContactModal } from "@components/UpsertContactModal";
import { ContactType } from "@customTypes/ContactType";
import { useDeleteContact } from "@hooks/useDeleteContact";
import { ToastService } from "@services/ToastService";
import { useAppStore } from "@store";

import { columns } from "./columns";

import cs from "./TabContact.module.scss";

type Props = {
  studentId: string;
  contact: ContactType[];
};

export const TabContact = ({ studentId, contact }: Props) => {
  const [isAddContactModalOpen, setIsAddContactModalOpen] = useState(false);
  const [editedContact, setEditedContact] = useState<ContactType | null>(null);

  const deleteContact = useDeleteContact();

  const openConfirmationModal = useAppStore(
    (state) => state.openConfirmationModal,
  );

  return (
    <section className={cs.root}>
      {contact.length > 0 && (
        <div>
          <Button
            color="secondary"
            size="small"
            onClick={() => {
              setEditedContact(null);
              setIsAddContactModalOpen(true);
            }}
          >
            Dodaj kontakt
          </Button>
        </div>
      )}

      <Table
        data={contact}
        columns={columns({
          onEditContact: handleEdit,
          onDeleteContact: handleDelete,
        })}
        empty={{
          title: "Brak kontaktów dla tego ucznia.",
          extra: (
            <div>
              <Button
                className="mt24"
                color="primary"
                onClick={() => {
                  setEditedContact(null);
                  setIsAddContactModalOpen(true);
                }}
              >
                <span>Dodaj kontakt</span>
              </Button>
            </div>
          ),
        }}
        className="width-100"
      />

      <UpsertContactModal
        isOpen={isAddContactModalOpen}
        contact={{ studentId, ...(editedContact || {}) }}
        onClose={() => {
          setIsAddContactModalOpen(false);
        }}
      />
    </section>
  );

  function handleEdit(contact: ContactType) {
    setEditedContact(contact);
    setIsAddContactModalOpen(true);
  }

  async function handleDelete(contactId: string) {
    const isConfirmed = await openConfirmationModal({
      confirmText: "Usuń",
      text: `Czy na pewno chcesz usunąć ten kontakt?`,
    });

    if (isConfirmed) {
      await deleteContact.mutateAsync({ id: contactId });

      ToastService.success("Kontakt został usunięty.");
    }
  }
};

export default TabContact;
