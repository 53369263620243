import { useQuery } from "react-query";

import { ParticipantApi } from "@api/ParticipantApi";

// INFO: Separate endpoint for ease of caching. Notice first query key - "lessons"
export const useLastNextLessonsByParticipant = (participantId: string) => {
  return useQuery(
    ["lessons", "last-next-lessons", participantId],
    () => {
      if (!participantId) return Promise.resolve(null);

      return ParticipantApi.fetchLastNextLessonsByParticipant(participantId);
    },
    {
      staleTime: 3 * 60 * 1000,
      cacheTime: 3 * 60 * 1000,
      enabled: Boolean(participantId),
    },
  );
};
