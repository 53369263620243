// @ts-nocheck

import { ReactNode, useState } from "react";

import classNames from "classnames";

import { Chips } from "@components/Chips";
import { Spinner } from "@components/Spinner";
import { MaterialDtoType } from "@customTypes/MaterialDtoType";
import { MaterialType } from "@customTypes/MaterialType";
import { useMaterials } from "@hooks/useMaterials";
import { ReactComponent as FolderGoUpImg } from "@img/folder_go_up.svg";

import { ReactComponent as FolderImg } from "./img/folder.svg";
import { ReactComponent as HomeImg } from "./img/house.svg";
import { ReactComponent as ListImg } from "./img/list.svg";
import { File } from "./File";
import { Folder } from "./Folder";
import { FoldersList } from "./FoldersList";
import { RecursiveNavigation } from "./RecursiveNavigation";

import cs from "./Materials.module.scss";

type MaterialsProps = {
  currentFolder: MaterialType | null;
  defaultView?: "folders" | "list";
  checked: MaterialDtoType[];
  condensed?: boolean;
  preventDownload?: boolean;
  footer?: ReactNode;
  emptyList?: ReactNode;
  setCurrentFolder: (selectedFolder: MaterialType | null) => void;
  onToggle: (material: MaterialDtoType, checked: boolean) => void;
};

export const Materials = ({
  currentFolder,
  checked,
  defaultView = "folders",
  condensed = false,
  preventDownload = false,
  footer = null,
  emptyList = null,
  setCurrentFolder,
  onToggle,
}: MaterialsProps) => {
  const [view, setView] = useState<"folders" | "list">(defaultView);
  const { files, folders, isLoading } = useMaterials();

  const currentFiles = files.filter((material) => {
    return (
      material.parentId === currentFolder?._id ||
      (!currentFolder && !material.parentId)
    );
  });
  const currentFolders = folders.filter((material) => {
    return (
      material.parentId === currentFolder?._id ||
      (!currentFolder && !material.parentId)
    );
  });

  const listItems = currentFolder
    ? files.filter((file) => {
        return file.ancestors?.find(
          (ancestor) => ancestor._id === currentFolder._id,
        );
      })
    : files;

  const rootCs = classNames(cs.root, "Materials", {
    [cs.condensed]: condensed,
  });

  return (
    <Spinner loading={isLoading} className={cs.spinner} size={44}>
      <div className={rootCs}>
        <FoldersList
          folders={folders}
          className={cs.FoldersList}
          onSelect={handleFolderSelected}
        />

        <header className={cs.header}>
          {/* TODO: Later */}
          <div />
          {/*<Search className={cs.search} items={[]} onChange={() => {}} />*/}

          {view === "folders" ? (
            <button
              className="btn-icon"
              title="Przełącz na widok listy"
              onClick={() => setView("list")}
            >
              <ListImg width={16} />
            </button>
          ) : (
            <button
              className="btn-icon"
              title="Przełącz na widok folderów"
              onClick={() => setView("folders")}
            >
              <FolderImg width={16} />
            </button>
          )}
        </header>

        <main className={cs.main}>
          <div className={cs.main_top}>
            <Chips color="primary" className={cs.navigationChips}>
              <HomeImg
                width={12}
                className={cs.navigationChips_navigate}
                onClick={() => setCurrentFolder(null)}
              />

              {currentFolder && <span>{" > "}</span>}

              <RecursiveNavigation
                folder={currentFolder}
                onSelect={handleFolderSelected}
              />
            </Chips>
          </div>

          <div className={cs.main_list}>
            <div className={cs.list_header}>
              <div />

              <div>Nazwa</div>

              {/* TODO: Later */}
              {/*{!condensed && <div>Ostatnie użycie</div>}*/}
              {!condensed && (
                <div className="hide-mobile">Data modyfikacji</div>
              )}

              <div>Typ</div>
            </div>

            {!isLoading && files.length === 0 && emptyList}

            {view === "folders" && (
              <div className={cs.list_items}>
                {currentFolder && (
                  <div
                    className={cs.list_item__folder}
                    onClick={() => {
                      const parent = currentFolder.ancestors.at(-1);

                      handleFolderSelected(parent?._id || null);
                    }}
                  >
                    <span />

                    <span className={cs.list_itemName}>
                      <FolderGoUpImg width={14} />
                      <span>...</span>
                    </span>

                    <span />
                    <span />
                  </div>
                )}

                {currentFolders.map((folder) => (
                  <Folder
                    key={folder._id}
                    material={folder}
                    checked={checked.some(({ _id }) => _id === folder._id)}
                    onSelect={setCurrentFolder}
                    onToggle={onToggle}
                  />
                ))}

                {currentFiles.map((material) => (
                  <File
                    key={material._id}
                    material={material}
                    checked={checked.some(({ _id }) => _id === material._id)}
                    view="folders"
                    condensed={condensed}
                    preventDownload={preventDownload}
                    onSelect={handleFolderSelected}
                    onToggle={onToggle}
                  />
                ))}
              </div>
            )}

            {view === "list" && (
              <div className={cs.list_items}>
                {listItems.map((material) => (
                  <File
                    key={material._id}
                    material={material}
                    checked={checked.some(({ _id }) => _id === material._id)}
                    view="list"
                    condensed={condensed}
                    preventDownload={preventDownload}
                    onSelect={handleFolderSelected}
                    onToggle={onToggle}
                  />
                ))}
              </div>
            )}
          </div>
        </main>

        {footer}
      </div>
    </Spinner>
  );

  function handleFolderSelected(folderId: string | null) {
    if (!folderId) {
      setCurrentFolder(null);
      return;
    }

    setCurrentFolder(folders.find(({ _id }) => _id === folderId) || null);
  }
};
