import { useState } from "react";

import { Chips } from "@components/Chips";
import { Pill } from "@components/Pill";
import { PlanType } from "@customTypes/PlanType";

import { ReactComponent as ExpandDownImg } from "./img/expand_down.svg";
import { ReactComponent as ExpandUpImg } from "./img/expand_up.svg";

import cs from "./PlansSearch.module.scss";

type ItemProps = {
  plan: PlanType;
  onSelect: (item: any) => void;
};

const Item = ({ plan, onSelect }: ItemProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div key={plan._id} className={cs.listItem} onClick={() => onSelect(plan)}>
      <div className={cs.plan}>
        <div>
          <Pill>{plan.name}</Pill>
        </div>

        <Chips color="neutral" className={cs.templatesCounter}>
          {plan.templates.length}
        </Chips>

        <div className="f-center">
          <button
            className="btn-icon"
            onClick={(e) => {
              e.stopPropagation();
              setIsExpanded((prev) => !prev);
            }}
          >
            {isExpanded ? (
              <ExpandUpImg width={12} />
            ) : (
              <ExpandDownImg width={12} />
            )}
          </button>
        </div>
      </div>

      {isExpanded && plan.templates.length > 0 && (
        <div className={cs.templatesList}>
          {plan.templates.map((template, index) => (
            <div className={cs.template}>
              <span className={cs.templateCounter}>{index + 1}.</span>
              <span className={cs.templateName}>{template.topic}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

type SearchPopoverContentProps = {
  plans: PlanType[];
  onSelect: (item: any) => void;
};

export const PlansSearchContent = ({
  plans,
  onSelect,
}: SearchPopoverContentProps) => {
  return (
    <div className={cs.list}>
      {plans.map((plan) => (
        <Item plan={plan} onSelect={onSelect} />
      ))}
    </div>
  );
};
