import { Button } from "@components/Button";
import { CompletedHomeworkPill } from "@components/CompletedHomeworkPill";
import { ElevatedIconBtn } from "@components/ElevatedIconBtn";
import { MaterialPill } from "@components/MaterialPill";
import { Modal } from "@components/Modal";
import { PaymentStatus } from "@components/PaymentStatus";
import { StudentPopover } from "@components/StudentPopover";
import { LessonType } from "@customTypes/LessonType";
import { formatDate, formatShortDate, formatWeekday } from "@utils/date.utils";
import { downloadFile } from "@utils/utils";

import arrowLeftImg from "./img/arrow_left.svg";
import arrowRightImg from "./img/arrow_right.svg";

import cs from "./LessonsListPage.module.scss";

type DetailsModalProps = {
  isOpen: boolean;
  activeLesson: LessonType | null;
  onNext: () => void;
  onPrev: () => void;
  onDelete: () => void;
  onEdit: () => void;
  onClose: () => void;
};

export const DetailsModal = ({
  isOpen,
  activeLesson,
  onNext,
  onPrev,
  onDelete,
  onEdit,
  onClose,
}: DetailsModalProps) => {
  if (!activeLesson) return null;

  const weekday = formatWeekday(activeLesson.date, "short");
  const date = formatShortDate(activeLesson.date);
  const formattedDate = activeLesson.date
    ? `${weekday}, ${date}, ${activeLesson.startHourStr}-${activeLesson.endHourStr}`
    : "-";

  return (
    <Modal
      title="Szczegóły lekcji"
      isOpen={isOpen}
      className={cs.modal}
      footer={
        <Button color="primary" onClick={onClose}>
          Zamknij
        </Button>
      }
      onClose={onClose}
    >
      <div className={cs.detailsModal_actions}>
        <div className={cs.sidePanelActions}>
          <div className="v-center">
            <Button
              color="primary"
              type="outlined"
              className="mr8"
              onClick={onPrev}
            >
              <img src={arrowLeftImg} alt="" width={12} />
              <span>Poprz.</span>
            </Button>

            <Button color="primary" onClick={onNext}>
              <img src={arrowRightImg} alt="" width={12} />
              <span>Nast.</span>
            </Button>
          </div>

          <div>
            <ElevatedIconBtn
              icon="edit"
              title="Edytuj"
              className="mr10"
              onClick={onEdit}
            />
            {/*<ElevatedIconBtn icon="clone" className="mr10" onClick={onClick} />*/}
            <ElevatedIconBtn icon="trash" onClick={onDelete} />
          </div>
        </div>
      </div>

      <div className={cs.detailsModal_body} tabIndex={0}>
        <div className={cs.sidePanelSection__main}>
          <div className="medium">Temat:</div>
          <div className="medium">{activeLesson.topic}</div>

          <div className="medium">Uczeń:</div>
          <div>
            <StudentPopover participant={activeLesson.participant} />
          </div>

          <div className="medium">Data i godzina:</div>
          <div>{formattedDate}</div>
        </div>

        <div className={cs.sidePanelSection__main}>
          <div className="medium">
            <div>Odesłane zad. domowe:</div>

            {activeLesson.completedHomeworks.length > 0 && (
              <button
                className="btn-icon link"
                onClick={() => {
                  activeLesson.completedHomeworks.forEach((homework) =>
                    downloadFile(homework.url),
                  );
                }}
              >
                Pobierz
              </button>
            )}
          </div>
          <div>
            <span className={cs.materials}>
              {activeLesson.completedHomeworks.length > 0
                ? activeLesson.completedHomeworks.map((homework) => (
                    <CompletedHomeworkPill
                      key={homework._id}
                      homework={homework}
                    />
                  ))
                : "-"}
            </span>
          </div>
        </div>

        <div className={cs.sidePanelSection__main}>
          <div className="medium">Materiały:</div>
          <div>
            <span className={cs.materials}>
              {activeLesson.materials.length > 0
                ? activeLesson.materials.map((material) => (
                    <MaterialPill key={material._id} material={material} />
                  ))
                : "-"}
            </span>
          </div>

          <div className="medium">Opis lekcji:</div>
          <div dangerouslySetInnerHTML={{ __html: activeLesson.desc || "-" }} />

          <div className="medium">Notatka:</div>
          <div>{activeLesson.privateNote || "-"}</div>
        </div>

        <div className={cs.sidePanelSection__main}>
          <div className="medium">Cena:</div>
          <div>
            {activeLesson.price || "-"} {" zł"}
          </div>

          <div className="medium">Termin płatności:</div>
          <div>
            {activeLesson.paymentDeadline
              ? formatDate(activeLesson.paymentDeadline)
              : "-"}
          </div>

          {activeLesson.arrears?.length > 0 && (
            <>
              <div className="medium">Status płatności:</div>
              <div>
                <PaymentStatus arrears={activeLesson.arrears} />
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};
