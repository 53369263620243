import { useMutation, useQueryClient } from "react-query";

import { PlansApi } from "../api/PlansApi";

export const useReplacePlan = () => {
  const queryClient = useQueryClient();

  return useMutation("plans", PlansApi.replace, {
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: ["plans"],
        refetchInactive: true,
      });
    },
  });
};
