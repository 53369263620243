import { useMutation, useQueryClient } from "react-query";

import { FinancesApi } from "@api/FinancesApi";
import { StandaloneArrearType } from "@customTypes/StandaloneArrearType";
import { ArrearDtoService } from "@services/ArrearDtoService";

export const useChangeArrearStatus = () => {
  const queryClient = useQueryClient();

  return useMutation(FinancesApi.changePaymentStatus, {
    onMutate: async ({ arrearId, status }) => {
      await queryClient.cancelQueries("standalone-arrears");

      const currentCache =
        queryClient.getQueriesData<StandaloneArrearType[]>(
          "standalone-arrears",
        );

      queryClient.setQueriesData<StandaloneArrearType[]>(
        ["standalone-arrears"],
        (previous = []) =>
          previous.map((arrear) => {
            return arrear._id !== arrearId
              ? arrear
              : {
                  ...arrear,
                  updatedAt: new Date(),
                  status,
                  isAfterDeadline: ArrearDtoService.calcIsAfterDeadline(
                    status,
                    arrear.lesson.paymentDeadline,
                  ),
                };
          }),
      );

      return { currentCache };
    },
    onError: (err, newTodo, context) => {
      queryClient.setQueriesData("standalone-arrears", context?.currentCache);
    },
  });
};
