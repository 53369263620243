import { DateType } from "../../types/DateType";
import {
  formatHour,
  formatShortDate,
  formatWeekday,
} from "../../utils/date.utils";

export const FormattedDate = ({ date }: { date: DateType }) => {
  const dateStr = formatShortDate(date);
  const hourStr = formatHour(date);
  const weekdayStr = formatWeekday(date, "long");

  return <>{`${dateStr}, ${hourStr} (${weekdayStr})`}</>;
};
