import { TodoDtoType } from "@customTypes/TodoDtoType";
import { TodoType } from "@customTypes/TodoType";
import { ParticipantDtoService } from "@services/ParticipantDtoService";

export class TodoDtoService {
  static transformAll(todos: TodoDtoType[]) {
    return todos.map((todo) => TodoDtoService.transform(todo));
  }

  static transform(todo: TodoDtoType): TodoType {
    if (todo.participant) {
      return {
        ...todo,
        participant: ParticipantDtoService.transform(todo.participant),
      };
    }

    return todo as TodoType;
  }
}
