import { useMutation, useQueryClient } from "react-query";

import { TodoApi } from "../api/TodoApi";
import { TodoType } from "../types/TodoType";
import { deleteById } from "../utils/utils";

export const useMarkTodoAsDone = () => {
  const queryClient = useQueryClient();

  return useMutation(TodoApi.markAsDone, {
    onMutate: async (todo) => {
      await queryClient.cancelQueries(["todos"]);

      const cachedValue =
        queryClient.getQueriesData<TodoType[]>(["todos"]) || [];

      queryClient.setQueriesData<any[]>(["todos", "undone"], (previous = []) =>
        deleteById(todo._id, previous),
      );

      queryClient.setQueriesData<any[]>(["todos", "done"], (previous = []) => [
        ...previous,
        todo,
      ]);

      return { cachedValue };
    },
    onError: (err, newTodo, context) => {
      queryClient.setQueriesData(["todos"], context?.cachedValue);
    },
  });
};
