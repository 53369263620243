import { Chips } from "../Chips";

import incompleteImg from "./img/incomplete.svg";

import cs from "./LessonBox.module.scss";

export const IncompleteIndicator = () => (
  <Chips
    color="primary"
    className={cs.incompleteChips}
    title="Lekcja oznaczona jako wymagająca uzupełnienia"
  >
    <img src={incompleteImg} alt="" height={11} />
  </Chips>
);
